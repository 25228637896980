import {handleResponse_401_Unauthorized} from "@/helpers/handle401";
import {getAuthorizationHeader} from "@/helpers/auth-header";

export const userPartsApi = {
    getPartViewEx,

    setPartStatus
};

//region Get
/**
 * Get PartUserViewEx for specified part
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param partId
 * @return {Promise<Response>}
 */
function getPartViewEx(rootStoreState, storeDispatch, partId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/user/parts/edit/${partId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Get

//region Set part as read or unread
/**
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel
 *         UserPartsController.InputModelUpdate
 *         {
 *             PartId,
 *             IsRead
 *         }
 */
function setPartStatus(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModel)
    };

    const url = `${rootStoreState.apiUrl}/api/user/parts/update`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Set part as read or unread