<!--
Edit exist course ($router.params.id is defined)
or
create new course ($router.params.mctype_id is defined)

Until new course is not created (not saved first time),
new-mode is enabled:
- parts are not editable
- it's not possible to publish/disable the course
-->
<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-toolbar flat>
            <v-toolbar-title>{{this.isNewMode()
                ? $t("adminCoursesEditor.FormTitleNew")
                : `${$t("adminCoursesEditor.FormTitleEdit") }`
              }}
              <v-subheader class="ma-0 pa-0">id={{courseData.data.courseId}}</v-subheader>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
                class="ma-2"
                @click="editParts()"
            >
              {{$t("adminCoursesEditor.button.EditParts")}}
            </v-btn>

            <v-spacer></v-spacer>

<!-- Delete -->
            <v-btn
                color="warning"
                class="ma-2"
                :disabled="isNewMode()"
                @click="deleteItem()"
            >
              {{$t("adminCoursesEditor.button.Delete")}}
            </v-btn>

<!-- Publish -->
            <v-btn
                color="success"
                class="ma-2"
                :disabled="isNewMode() || courseData.data.isPublished"
                @click="publishItem()"
            >
              {{$t("adminCoursesEditor.button.Publish")}}
            </v-btn>

<!-- Save -->
            <v-btn
                color="primary"
                class="ma-2"
                @click="saveAndPublish(false)"
            >
              {{$t("adminCoursesEditor.button.Save")}}
            </v-btn>

<!-- Close -->
            <router-link to="/view/admin/courses"
                         tag="button"
            >
              <v-btn
                  color="info"
                  class="ma-2"
              >
                {{$t("adminCoursesEditor.button.Close")}}
              </v-btn>
            </router-link>
          </v-toolbar>
        </v-col>
      </v-row>

<!-- Course ID, creator-name, course type -->
      <v-row>
        <v-col>
          {{$t("adminCoursesEditor.Type")}}:
          <v-chip>
            {{courseData.courseTypeTitle}}
          </v-chip>
        </v-col>
        <v-col>
          <div v-if="!isNewMode()">
            {{$t("adminCoursesEditor.CreatedBy")}}:
            <v-chip>
              {{courseData.createdByUserName}}
            </v-chip>
          </div>
        </v-col>
        <v-col>
          <div v-if="!isNewMode()">
            {{$t("adminCoursesEditor.CreatedAt")}}:
            <v-chip>
              {{dt2str(courseData.createdAt)}}
            </v-chip>
          </div>
        </v-col>
        <v-col>
          <div v-if="courseData.data.isPublished">
            {{$t("adminCoursesEditor.PublishedAt")}}:
            <v-chip>
              {{courseData.publishedAt ? dt2str(courseData.publishedAt) : ""}}
            </v-chip>
          </div>
          <div v-else>
            {{$t("adminCoursesEditor.Status")}}:
            <v-chip color="accent">
              {{$t("adminCoursesEditor.status.NotPublished")}}
            </v-chip>
          </div>
        </v-col>
      </v-row>

<!-- Attached use groups -->
      <v-row>
        <v-col>
          <v-select
              v-model="selectedUserGroupIds"
              :items="listUserGroups"
              :item-text="x => x.title"
              :item-value="x => x.userGroupId"
              :label="$t('adminCoursesEditor.AttachUserGroups')"
              chips
              deletable-chips
              multiple
              clearable
          >
            <template #selection="{ item }">
              <v-chip>{{item.title}}</v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row>

<!-- Directly attached users -->
      <v-row>
        <v-col>
          <v-select
              v-model="selectedUserIds"
              :items="listUsers"
              :item-text="x => x.title"
              :item-value="x => x.userId"
              :label="$t('adminCoursesEditor.AttachUsers')"
              chips
              deletable-chips
              multiple
              clearable
          ></v-select>
        </v-col>
      </v-row>

<!-- Course title and The course is enabled for users to pass -->
      <v-row>
        <v-col>
          <v-text-field v-model="courseData.data.title"
                        :label="$t('adminCoursesEditor.CourseTitle')"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-checkbox v-model="courseData.data.isEnabled"
                      :label="$t('adminCoursesEditor.Enabled')"
                      :disabled="isNewMode() || !courseData.data.isPublished"
          ></v-checkbox>
        </v-col>
      </v-row>

<!-- Course description -->
      <v-row>
        <v-col>
          <template>
            <div>
              <v-subheader class="pa-0">
                {{$t("adminCoursesEditor.CourseDescription")}}
              </v-subheader>

              <ckeditor-wrapper
                  ref="ckeditorWrapper"
                  v-bind:text.sync="courseData.descriptionBody"
              >
              </ckeditor-wrapper>
            </div>
          </template>
        </v-col>
      </v-row>

    </v-container>

    <!-- a dialog to confirm deletion of the item -->
    <v-dialog v-model="dialogDelete"
              max-width="520px"
    >
      <v-card color="warning">
        <v-card-title class="headline">
          {{$t("adminCoursesEditor.delete.Confirmation")}}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- a dialog to confirm publish of the item -->
    <v-dialog v-model="dialogPublish" max-width="550px">
      <v-card>
        <v-card-title class="headline">
          {{$t("adminCoursesEditor.publish.ConfirmationLine1")}}
        </v-card-title>
        <v-card-title class="headline warning">
          {{$t("adminCoursesEditor.publish.ConfirmationLine2")}}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closePublishDialog">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="publishItemConfirm" >OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>

import {adminCoursesApi} from "@/api/admin.courses.api";
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {adminUserGroupsApi} from "@/api/admin.usergroups.api";
import {adminUsersApi} from "@/api/admin.users.api";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";
import {adminMctypesApi} from "@/api/admin.mctypes.api";
import CkeditorWrapper from "@/components/shared/ckeditorWrapper";

export default {
  name: "adminCoursesPageEditor",
  components: {CkeditorWrapper},
  props: [
  ],

  data: function() {
    return {
      /**
       * All data of the course that is being edited (CourseEditEx)
       */
      courseData: {
        data: {},
        attachedUsers: [],
        attachedUserGroups: [],
      },

      selectedUserIds: [],
      selectedUserGroupIds: [],

      /** Full list of available dbUsers: DbUserEx */
      listUsers: [],
      /** Full list of available user groups: UserGroup */
      listUserGroups: [],

      /** show delete-confirmation dialog*/
      dialogDelete: false,

      /** show publish-confirmation dialog*/
      dialogPublish: false,

    }
  },

  created() {
    this.init();
  },

  watch: {
    "dialogDelete": function(visible) {
      visible || this.closeDeleteDialog();
    },
  },

  computed: {
    /** User who has currently logged in */
    currentUser() {
      return JSON.parse(localStorage.getItem('user'));
    }
  },

  /**
   * When new page is created, the page is redirected to itself with different set of params
   * (to change isNewMode from true to false)
   *
   * So, "created" is not called second time if we move from one list to another.
   * We need to use beforeRouteEnter to workaround the problem.
   *
   * https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation
   * https://github.com/vuejs/vue-router/issues/1875
   **/
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.init();
    });
  },

  methods: {
//region Init
    init: function() {
      //load list of available users
      adminUserGroupsApi.getListUserGroups(this.$store.state, this.$store.dispatch).then(
          user_groups => {
            this.listUserGroups = user_groups
          }
          , errors => showHttpErrorsInToasts(this, errors)
      );

      //load list of available user groups
      adminUsersApi.getListDbUsers(this.$store.state, this.$store.dispatch).then(
          users => {
            this.listUsers = users;
          }
          , errors => showHttpErrorsInToasts(this, errors)
      );

      // load course data (only in edit-mode)
      if (this.isNewMode()) {
        //let's load mctype-title
        adminMctypesApi.getMctype(this.$store.state, this.$store.dispatch, this.$route.params.mctype_id)
          .then(
              mctype => {
                this.courseData.courseTypeTitle = mctype.title;
              }
             , error => showHttpErrorsInToasts(this, error)
          )
      } else {
        this.load_course_data();
      }
    },

    load_course_data() {
      adminCoursesApi.getEditCourseEx(this.$store.state, this.$store.dispatch, this.$route.params.id).then(
          c => {
            this.courseData = c;
            this.selectedUserIds = this.courseData.attachedUsers.map(x => x.userId);
            this.selectedUserGroupIds = this.courseData.attachedUserGroups.map(x => x.userGroupId);
            this.$refs.ckeditorWrapper.content = this.courseData.descriptionBody;
            //this.$refs.ckeditorWrapper.isDisabled = this.courseData.data.isPublished;
          },
          error => showHttpErrorsInToasts(this, error)
      );
    },

    /** true - new course is being created
     *  false - exist course is being edited */
    isNewMode: function() {
      return !this.$route.params.id;
    },
//endregion Init

//region Save, publish, create
    saveAndPublish: function(publishTheCourseAfterSaving) {
      if (this.isNewMode()) {
        this.create_new_course();
      } else {
        this.save_and_publish(publishTheCourseAfterSaving);
      }
    },

    /** create new course, open the course editor*/
    create_new_course: function() {
      adminCoursesApi.createCourse(this.$store.state, this.$store.dispatch
          , {
            mctypeId: this.$route.params.mctype_id,
            title: this.courseData.data.title,
            descriptionBody: this.courseData.descriptionBody,
            descriptionBodyFormat: "HTML",
            attachedUserIds: this.selectedUserIds,
            attachedUserGroupIds: this.selectedUserGroupIds,
          }
      ).then(
          course_id => {
            this.$toast.open({
              message: this.$t("adminCoursesEditor.message.Created", {course: course_id}),
              type: 'success',
              position: 'top-right'
            });
            this.$router.replace({ path: `/view/admin/courses/${course_id}/edit` })

            //update statistics on left side-bar (see Normal view)
            this.$bus.$emit("refreshCountUnprocessedItems", {courses: true});
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
    },

    /** Save all course data and optionally publish the course */
    save_and_publish: function(publishTheCourseAfterSaving, onSuccess) {
      adminCoursesApi.updateCourse(this.$store.state, this.$store.dispatch
          , {
            courseId: this.courseData.data.courseId,
            title: this.courseData.data.title,
            attachedUserIds: this.selectedUserIds,
            attachedUserGroupIds: this.selectedUserGroupIds,
            descriptionBody: this.courseData.descriptionBody,
            descriptionBodyFormat: this.courseData.descriptionBodyFormat,
            isPublished: publishTheCourseAfterSaving
                ? true
                : undefined,
            isDisabled: this.isNewMode()
                ? false
                : !this.courseData.data.isEnabled
          }
      ).then(
          () => {
            this.$toast.open({
              message: publishTheCourseAfterSaving
                  ? this.$t("adminCoursesEditor.message.Published", {"course": this.courseData.data.courseId})
                  : this.$t("adminCoursesEditor.message.Saved", {"course": this.courseData.data.courseId})
              , type: 'success'
              , position: 'top-right'
            });
            this.load_course_data();
            if (onSuccess) {
              onSuccess();
            }

            //update statistics on left side-bar (see Normal view)
            this.$bus.$emit("refreshCountUnprocessedItems", {courses: true});
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
    },
//endregion Save, publish, create

//region Delete item
    /** Show confirmation dialog to delete selected item*/
    deleteItem () {
      this.dialogDelete = true;
    },

    /** Cancel the dialog to confirm a deletion of the selected item*/
    closeDeleteDialog () {
      this.dialogDelete = false;
    },

    /** Item was confirmed to delete. Make real deletion*/
    deleteItemConfirm () {
      adminCoursesApi.deleteCourse(this.$store.state, this.$store.dispatch
          , this.courseData.data.courseId
      ).then(
          () => {
            this.$toast.open({
              message: this.$t("adminCoursesEditor.message.Deleted", {"course": this.courseData.data.courseId}),
              type: 'success',
              position: 'top-right'
            });
            this.$router.replace({ path: `/view/admin/courses` })

            this.$bus.$emit("refreshCountUnprocessedItems", {courses: true});
          }
      ).catch(error => { showHttpErrorsInToasts(this, error); });
      this.closeDeleteDialog();
    },
//endregion Delete item

//region Publish item
    /** Show confirmation dialog to publish selected item*/
    publishItem () {
      this.dialogPublish = true;
    },

    /** Cancel the dialog to confirm a publishing of the selected item*/
    closePublishDialog () {
      this.dialogPublish = false;
    },

    /** Item was confirmed to delete. Save and publish the item*/
    publishItemConfirm () {
      this.save_and_publish(true);
      this.closePublishDialog();
    },
//endregion Publish item

//region Edit parts
    editParts() {
      const that = this;
      if (this.isReadonlyMode()) {
        that.$router.push({path: `/view/admin/courses/${that.courseData.data.courseId}/parts`})
      } else {
        this.save_and_publish(false
            , function edit_parts() {
              that.$router.push({path: `/view/admin/courses/${that.courseData.data.courseId}/parts`})
            }
        );
      }
    },
//endregion Edit parts

//region Permissions

    /**
     * False if the given course can be edited  by the current user.
     * It means, that either the current user is FA or the current user is GA and he is creator of the course
     */
    isReadonlyMode: function() {
      if (this.isNewMode() || this.currentUser.isFullAdmin) {
        return false;
      }
      return this.currentUser.userId !== this.courseData.createdBy;
    },

//endregion Permissions

//region Utils
    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },
//endregion Utils

  }
}
</script>

<style scoped>

</style>