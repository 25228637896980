import {adminUsersApi} from "@/api/admin.users.api";
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";

export const apiFaUsersController = {
    getArrayOfInputModelCreate
    , loadUsers
    , loadAdUsers
    , makeEditRequest
    , makeInsertAdUserRequest
    , makeInsertLocalUserRequest
    , makeDeleteRequest
}

/**
 * convert array of AD-users to array of FaUserController.InputModelCreate
 */
export function getArrayOfInputModelCreate(selectedAdUsers) {
    const dest = [];
    for (let item of selectedAdUsers) {
        dest.push({
            title: item.fullName,
            userName: item.domainUserName,
            flags: 0,
        });
    }

    return dest;
}

//region Load users
/** Load list of all active DBUserEx from server */
function loadUsers(vm, setListItemsOnSuccess) {
    return adminUsersApi.getListDbUsers(vm.$store.state, vm.$store.dispatch).then(
        setListItemsOnSuccess
        , errors => showHttpErrorsInToasts(vm, errors)
    )
}

/** Load list of AD-users to display in the insert-dialog*/
function loadAdUsers(vm, setListItemsOnSuccess) {
    return adminUsersApi.getListAdUsers(vm.$store.state, vm.$store.dispatch).then(
        setListItemsOnSuccess
        , errors => showHttpErrorsInToasts(vm, errors)
    )
}
//endregion Load users

//region Edit
/** Update user info
 * and set Full admin rights */
function makeEditRequest(vm, listItems, editedItem, editedItemIndex) {
    const current_user_info = listItems[editedItemIndex];

    //the object was edited
    adminUsersApi.updateDbUser(
        vm.$store.state,
        vm.$store.dispatch,
        {
            "userId": editedItem.userId,
            "title": editedItem.title,
            "disabled": editedItem.isDisabled,
            "updateMask":
                editedItem.kind === 0
                    ? 0x1 | 0x2 //UserUpdate.tuser_ufields.TITLE | UserUpdate.tuser_ufields.DISABLED
                    : 0x1 | 0x2 | 0x4, //UserUpdate.tuser_ufields.TITLE | UserUpdate.tuser_ufields.DISABLED | UserUpdate.tuser_ufields.USER_NAME
            "isPasswordChanged": editedItem.setNewPassword,
            "password": editedItem.localPassword,
            "userName": editedItem.userName
        }
    ).then(
        new_item => {
            //user info is updated
            Object.assign(listItems[editedItemIndex], new_item)

            //do we need to update "Full admin" rights?
            if (current_user_info.isFullAdmin !== editedItem.isFullAdmin) {
                adminUsersApi.setFA(
                    vm.$store.state,
                    vm.$store.dispatch,
                    {
                        "userId": editedItem.userId,
                        "fullAdmin": editedItem.isFullAdmin
                    }
                ).then(
                    new_item => Object.assign(listItems[editedItemIndex], new_item)
                    , error => showHttpErrorsInToasts(vm, error)
                )
            }
        }
        , error => showHttpErrorsInToasts(vm, error)
    )
}
//endregion Edit

//region Insert users
function makeInsertAdUserRequest(vm, listItems, adUsersToInsert) {
    adminUsersApi.createDbUserAd(
        vm.$store.state,
        vm.$store.dispatch,
        adUsersToInsert
    ).then(
        new_items => {
            for (const item of new_items) {
                listItems.push(item);
            }
        }
        , error => showHttpErrorsInToasts(vm, error)
    )
}

/**
 * Create new local user
 * @param vm
 * @param listItems
 * @param userInfo
 */
function makeInsertLocalUserRequest(vm, listItems, userInfo) {
    adminUsersApi.createDbUserLocal(
        vm.$store.state,
        vm.$store.dispatch,
        {
            "userName": userInfo.localLogin,
            "title": userInfo.title,
            "disabled": userInfo.isDisabled,
            "password": userInfo.localPassword,
            "isFullAdmin": userInfo.isFullAdmin
        }
    ).then(
        item => {
            listItems.push(item);
        }
        , error => showHttpErrorsInToasts(vm, error)
    )
}
//endregion Insert users

//region Delete users
function makeDeleteRequest(vm, listItems, indexToDelete) {
    adminUsersApi.deleteDbUser(
        vm.$store.state,
        vm.$store.dispatch,
        listItems[indexToDelete].userId
    ).then(
        () => listItems.splice(indexToDelete, 1)
        , error => showHttpErrorsInToasts(vm, error)
    )
}
//endregion Delete users