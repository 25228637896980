/**
 * Auto-log-out if 401 (Unauthorized) is received
 *
 * @param response
 *      see fetch
 *          https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
 * @param dispatch
 *      vuex store.dispatch
 * @return {*}
 *      array of error messages
 */
import {parseHttpErrorResponse} from "@/helpers/handleHttpErrors";

export function handleResponse_401_Unauthorized(response, dispatch) {
    return response.text().then(
        text => {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    // auto logout if 401 (Unauthorized) response returned from api
                    dispatch('authentication/logout')
                    location.reload(true);
                }
                //!TODO: handle other possible error statuses, i.e. see https://dev.to/bawa_geek/handling-the-axios-errors-gracefully-2c3g

                const error = parseHttpErrorResponse(data)
                    || response.statusText
                    || `Error ${response.status}`
                ;

                return Promise.reject(error);
            }

            return data;
        }
    );
}