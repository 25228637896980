/**
 * Login, logout
 * Manage info about currently logged-in user
 * in localStorage (key - "user" === struct AuthenticateResponse)
 */

import {handleResponse_401_Unauthorized} from "@/helpers/handle401";

export const loginApi = {
    login
    , logout
};

//region Login, logout

/**
 * Login to the system.
 * Send login-request to the server
 * and save info about currently logged user to localStorage.
 *
 * @param rootStoreState
 *      vuex store.state
 * @param storeDispatch
 *      vuex store.dispatch
 * @param userName
 * @param password
 */
function login(rootStoreState, storeDispatch, userName, password) {
    const request_options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(
            {
                "Username" : userName,
                "Password" : password
            }
        )
    };

    // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
    return fetch(`${rootStoreState.apiUrl}/api/login/authenticate`, request_options)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch)  )
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

/**
 * Log out - remove info about currently active user from the local storage.
 */
function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}
//endregion Login, logout