<!--
  Master user (MU) is a special not AD-user
  that can register DB-masterUser and first grand full admin them.

  Display list of all AD-masterUser/DB-masterUser,
  allow to grand/revoke full-admin rights and logoff.
-->
<template>
  <v-app id="inspire">

<!-- Toolbar -->
    <v-app-bar app>
      <v-toolbar-title>{{$t("master.PanelTitle")}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-row
          align="center"
          justify="space-around"
      >
        <v-btn
            depressed
            color="primary"
            :disabled="!is_grand_allowed() || submitted"
            @click="send_request_to_server(true)"
        >
          {{$t("master.Grand")}}
        </v-btn>
        <v-btn
            color="secondary"
            :disabled="!is_revoke_allowed() || submitted"
            @click="send_request_to_server(false)"
            depressed
        >
          {{$t("master.Revoke")}}
        </v-btn>
        <router-link
            to="/login"
            tag="button"
        >
          <v-btn
              icon
              color="gray"
          >
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </router-link>
      </v-row>
    </v-app-bar>

<!-- Main view -->
    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col cols="2">
<!-- Description -->
            <v-sheet rounded="lg">
              <v-card>
                <v-card-text>
                  {{$t("master.Desc")}}
                </v-card-text>
              </v-card>
            </v-sheet>
          </v-col>

  <!-- List of users -->
          <v-col>
            <v-sheet
                min-height="70vh"
                rounded="lg"
            >
              <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="listUsers"
                  :single-select="false"
                  item-key="domainUserName"
                  show-select
                  class="elevation-1"
              >
                <!-- disable Toggle-select-all button -->
                <template v-slot:header.data-table-select> </template>
                <template v-slot:item.isFullAdmin="{ item }">
                  <v-simple-checkbox
                      v-model="item.isFullAdmin"
                      disabled
                  ></v-simple-checkbox>
                </template>
              </v-data-table>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import {masterUserApi} from "@/api/master-user.api";
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";

export default {
  name: "MasterUserPanel",
  props: [

  ],

  data: function() {
    return {
      /** list of users received from the server */
      listUsers: [],
      /** true if we send request to server and answer is still not received*/
      submitted: false,
      /** last error received from the server */
      lastError: null,

      /** list of checked users (user objects)*/
      selected: [],
      headers: [
        {
          text: this.$t("master.header.ad"),
          align: 'start',
          value: 'domainUserName',
        },
        {
          text: this.$t("master.header.Name"),
          value: 'fullName'
        },
        {
          text: this.$t("master.header.FA"),
          value: 'isFullAdmin'
        },
        {
          text: this.$t("master.header.UserId"),
          value: 'dbUser.userId'
        },
      ],
    }
  },

  created() {
    this.loadUsers();
  },

  watch: {
    /**
     * selected => checkedUsers
     * */
    selected : function() {
      this.checkedUsers = [];
      for (let i = 0; i < this.selected.length; ++i) {
        const selected_domain_name = this.mapUsers[this.selected[i].domainUserName];
        this.checkedUsers.push(selected_domain_name);
      }
      this.$forceUpdate();
    }
  },

  methods: {
    /**
     * Load list of all AD-users from server
     *    api/MasterUser
     */
    loadUsers() {
      masterUserApi.getUsers(this.$store.state, this.$store.dispatch).then(
          list => {
            this.listUsers = list;
            this.checkedUsers = [];
            this.mapUsers = {};
            for (let i = 0; i < this.listUsers.length; ++i) {
              this.mapUsers[this.listUsers[i].domainUserName] = i;
            }
          },
          error => {
            console.log(error);
          }
      )
    },

    /** Grand is allowed if at least one user is checked
     * and all checked users are not FA */
    is_grand_allowed: function() {
      if (this.selected.length === 0) {
        return false;
      }
      //let's ensure, that all checked sideBarItems are not FA
      for (const user of Object.values(this.selected)) {
        if (user.isFullAdmin) {
          return false;
        }
      }
      return true;
    },

    /**  Revoke is allowed if at least one user is checked
     * and all checked users are FA */
    is_revoke_allowed: function() {
      if (this.selected.length === 0) {
        return false;
      }
      //let's ensure, that all checked sideBarItems are FA
      for (const user of Object.values(this.selected)) {
        if (!user.isFullAdmin) {
          return false;
        }
      }
      return true;
    },

    /** Send request to server to Grand or Revoke FA-rights.
     * If success we need to refresh the list of the users.
     * */
    send_request_to_server: function(grandFA) {
      this.submitted = true;
      masterUserApi.updateUsersRights(
        this.$store.state,
        this.$store.dispatch,
          {
            GrandFA: grandFA ? this.get_logins_of_checked_users() : null,
            RevokeFA: !grandFA ? this.get_logins_of_checked_users() : null,
          }
      ).then(
          () => {
            this.submitted = false;
            this.loadUsers();
            this.lastError = null;
            this.selected = [];
            this.$forceUpdate();
            this.$toast.open({
              message: "Success",
              type: 'success',
              position: 'top-right'
            });
          },
          error => {
            this.lastError = error;
            this.submitted = false;
            showHttpErrorsInToasts(this.lastError);
          }
      )
    },

    /**
     * Generate list of logis of the checked users
     */
    get_logins_of_checked_users: function() {
      const dest = [];
      for (const user of Object.values(this.selected)) {
        dest.push(user.domainUserName);
      }
      return dest;
    },
  }
}
</script>

<style scoped>
.e21-scrollable-container {
  height:50vh;
  overflow-y: scroll;
}

.e21-toolbar {
  margin-bottom: 20px;
  padding: 5px 5px 5px 5px;
  background-color: #F0F0F0;
}

.e21-admin {
  font-weight: bold;
}

</style>