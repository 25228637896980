import {compareUtils} from "@/helpers/compareUtils";

/**
 * Utils for shared/treeForms component
 * */
export const treeFormsUtils = {
    getChildren,
    getFolderSid,
    getFileSid,
    getFolderItem,
    getFileItem,
    getItemTitle,
    sortChildren,
    getPathToRootFolder
}

/** Generate list of children (folders and files) for given folder */
function getChildren(subfolders, files, allItems) {
    let dest = [];

    //add all subfolders
    for (let f of subfolders) {
        const sid = treeFormsUtils.getFolderSid(f.folderId);
        dest.push(
            treeFormsUtils.getFolderItem(
                f
                , allItems && allItems[sid]
                    ? allItems[sid].children
                    : []
                , allItems
            ));
    }

    //add all files
    for (let f of files) {
        dest.push(treeFormsUtils.getFileItem(f, allItems));
    }

    return dest;
}

function getPathToRootFolder(folderId) {
    const sid = treeFormsUtils.getFolderSid(folderId);
    return {
        isFolder: true,
        data: {
            folderId: folderId,
            title: ".."
        },
        children: [],
        id: sid,
        isPathToRoot: true
    }
}

//region Generate unique identifiers for folder/file-items
/** generate folder uid */
function getFolderSid(folderId) {
    return `folder_${folderId}`;
}

/** generate file uid */
function getFileSid(archiveFile) {
    return `file_${archiveFile.parentFolderId}_${archiveFile.fileData.fileId}`;
}
//endregion Generate unique identifiers for folder/file-items

//region Generate new items
/**
 * Generate new folder item with uid, children and isFolder flag
 * @param f
 * @param subitems
 *      Optional previously downloaded subitems.
 *      We can reuse them i.e after renaming of the folder.
 * @param allItems
 * @return {{isFolder: boolean, data, children, id: string}}
 */
function getFolderItem(f, subitems, allItems) {
    const sid = treeFormsUtils.getFolderSid(f.folderId);
    let dest = {
        isFolder: true,
        data: f,
        children: subitems,
        id: sid
    }
    if (allItems) {
        allItems[dest.id] = dest;
    }
    return dest;
}

/**
 * generate new file item with uid, children and isFolder flag
 * @param archiveFile
 * @param allItems
 * @return {{isFolder: boolean, data, id: string}}
 */
function getFileItem(archiveFile, allItems) {
    const sid = treeFormsUtils.getFileSid(archiveFile);
    let dest = {
        isFolder: false,
        data: archiveFile,
        id: sid
    }
    if (allItems) {
        allItems[dest.id] = dest;
    }
    return dest;
}
//endregion Generate new items

/** get title of folder/file */
function getItemTitle(item) {
    return item.isFolder
        ? item.data.title
        : item.data.ffFilename; //files are not sorted by sortorder
}

function getItemSortorder(item) {
    return item.data.sortorder;
}

/** sort in place the children of the given folder */
function sortChildren(items) {
    items.sort(function(a, b) {
        if (a.isFolder === b.isFolder) {
            const a_title = treeFormsUtils.getItemTitle(a);
            const b_title = treeFormsUtils.getItemTitle(b);
            const a_sortorder = getItemSortorder(a);
            const b_sortorder = getItemSortorder(b);
            return compareUtils.compareBySortorderAndTitle(a_sortorder, a_title, b_sortorder, b_title );
        } else {
            return a.isFolder
                ? -1
                : 1;
        }
    });
}
