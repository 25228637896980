import { render, staticRenderFns } from "./adminFormsPage.vue?vue&type=template&id=f84c6bce&scoped=true&"
import script from "./adminFormsPage.vue?vue&type=script&lang=js&"
export * from "./adminFormsPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f84c6bce",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VToolbar,VToolbarTitle})
