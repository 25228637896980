/**
 * Generate URL with params as
 *      url?param1=value1&param2=value2 ..
 * where the params are set by jsonParam
 *      {
 *          param1: value1,
 *          param2: value2
 *      }
 *
 * @param url
 * @param jsonParams
 * @return {string|*}
 */
export function getUrlWithParams(url, jsonParams) {
    if (jsonParams) {
        const url_param = new URLSearchParams(jsonParams).toString();
        return url_param
            ? `${url}?${url_param}`
            : url;
    } else {
        return url;
    }
}