import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {adminUserGroupsApi} from "@/api/admin.usergroups.api";

export const apiFaUserGroupsController = {
    loadUserGroups
    , loadUserGroupsViewEx
    , makeEditRequest
    , makeInsertRequest
    , makeDeleteRequest
}

//region Requests to server - Get
/** Load list of all active UserGroup from server */
function loadUserGroups(vm, setListItemsOnSuccess) {
    adminUserGroupsApi.getListUserGroups(vm.$store.state, vm.$store.dispatch).then(
        setListItemsOnSuccess
        , errors => showHttpErrorsInToasts(vm, errors)
    )
}

/** Load list of all active UserGroup from server - UserGroupViewEx */
function loadUserGroupsViewEx(vm, setListItemsOnSuccess) {
    adminUserGroupsApi.getListUserGroupsViewEx(vm.$store.state, vm.$store.dispatch).then(
        setListItemsOnSuccess
        , errors => showHttpErrorsInToasts(vm, errors)
    )
}
//endregion Requests to server - Get

//region Requests to server - CUD
/**
 * Update user group
 * and modify corresponded item in the listItems
 * @param vm
 * @param listItems
 * @param updatedData
 * @param indexInTheList
 *      0-based index of the user group
 *      in the listItems
 */
function makeEditRequest(vm, listItems, updatedData, indexInTheList) {
    //the object was edited
    adminUserGroupsApi.updateUserGroup(
        vm.$store.state,
        vm.$store.dispatch,
        {
            "userGroupId": updatedData.data.userGroupId,
            "title": updatedData.data.title,
            "description": updatedData.data.description
        }
    ).then(
        new_item => Object.assign(listItems[indexInTheList], new_item)
        , error => showHttpErrorsInToasts(vm, error)
    )
}


/**
 * Insert new user group to listItems
 * and insert it to the listItems
 * @param vm
 * @param listItems
 * @param newUserGroup
 */
function makeInsertRequest(vm, listItems, newUserGroup) {
    adminUserGroupsApi.createUserGroup(
        vm.$store.state,
        vm.$store.dispatch,
        newUserGroup
    ).then(
        new_item => listItems.push(new_item)
        , error => showHttpErrorsInToasts(vm, error)
    )
}

/**
 * Delete user group on server
 * and remove it from the listItems
 *
 * @param vm
 * @param listItems
 * @param indexToDelete
 */
function makeDeleteRequest(vm, listItems, indexToDelete) {
    adminUserGroupsApi.deleteUserGroup(
        vm.$store.state,
        vm.$store.dispatch,
        listItems[indexToDelete].data.userGroupId
    ).then(
        () => listItems.splice(indexToDelete, 1)
        , error => showHttpErrorsInToasts(vm, error)
    )
}
//endregion Requests to server - CUD

