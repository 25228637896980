<!--
Admin part.
Statistic by messages for the user
(with server side pagination)
-->
<template>
  <div>
    <v-toolbar-title>
      <router-link :to="`/view/admin/messages/${this.$route.params.id}/edit`"
                   tag="button">
        {{$t("adminUserGroupsStatByMessages.UserGroupTitle"
          , {
            "title": this.userGroupData ? this.userGroupData.data.title : ""
          }
      )}}
      </router-link>
      <v-subheader class="ma-0 pa-0">id={{$route.params.id}}</v-subheader>

    </v-toolbar-title>

    <v-data-table
        :headers="headers"
        :items="listItems"
        item-key="mutaskId"
        class="elevation-1"
        :options.sync="paginationOptions"
        :server-items-length="totalCountItems"
        :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            {{$t("adminUserGroupsStatByMessages.FormTitle")}}
          </v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
        </v-toolbar>
      </template>

<!-- Status -->
      <template v-slot:item.isDone="{ item }">
        <div class="d-flex justify-center">
          <v-icon
              small
              :color="item.isDone ? 'green' : 'red'"
          >
            {{item.isDone ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle-outline" }}
          </v-icon>
        </div>
      </template>

<!-- Done at -->
      <template v-slot:item.lastReadAt="{ item }">
        <div class="d-flex justify-center">
          {{ item.lastReadAt ? dt2str(item.lastReadAt) : ""}}
        </div>
      </template>

<!-- published at -->
      <template v-slot:item.publishedAt="{ item }">
        <div class="d-flex justify-center">
          {{ item.publishedAt
            ? dt2str(item.publishedAt)
            : $t("adminUserGroupsStatByMessages.status.NotPublished")
          }}
        </div>
      </template>

      <!-- actions column: Edit, delete - with tooltips -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-center">
          <!-- View statistics by users -->
          <router-link :to="'/view/admin/messages/' + item.messageData.messageId + '/stat'"
                       tag="button"
                       v-if="item.publishedAt">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                >
                  mdi-account-alert
                </v-icon>
              </template>
              <span>
                {{$t("adminUserGroupsStatByMessages.tooltip.StatByUsers")}}
              </span>
            </v-tooltip>
          </router-link>
        </div>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";
import {debounce} from "lodash";
import {adminUserGroupsApi} from "@/api/admin.usergroups.api";

export default {
  name: "adminMessagesPageStatByUsers",

  data: function() {
    return {
      /** ID of the message provided through URL*/
      userId: this.$route.params.id,

      /** Message-struct loaded from server by messageId */
      userGroupData: {
        data: {}
      },

      /** List of UserStatusByMessage */
      listItems: [],

      /** Columns of the list of DB-users
       * Sorting is disabled because of implementation of move up/down
       * */
      headers: [
        {
          text: this.$t("adminUserGroupsStatByMessages.headers.Id"),
          align: 'start',
          value: 'messageData.messageId',
          sortable: true,
          width: "100"
        },
        {
          text: this.$t("adminUserGroupsStatByMessages.headers.CreatedBy"),
          value: 'createdByUserName',
          sortable: true,
          align: 'left',
          width: 160
        },
        {
          text: this.$t("adminUserGroupsStatByMessages.headers.PublishedAt"),
          value: 'publishedAt',
          sortable: true,
          align: 'center',
          width: 160
        },
        {
          text: this.$t("adminUserGroupsStatByMessages.headers.Type"),
          value: 'messageTypeTitle',
          sortable: true,
          align: 'center',
          width: 160
        },
        {
          text: this.$t("adminUserGroupsStatByMessages.headers.Message"),
          value: 'messageData.title',
          sortable: true,
          align: 'left'
        },
        {
          text: this.$t("adminUserGroupsStatByMessages.headers.ReadBy"),
          value: 'countUsersRead',
          sortable: true,
          width: 120,
          align: 'center'
        },
        {
          text: this.$t("adminUserGroupsStatByMessages.headers.UnreadBy"),
          value: 'countUsersUnread',
          sortable: true,
          width: 120,
          align: 'center'
        },
        {
          text: this.$t("adminUserGroupsStatByMessages.headers.Actions"),
          value: 'actions',
          sortable: false,
          width: 100,
          align: 'center',
        },

      ],

      /**
       * Current pagination settings
       *
       * Server side pagination is used
       * see: https://vuetifyjs.com/en/api/v-data-footer/#props
       * */
      paginationOptions: {
        sortDesc: [true],
        sortBy: ["messageData.messageId"]
      },
      totalCountItems: 0,

      /** the data is loading from the server */
      loading: false
    }
  },

  watch: {
    /** When user select a message type, we need to reload list of the messages of the selected type*/
    currentMctypeId() {
      //message type was changed
      //we need to start from page 1, but we can keep currently selected ordering parameters
      this.debouncedLoadDataFromServer();
    },

    paginationOptions: {
      handler(newValue, oldValue) {
        // console.log("options.new", JSON.stringify(newValue));
        // console.log("options.old", JSON.stringify(oldValue));

        if (newValue !== oldValue) {
          this.debouncedLoadDataFromServer();
        }
      },
      deep: true
    },

  },

  created() {
    // _.debounce is a function provided by lodash to limit how
    // often a particularly expensive operation can be run.
    // In this case, we want to limit how often we access
    // yesno.wtf/api, loading until the user has completely
    // finished typing before making the ajax request. To learn
    // more about the _.debounce function (and its cousin
    // _.throttle), visit: https://lodash.com/docs#debounce
    this.debouncedLoadDataFromServer = debounce(this.loadDataFromServer, 50)
    this.debouncedLoadDataFromServer();

    //load info about the user group
    adminUserGroupsApi.getUserGroup(this.$store.state, this.$store.dispatch, this.$route.params.id).then(
        m => {
          this.userGroupData = m;
        },
        error => showHttpErrorsInToasts(this, error)
    );
  },

  methods: {
    /** Load list of messages form server - one page only*/
    loadDataFromServer: function() {
      const pn = {
        page: this.paginationOptions.page,
        itemsPerPage: this.paginationOptions.itemsPerPage,
        sortBy: this.paginationOptions.sortBy,
        sortDesc: this.paginationOptions.sortDesc,
      };

      this.loading = true;
      adminUserGroupsApi.getStatByMessages(this.$store.state, this.$store.dispatch, this.userId, pn).then(
          json => {
            this.listItems = json.items;
            this.paginationOptions.page = json.paginationOptions.page;
            //We need to use arrays: https://github.com/vuetifyjs/vuetify/issues/12929
            this.paginationOptions.sortBy = [json.paginationOptions.sortBy];
            this.paginationOptions.sortDesc = [json.paginationOptions.sortDesc];

            this.paginationOptions.itemsPerPage = json.paginationOptions.itemsPerPage;
            this.totalCountItems = json.totalCountItems;
            this.loading = false;
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });
    },

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },

  }
}
</script>

<style scoped>

</style>