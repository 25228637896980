<!-- Editor of users

     It's based on the follow code:
        https://codepen.io/pen/?editors=1010
-->
<template>
  <v-data-table
      :headers="headers"
      :items="listItems"
      :single-select="true"
      item-key="userId"
      class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>
          {{$t("adminUsers.FormTitle")}}
        </v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>

        <v-spacer></v-spacer>

<!-- a dialog to create new local not-AD user -->
        <v-dialog
            v-model="dialogInsertLocal"
            max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                class="ma-2"
                v-bind="attrs"
                v-on="on"
            >
              {{$t("adminUsers.button.NewLocalUser")}}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t("adminUsers.dialogInsertLocalUser") }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <!-- About v-if, see: https://stackoverflow.com/questions/51472947/vuetifys-autofocus-works-only-on-first-modal-open -->

                    <!-- There is a problem with auto-complition of password and username editboxes
                         https://github.com/vuetifyjs/vuetify/issues/2792
                         It can be solvable by using random name and not empty autocomplete
                    -->
                    <v-text-field
                        v-model="editedItem.localLogin"
                        autocomplete="new-username"
                        :name="`username_${Math.random()}`"
                        :label="$t('adminUsers.Login')"
                        :rules="loginRules"
                        autofocus
                    ></v-text-field>
                    <v-text-field
                        v-model="editedItem.localPassword"
                        autocomplete="new-password"
                        type="password"
                        :name="`password_${Math.random()}`"
                        :label="$t('adminUsers.Password')"
                        :rules="passwordRules"
                    ></v-text-field>

                    <v-text-field
                        v-model="editedItem.title"
                        :label="$t('adminUsers.UserName')"
                        :rules="userNameRules"
                    ></v-text-field>
                    <v-checkbox
                        v-model="editedItem.isFullAdmin"
                        :label="$t('adminUsers.FullAdmin')"
                    ></v-checkbox>
                    <v-checkbox
                        v-model="editedItem.isDisabled"
                        :label="$t('adminUsers.Disabled')"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="closeInsertLocalDialog"
              >
                {{$t("button.Cancel")}}
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="createNewLocalUser"
              >
                {{$t("button.Save")}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

<!-- a dialog to create new AD-based-user (select available AD-user from the list and create new DB-user with default values) -->
        <v-dialog
            v-model="dialogInsertAd"
            max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                class="ma-2"
                v-bind="attrs"
                v-on="on"
            >
              {{$t("adminUsers.button.NewAdUser")}}
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">
                {{$t("adminUsers.dialog.title")}}
              </span>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text style="height: 40vh; overflow-y: scroll;">
              <v-data-table
                  v-model="selectedAdUsers"
                  :headers="headersAdList"
                  :items="listAdUsers"
                  :single-select="false"
                  item-key="domainUserName"
                  class="elevation-1"
                  show-select
                  disable-pagination
                  hide-default-footer
              >
                <!-- disable Toggle-select-all button -->
                <template v-slot:header.data-table-select> </template>

              </v-data-table>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="closeInsertDialog"
              >
                {{$t("button.Cancel")}}
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="createNewUser"
              >
                {{$t("adminUsers.button.Register")}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

<!-- a dialog to edit exist user -->
        <v-dialog
            v-model="dialogEdit"
            max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">{{ dialogEditTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <!-- About v-if, see: https://stackoverflow.com/questions/51472947/vuetifys-autofocus-works-only-on-first-modal-open -->
                    <v-text-field
                        v-model="editedItem.title"
                        :label="$t('adminUsers.UserName')"
                        v-if="dialogEdit"
                        :rules="userNameRules"
                        autofocus
                    ></v-text-field>
                    <v-checkbox
                        v-model="editedItem.isFullAdmin"
                        :label="$t('adminUsers.FullAdmin')"
                    ></v-checkbox>
                    <v-checkbox
                        v-model="editedItem.isDisabled"
                        :label="$t('adminUsers.Disabled')"
                    ></v-checkbox>
                    <v-text-field
                        v-model="editedItem.userName"
                        :label="$t('adminUsers.Login')"
                        v-if="editedItem.kind === 1"
                        :rules="loginRules"
                        autofocus
                    ></v-text-field>
                    <v-checkbox
                        v-model="editedItem.setNewPassword"
                        :label="$t('adminUsers.SetNewPassword')"
                        v-if="editedItem.kind === 1"
                    ></v-checkbox>
                    <v-text-field
                        v-model="editedItem.localPassword"
                        v-if="editedItem.setNewPassword && editedItem.kind === 1"
                        type="password"
                        :rules="passwordRules"
                        autocomplete="edit-password"
                        :name="`password_${Math.random()}`"
                        :label="$t('adminUsers.Password')"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-subheader class="ma-0 pa-0">id={{editedItem.userId}}</v-subheader>

              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="closeEditDialog"
              >
                {{$t("button.Cancel")}}
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="saveUpdates"
              >
                {{$t("button.Save")}}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

<!-- a dialog to confirm deletion of the item -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">
              {{$t("adminUsers.tooltip.delete.Confirm")}}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDeleteDialog">
                {{$t("button.Cancel")}}
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                {{$t("button.Ok")}}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
    </template>

<!-- actions column: Edit, delete -->
    <template v-slot:item.actions="{ item }">
      <div class="d-flex justify-center">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>
            {{$t("adminUsers.tooltip.EditUser")}}
          </span>
        </v-tooltip>

        <v-divider vertical class="mx-2"/>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                v-bind="attrs"
                v-on="on"
                @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>
            {{$t("adminUsers.tooltip.DeleteUser")}}
          </span>
        </v-tooltip>
        <v-divider vertical class="mx-2"/>

        <!-- All messages assigned to the user -->
        <router-link :to="'/view/admin/users/' + item.userId + '/stat/messages'"
                     tag="button">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
              >
                mdi-account-check
              </v-icon>
            </template>
            <span>
              {{$t("adminUsers.tooltip.StatByMessages")}}
            </span>
          </v-tooltip>
        </router-link>

        <v-divider vertical class="mx-2"/>

        <!-- All courses assigned to the user -->
        <router-link :to="'/view/admin/users/' + item.userId + '/stat/courses'"
                     tag="button"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
              >
                mdi-account-alert
              </v-icon>
            </template>
            <span>
              {{$t("adminUsers.tooltip.StatByCourses")}}
            </span>
          </v-tooltip>
        </router-link>
      </div>
    </template>

<!-- login name: show [DISABLED] beside the name for disabled users -->
    <template
        v-slot:item.userName="{ item }"
    >
      {{item.userName}}
      <v-chip
          class="info"
          v-if="item.kind === 1"
      >
        {{$t("adminUsers.status.Local")}}
      </v-chip>

    </template>

<!-- Roles: Full admin and group admins-->
    <template
        v-slot:item.roles ="{ item }"
    >
      <v-chip
          class="warning"
          v-if="item.isDisabled"
      >
        {{$t("adminUsers.status.Disabled")}}
      </v-chip>
      {{getUserRolesAsString(item)}}
    </template>
  </v-data-table>
</template>

<script>
import {apiDbUserEx} from "@/server/dbUserExHelper";
import {apiFaUsersController, apiFaUsersController as apiDataUsers} from "@/server/faUsersControllerUtils";
import {deepClone} from "@/helpers/gutils";

export default {
  name: "adminUsersPage",
  props: [
  ],

  data: function() {
    return {
      /** Main list of DB-users loaded from the server*/
      listItems: [],
      /** List of AD-users from the server (it's used in insert-dialog)*/
      listAdUsers: [],
      /** List of AD-users that were selected in the insert-dialog*/
      selectedAdUsers: [],
      /** last error received from the server */
      lastError: null,

      /** Columns of the list of DB-users */
      headers: [
        {
          text: this.$t("adminUsers.headers.Id"),
          align: 'start',
          value: 'userId',
        },
        {
          text: this.$t("adminUsers.headers.DisplayName"),
          value: 'title'
        },
        {
          text: this.$t("adminUsers.headers.Login"),
          value: 'userName',
          align: 'center'
        },
        {
          text: this.$t("adminUsers.headers.Roles"),
          value: 'roles'
        },
        {
          text: this.$t("adminUsers.headers.Actions"),
          value: 'actions',
          sortable: false,
          align: 'center'
        },
      ],
      /** Columns of the list of AD-users */
      headersAdList: [
        {
          text: this.$t("adminUsers.headers.Login"),
          align: 'start',
          value: 'domainUserName',
        },
        {
          text: this.$t("adminUsers.headers.DisplayName"),
          value: 'fullName'
        },
      ],

      /** show edit dialog to edit DB-user properties*/
      dialogEdit: false,
      /** show delete-confirmation dialog*/
      dialogDelete: false,
      /** show AD-user selector to select available user-name before registration of new DB-user */
      dialogInsertAd: false,
      /** show dialog to insert local user name and password */
      dialogInsertLocal: false,

      /** The index of the currently edited item in listItems
       * -1 - there is no item that is being edited*/
      editedIndex: -1,
      /** A copy of the item that is being edited*/
      editedItem: {
        userId: 0,
        userName: "",
        title: "",
        isFullAdmin: false,
        isDisabled: false,
        setNewPassword: false,
        localPassword: null,
        localLogin: null
      },
      /** Default values of the item.
       * When a dialog is close, we copy these values to editedItem */
      defaultItem: {
        userId: 0,
        userName: "",
        title: "",
        isFullAdmin: false,
        isDisabled: false,
        setNewPassword: false,
        localPassword: null,
        localLogin: null
      },

      /** A title of edit dialog.
       * We refresh it using refreshEditDialogTitle*/
      dialogEditTitle: "",

      loginRules: [
        v => !!v || this.$t('adminUsers.rules.LoginRequired'),
        v => (v && v.length >= 3) || this.$t('adminUsers.rules.LoginLength'),
      ],
      passwordRules: [
        v => !!v || this.$t('adminUsers.rules.PasswordRequired'),
        v => (v && v.length >= 6) || this.$t('adminUsers.rules.PasswordLength'),
        v => (v && /\d/.test(v) && (/[A-Z]/.test(v))) || this.$t('adminUsers.rules.PasswordRequirements'),
      ],
      userNameRules: [
        v => !!v || this.$t('adminUsers.rules.UserNameRequired'),
      ]
    }
  },

  watch: {
    dialogEdit (visible) {
      visible || this.closeEditDialog();
    },
    dialogInsertAd (visible) {
      visible || this.closeInsertDialog();
      if (visible) {
        //load list of AD-users from the server
        //to display in insert-dialog (== AD-selector)
        this.selectedAdUsers = [];
        apiFaUsersController.loadAdUsers(this
            , list => {
              this.listAdUsers = list;
            }
        );
      }
    },
    dialogInsertLocal (visible) {
      visible || this.closeInsertLocalDialog();
    },
    dialogDelete (visible) {
      visible || this.closeDeleteDialog();
    },
    editedIndex () {
      this.refreshEditDialogTitle();
    }
  },

  created() {
    apiFaUsersController.loadUsers(this
      , list => {
        this.listItems = list;
      }
  );
    this.refreshEditDialogTitle();
  },

  methods: {
    refreshEditDialogTitle() {
      this.dialogEditTitle = this.$t("adminUsers.editDialog.Title");
    },

    /** Extract list of user's roles as a string like follow one:
     *    Full admin
     *    Group admin: group 1
     *    Group admin: group 2
     *    ...
     * */
    getUserRolesAsString: function(item) {
      return apiDbUserEx.getUserRolesAsString(item, this);
    },

//region Insert AD user
    /** Close the dialog to edit selected item*/
    closeInsertDialog () {
      this.dialogInsertAd = false;
      this.$nextTick(() => {
        this.editedItem = deepClone(this.defaultItem);
        this.editedIndex = -1;
      })
    },

    /** Create new DB-users for the selected Ad-users */
    createNewUser () {
      if (this.selectedAdUsers.length > 0) {
        const dest = apiDataUsers.getArrayOfInputModelCreate(this.selectedAdUsers);
        apiFaUsersController.makeInsertAdUserRequest(this, this.listItems, dest);
      }

      this.closeInsertDialog()
    },
//endregion Insert AD user

//region Insert local user
    /** Close the dialog to create local user*/
    closeInsertLocalDialog () {
      this.dialogInsertLocal = false;
      this.$nextTick(() => {
        this.editedItem = deepClone(this.defaultItem);
        this.editedIndex = -1;
      })
    },

    /** Create new DB-users with local username/password (not AD) */
    createNewLocalUser () {
      apiFaUsersController.makeInsertLocalUserRequest(this, this.listItems, this.editedItem);
      this.closeInsertLocalDialog()
    },
//endregion Insert local user

//region Edit user
    /** Show dialog to edit selected item*/
    editItem (item) {
      this.editedIndex = this.listItems.indexOf(item);
      this.editedItem = deepClone(item);
      this.dialogEdit = true;
    },

    /** Close the dialog to edit selected item*/
    closeEditDialog () {
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = deepClone(this.defaultItem);
        this.editedIndex = -1;
      })
    },

    /**
     * Save changes after editing.
     */
    saveUpdates () {
      if (this.editedIndex > -1) {
        apiFaUsersController.makeEditRequest(this, this.listItems, this.editedItem, this.editedIndex); //the object was edited
      }
      this.closeEditDialog()
    },
//endregion Edit user

//region Delete item
    /** Show confirmation dialog to delete selected item*/
    deleteItem (item) {
      this.editedIndex = this.listItems.indexOf(item);
      this.editedItem = deepClone(item);
      this.dialogDelete = true;
    },

    /** Close the dialog to confirm a deletion of the selected item*/
    closeDeleteDialog () {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = deepClone(this.defaultItem);
        this.editedIndex = -1;
      })
    },

    /** Item was confirmed to delete. Make real deletion*/
    deleteItemConfirm () {
      apiFaUsersController.makeDeleteRequest(this, this.listItems, this.editedIndex); //new object was deleted
      this.closeDeleteDialog();
    },
//endregion Delete item
  }
}
</script>

<style scoped>

</style>