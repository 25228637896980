<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="listItems"
        _hide-default-footer
        item-key="messageId"
        class="elevation-1"
        :options.sync="paginationOptions"
        :server-items-length="totalCountItems"
        :loading="loading"
        @click:row="handleClick"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{$t("userMessages.FormTitle")}}</v-toolbar-title>

          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>

<!-- Filter by message types -->
          <v-select
              v-model="currentMctypeId"
              :items="mctypes"
              item-text="title"
              item-value="mctypeId"
              menu-props="auto"
              label="Message type"
              hide-details
              prepend-icon="mdi-map"
              single-line
          ></v-select>

          <v-spacer></v-spacer>

<!-- Switcher to view all/unread messages -->
          <v-switch
              v-model="showUnreadOnly"
              :label="$t('userMessages.UnreadOnly')"
              inset
              class="mx-4"
          ></v-switch>

        </v-toolbar>
      </template>

<!-- Show icon status: RED - unread, GREEN - read -->
      <template v-slot:item.status="{ item }">
        <div class="d-flex justify-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  :color="item.isDone ? 'green' : 'red'"
              >
                {{item.isDone ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle-outline" }}
              </v-icon>
            </template>
            <span>
              {{ item.isDone
                ? $t("userMessages.status.Read")
                : $t("userMessages.status.Unread")
              }}
            </span>
          </v-tooltip>
        </div>
      </template>

<!-- Highlight all columns by BOLD for all unread items -->
      <template v-slot:item.createdByUserName ="{ item }">
        <div class="d-flex justify-left"
             :class="{'font-weight-bold' : !item.isDone}"
        >
          {{item.createdByUserName}}
        </div>
      </template>

      <template v-slot:item.messageId ="{ item }">
        <div class="d-flex justify-left"
             :class="{'font-weight-bold' : !item.isDone}"
        >
          {{item.messageId}}
        </div>
      </template>

      <template v-slot:item.createdAt ="{ item }">
        <div class="d-flex justify-left"
             :class="{'font-weight-bold' : !item.isDone}"
        >
          {{item.createdAt ? dt2str(item.createdAt) : ""}}
        </div>
      </template>

<!-- Show title + icon of ATTACHED file exists -->
      <template v-slot:item.title ="{ item }">
        <div class="d-flex justify-left"
             :class="{'font-weight-bold' : !item.isDone}"
        >
          {{item.title}}
          <v-spacer></v-spacer>
          <v-icon v-if="item.countAttachedFiles">
            mdi-attachment
          </v-icon>
        </div>
      </template>

    </v-data-table>

  </div>
</template>

<script>
import {adminMctypesApi} from "@/api/admin.mctypes.api";
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {debounce} from "lodash";
import {userMessagesApi} from "@/api/user.messages.api";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";

export default {
  name: "adminMessagesPage",
  props: [
  ],

  data: function() {
    return {
      /** List of message mctypes, McType*/
      mctypes: [],

      /** Currently selected type of the messages*/
      currentMctypeId: 0,

      /** Main list of messages loaded from the server*/
      listItems: [],

      /** Columns of the list of messages */
      headers: [
        {
          text: this.$t("userMessages.header.CreatedAt"),
          value: 'createdAt',
          width: "150",
          align: 'center'
        },
        {
          text: this.$t("userMessages.header.Creator"),
          value: 'createdByUserName',
          width: 200
        },
        {
          text: this.$t("userMessages.header.Status"),
          value: 'status',
          width: 100,
          align: 'center'
        },
        {
          text: this.$t("userMessages.header.Subject"),
          value: 'title'
        },
      ],

      /**
       * Current pagination settings
       *
       * Server side pagination is used
       * see: https://vuetifyjs.com/en/api/v-data-footer/#props
       * */
      paginationOptions: {
        sortDesc: [true],
        sortBy: ["messageId"]
      },
      totalCountItems: 0,

      /** the data is loading from the server */
      loading: false,

      showUnreadOnly: false,
    }
  },

  watch: {
    /** When user select a message type, we need to reload list of the messages of the selected type*/
    currentMctypeId() {
      //message type was changed
      //we need to start from page 1, but we can keep currently selected ordering parameters
      this.debouncedLoadDataFromServer();
      this.$store.commit("storeMessages/setUserMctypeId", this.currentMctypeId)
    },

    showUnreadOnly() {
      //flag "show unread only" was changed
      //we need to start from page 1, but we can keep currently selected ordering parameters
      this.debouncedLoadDataFromServer();
      this.$store.commit("storeMessages/setUserUnreadOnly", this.showUnreadOnly)
    },

    paginationOptions: {
      handler(newValue, oldValue) {
        // console.log("options.new", JSON.stringify(newValue));
        // console.log("options.old", JSON.stringify(oldValue));

        if (newValue !== oldValue) {
          this.debouncedLoadDataFromServer();
        }
      },
      deep: true
    },

  },

  created() {
    // _.debounce is a function provided by lodash to limit how
    // often a particularly expensive operation can be run.
    // In this case, we want to limit how often we access
    // yesno.wtf/api, loading until the user has completely
    // finished typing before making the ajax request. To learn
    // more about the _.debounce function (and its cousin
    // _.throttle), visit: https://lodash.com/docs#debounce
    this.debouncedLoadDataFromServer = debounce(this.loadDataFromServer, 50)
    this.debouncedLoadDataFromServer();

    this.showUnreadOnly = this.$store.state.storeMessages.userUnreadOnly;

    //default mctype_id passed in url from dashboard
    const default_mctype_id = this.$route.params.mctype_id
        ? Number(this.$route.params.mctype_id)
        : this.$store.state.storeMessages.userMctypeId; //set mctype currently stored in the vuex

    // load list of available message-types
    adminMctypesApi.getListTypesOfMessages(this.$store.state, this.$store.dispatch).then(
        list => {
          this.mctypes = list;
          //select first available mctype by default
          if (list.length !== 0) {
            this.currentMctypeId = list[0].mctypeId;
            if (default_mctype_id) {
              if (list.filter(x => x.mctypeId === default_mctype_id).length > 0) {
                this.currentMctypeId = default_mctype_id;
              }
            }
          }
        }
    );
  },

  methods: {
    /** Load list of messages form server - one page only*/
    loadDataFromServer: function() {
      const pn = {
        page: this.paginationOptions.page,
        itemsPerPage: this.paginationOptions.itemsPerPage,
        sortBy: this.paginationOptions.sortBy,
        sortDesc: this.paginationOptions.sortDesc,
      };

      this.loading = true;
      userMessagesApi.getListMessages(this.$store.state
          , this.$store.dispatch
          , {
            mctypeId: this.currentMctypeId
            , unreadOnly: this.showUnreadOnly
          }
          , pn
      ).then(
          json => {
            this.listItems = json.items;
            this.paginationOptions.page = json.paginationOptions.page;
            //We need to use arrays: https://github.com/vuetifyjs/vuetify/issues/12929
            this.paginationOptions.sortBy = [json.paginationOptions.sortBy];
            this.paginationOptions.sortDesc = [json.paginationOptions.sortDesc];

            this.paginationOptions.itemsPerPage = json.paginationOptions.itemsPerPage;
            this.totalCountItems = json.totalCountItems;
            this.loading = false;
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });
    },

    /** User has clicked on the item of the table */
    handleClick: function(item) {
      this.$router.push({
        path: `/view/user/messages/${item.messageId}/view/` //normal user has logged in
      });
    },

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },

  }
}
</script>

<style scoped>

</style>