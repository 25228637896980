import {getAuthorizationHeader} from "@/helpers/auth-header";
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {adminFilesApi} from "@/api/admin.files.api";

export const fileUtils = {
    downloadFileUsingJWTToken,
    downloadFileByURL,
    downloadFileUsingFileToken
}

/**
 * Download file using JWT-token
 *
 * https://stackoverflow.com/questions/29452031/how-to-handle-file-downloads-with-jwt-based-authentication
 *
 * @param vue
 *      this
 * @param fileItem
 *      DataBasic.File
 */
function downloadFileUsingJWTToken(vue, fileItem) {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    let file_url = `${vue.$store.state.apiUrl}/api/user/files/download/${fileItem.fileId}`;

    let headers = getAuthorizationHeader();

    fetch(file_url, {headers})
        .then(response => {
            if (response.ok) {
                return response.blob()
            } else {
                if (response.status === 404) {
                    throw "Fie not found";
                } else {
                    throw response.statusText;
                }
            }
        })
        .then(blobby => {
            let objectUrl = window.URL.createObjectURL(blobby);

            anchor.href = objectUrl;
            anchor.download = fileItem.originalFileName;
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
        })
        .catch(error => showHttpErrorsInToasts(vue, error));
}

/**
 * Download file using special short-living file token.
 * @param vue
 *      this
 * @param fileId
 * @param dontOpen
 *      true - don't make attempt to open file in the browser, just download it
 */
function downloadFileUsingFileToken(vue, fileId, dontOpen) {
    adminFilesApi.generateFileTokenById(vue.$store.state, vue.$store.dispatch, fileId).then(
        tf => {
            //window.open(`${this.$store.state.apiUrl}/api/user/files/download/token/${token.token}`);
            const url = `${vue.$store.state.apiUrl}/api/user/files/download/token/${tf.originalFileNameWithoutExtension}?token=${tf.token.token}${dontOpen ? "&open=0" : ""}`;
            fileUtils.downloadFileByURL(url);
        }
    ).catch(error => {
        showHttpErrorsInToasts(vue, error);
    });
}

/**
 * https://stackoverflow.com/questions/1066452/easiest-way-to-open-a-download-window-without-navigating-away-from-the-page
 * open a file download pop-up
 *
 * @param filePath
 */
function downloadFileByURL(filePath) {
    window.open(filePath, "_blank");
    // var link = document.createElement('a');
    // link.href = filePath;
    // //link.download = filePath.substr(filePath.lastIndexOf('/') + 1);
    // link.click();
}