<!--
  The viewer of the document in Document Archive
  see #27
-->
<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-toolbar flat>
            <v-toolbar-title>
              {{
                $t("userDocumentPageViewer.FormTitle")
              }}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
                class="ma-2"
                @click="show_print_version()"
            >
              {{$t("userDocumentPageViewer.PrintVersion")}}
            </v-btn>

            <v-btn
                class="ma-2"
                @click="close()"
            >
              {{$t("button.Close")}}
            </v-btn>

          </v-toolbar>
        </v-col>
      </v-row>

      <!-- Document ID, creator-name -->
      <v-row>
        <v-col>
          <v-subheader class="pa-0">
            {{$t("label.CreatedBy")}}
          </v-subheader>
          {{documentData.createdByUserName}}
        </v-col>
        <v-col>
          <v-subheader class="pa-0">
            {{$t("label.CreatedAt")}}
          </v-subheader>
          {{
            documentData.createdAt
                ? dt2str(documentData.createdAt)
                : ""
          }}
        </v-col>
        <v-col>
          <v-subheader class="pa-0">
            {{$t("label.PublishedAt")}}
          </v-subheader>
          {{documentData.publishedAt ? dt2str(documentData.publishedAt) : ""}}
        </v-col>
      </v-row>

<!-- Title and comment  -->
      <v-row>
        <v-col>
          <v-text-field v-model="documentData.data.title"
                        :label='$t("adminDocumentsEditor.Title")'
                        readonly
          />
        </v-col>
        <v-col>
          <v-text-field v-model="documentData.data.remarks"
                        :label='$t("adminDocumentsEditor.Remarks")'
                        readonly
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <template>
            <div id="document_content">
              <v-subheader class="pa-0">{{$t("userDocumentPageViewer.DocumentContent")}}</v-subheader>
              <div v-html="documentData.htmlContent">
              </div>
            </div>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {documentsApi} from "@/api/documents.api";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";

export default {
  name: "userDocumentPageViewer",
  components: {},
  data: function() {
    return {
      components: {},
      /**
       * All data of the document that is being edited (DocumentEditEx)
       */
      documentData: {
        /** (Document) */
        data: {},
      },

      /** the folder to which the document (will) belongs */
      currentFolderId: undefined,
    }
  },

  created() {
    this.init(this.$route.params.documentId);
  },

  /**
   * When new page is created, the page is redirected to itself with different set of params
   * (to change isNewMode from true to false)
   *
   * So, "created" is not called second time if we move from one list to another.
   * We need to use beforeRouteEnter to workaround the problem.
   *
   * https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation
   * https://github.com/vuejs/vue-router/issues/1875
   **/
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.init(to.params.documentId);
    });
  },

  beforeRouteUpdate (to, from, next) {
    this.init(to.params.documentId);
    next();
  },

  methods: {
//region Init
    init: function(documentId) {
      //load document data
      documentsApi.getDocument(this.$store.state, this.$store.dispatch, documentId, false).then(
          document_ex => {
            this.documentData = document_ex;
            this.currentFolderId = document_ex.data.folderId;
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
    },

    close: function() {
      //in admin mode, we need to return to the parent folder in admin section
      //in not-admin mode - to the parent folder in user section
      if (this.$route.params.adminMode === "1") {
        this.$router.push({ path: `/view/admin/documents/openFolder/${this.currentFolderId}` });
      } else {
        this.$router.push({ path: `/view/user/documents/openFolder/${this.currentFolderId}` });
      }
    },

    /** show print version of the document in a separate tab */
    show_print_version: function() {
      documentsApi.getDocument(this.$store.state, this.$store.dispatch, this.$route.params.documentId, false, true).then(
          document_ex => {
            //new tab
            //!TODO: we cannot save content
            // see https://stackoverflow.com/questions/17048914/saving-a-document-when-opened-with-window-open-in-chrome
            // probably it worth to try to open URL...
            const tab = window.open('about:blank', '_blank');
            tab.document.write(document_ex.htmlContent);
            tab.document.close(); // to finish loading the page

            //new window
            // var wnd = window.open("about:blank", "", "_blank");
            // wnd.document.write(document_ex.htmlContent);
          }
      ).catch(error => showHttpErrorsInToasts(this, error));

    },
//endregion Init

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },
  }
}

</script>

<style scoped>
/**
DOM content created with v-html are not affected by scoped styles, but you can still style them using deep selectors.
https://vue-loader-v14.vuejs.org/en/features/scoped-css.html
*/

div >>> #document_content img
{
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}

/** Root container, it contains
    table of content
    and
    document content

    Table of content and document content are displayed in two columns: left and right.
    Left column is static, right column is limited by height and so has scrollbar, see #85

    The solution is based on follow code:
    https://stackoverflow.com/questions/21515042/scrolling-a-flexbox-with-overflowing-content
*/
div >>> #document_content .elearning_document_root
{
  display: flex;
  flex-direction: row-reverse;
}

/** Table of content */
div >>> #document_content .elearning_document_index
{
  /*flex-grow: 1;*/
  flex-grow: 1;
  max-width: 20vw;
  margin-right: 20px;
}

/** Document content - container 1
**/
div >>> #document_content .elearning_main {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  max-height: 80vh;
}

/** Document content - container 2*/
div >>> #document_content .elearning_content {
  flex: 1;
  display: flex;
  overflow: auto;
}

/** Document content - container 3*/
div >>> #document_content .elearning_box {
  min-height: -webkit-min-content;
  display: flex;
}

/** Document content */
div >>> #document_content .elearning_document_content
{
}

/** Classes to display various headers in the table of content */

div >>> #document_content .table_of_content_h1
{
  margin-left: 0px;
}
div >>> #document_content .table_of_content_h2
{
  margin-left: 10px;
}
div >>> #document_content .table_of_content_h3
{
  margin-left: 20px;
}
div >>> #document_content .table_of_content_h4
{
  margin-left: 30px;
}


</style>