<template>
  <v-data-table
      :headers="headers"
      :items="listItems"
      item-key="data.userGroupId"
      class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>
          {{$t("adminUserGroupsEditor.FormTitle")}}
        </v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>

        <v-spacer></v-spacer>

        <router-link to="/view/admin/user_groups/new"
                     tag="button">
          <v-btn
              color="primary"
              dark
              class="mr-4"
              v-if="!isCurrentUserGAandNotFA()"
          >
            {{$t("adminUserGroups.button.NewUserGroup")}}
          </v-btn>
        </router-link>

        <!-- a dialog to confirm deletion of the item -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">
              {{$t("adminUserGroups.tooltip.delete.Confirm")}}
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDeleteDialog">
                {{$t("button.Cancel")}}
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                {{$t("button.Ok")}}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-toolbar>
    </template>

<!-- actions column: Edit, delete, statistics - with tooltips -->
    <template v-slot:item.actions="{ item }">
      <div class="d-flex justify-center">
    <!-- Edit button -->
        <v-tooltip top v-if="isCurrentUserFA()">
          <template v-slot:activator="{ on, attrs }">
            <router-link :to="`/view/admin/user_groups/${item.data.userGroupId}/edit`"
                         tag="button">
              <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
              >
                mdi-pencil
              </v-icon>
            </router-link>
          </template>
          <span>
            {{$t("adminUserGroups.FormTitle")}}
          </span>
        </v-tooltip>

        <v-divider vertical class="mx-2" v-if="isCurrentUserFA()"/>

    <!-- Delete button -->
        <v-tooltip top v-if="isCurrentUserFA()">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
                small
                @click="deleteItem(item)"
                v-bind="attrs"
                v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>
            {{$t("adminUserGroups.tooltip.delete")}}
          </span>
        </v-tooltip>

        <v-divider vertical class="mx-2" v-if="isCurrentUserFA()"/>

    <!-- All messages assigned to the user group -->
        <router-link :to="'/view/admin/user_groups/' + item.data.userGroupId + '/stat/messages'"
                     tag="button">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
              >
                mdi-account-check
              </v-icon>
            </template>
            <span>
              {{$t("adminUserGroups.tooltip.StatByMessages")}}
            </span>
          </v-tooltip>
        </router-link>

        <v-divider vertical class="mx-2"/>

    <!-- All courses assigned to the user group -->
        <router-link :to="'/view/admin/user_groups/' + item.data.userGroupId + '/stat/courses'"
                     tag="button"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
              >
                mdi-account-alert
              </v-icon>
            </template>
            <span>
              {{$t("adminUserGroups.tooltip.StatByCourses")}}
            </span>
          </v-tooltip>
        </router-link>
      </div>
    </template>

  </v-data-table>
</template>

<script>
import {apiFaUserGroupsController} from "@/server/faUserGroupsController";
import {deepClone} from "@/helpers/gutils";

export default {
  name: "adminUserGroupsPage",
  props: [
  ],

  data: function() {
    return {
      /** Main list of user groups loaded from the server*/
      listItems: [],
      /** last error received from the server */
      lastError: null,
      /** Columns of the list of DB-users */
      headers: [
        {
          text: this.$t("adminUserGroups.headers.Id"),
          align: 'start',
          value: 'data.userGroupId',
          width: 100
        },
        {
          text: this.$t("adminUserGroups.headers.Title"),
          value: 'data.title',
          width: 200
        },
        {
          text: this.$t("adminUserGroups.headers.Description"),
          value: 'data.description',
        },
        {
          text: this.$t("adminUserGroups.headers.Admins"),
          value: 'countAdmins',
          align: 'center',
          width: "100",
        },
        {
          text: this.$t("adminUserGroups.headers.Members"),
          value: 'countMembers',
          align: 'center',
          width: "110",
        },
        {
          text: this.$t("adminUserGroups.headers.Courses"),
          value: 'countCourses',
          align: 'center',
          width: "100",
        },
        {
          text: this.$t("adminUserGroups.headers.Actions"),
          value: 'actions',
          align: 'center',
          sortable: false,
          width: "100"
        },
      ],

      /** show edit dialog to edit DB-user properties*/
      dialogEdit: false,
      /** show delete-confirmation dialog*/
      dialogDelete: false,

      /** The index of the currently edited item in listItems
       * -1 - there is no item that is being edited*/
      editedIndex: -1,
      /** A copy of the item that is being edited*/
      editedItem: {
        userGroupId: 0,
        title: "",
        description: "",
        flags: 0
      },
      /** Default values of the item.
       * When a dialog is close, we copy these values to editedItem */
      defaultItem: {
        userGroupId: 0,
        title: "",
        description: "",
        flags: 0
      },
    }
  },

  watch: {
    dialogDelete (visible) {
      visible || this.closeDeleteDialog();
    },
  },

  created() {
    apiFaUserGroupsController.loadUserGroupsViewEx(this
      , list => {
          this.listItems = list;
        }
    );
  },

  methods: {
//region Delete item
    /** Show confirmation dialog to delete selected item*/
    deleteItem (item) {
      this.editedIndex = this.listItems.indexOf(item);
      this.editedItem = deepClone(item);
      this.dialogDelete = true;
    },

    /** Close the dialog to confirm a deletion of the selected item*/
    closeDeleteDialog () {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = deepClone(this.defaultItem);
        this.editedIndex = -1;
      })
    },

    /** Item was confirmed to delete. Make real deletion*/
    deleteItemConfirm () {
      apiFaUserGroupsController.makeDeleteRequest(this
          , this.listItems
          , this.editedIndex
      ); //new object was deleted
      this.closeDeleteDialog();
    },
//endregion Delete item

//region User rights
    isCurrentUserFA: function() {
      let user = JSON.parse(localStorage.getItem('user'));
      return user.isFullAdmin;
    },

    isCurrentUserGAandNotFA: function() {
      let user = JSON.parse(localStorage.getItem('user'));
      return !user.isFullAdmin
          && user.gaUserGroupIds
          && user.gaUserGroupIds.length > 0;
    },
//endregion User rights
  }
}
</script>

<style scoped>

</style>