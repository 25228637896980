<template>
  <ckeditor
      id = "ck_editor"
      :editor="editor"
      v-model="content"
      :config="editorConfig"
      :disabled="isDisabled"
  >
  </ckeditor>
</template>

<script>
//region Import
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';

import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import Autoformat from '@ckeditor/ckeditor5-autoformat/src/autoformat.js';
import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import BlockQuote from '@ckeditor/ckeditor5-block-quote/src/blockquote.js';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold.js';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials.js';
import Heading from '@ckeditor/ckeditor5-heading/src/heading.js';
import Image from '@ckeditor/ckeditor5-image/src/image.js';
import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption.js';
import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle.js';
import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize.js';
import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar.js';
import ImageUpload from '@ckeditor/ckeditor5-image/src/imageupload.js';
import Indent from '@ckeditor/ckeditor5-indent/src/indent.js';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic.js';
import Link from '@ckeditor/ckeditor5-link/src/link.js';
import List from '@ckeditor/ckeditor5-list/src/list.js';
import MediaEmbed from '@ckeditor/ckeditor5-media-embed/src/mediaembed.js';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph.js';
import PasteFromOffice from '@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice';
import Table from '@ckeditor/ckeditor5-table/src/table.js';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties.js';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties.js';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar.js';
import TextTransformation from '@ckeditor/ckeditor5-typing/src/texttransformation.js';
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import {getAuthorizationHeader} from "@/helpers/auth-header";

//!TODO: https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/installing-plugins.html
//import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';

//endregion Import

export default {
  name: "ckeditorWrapper",
  model: {
    prop: 'text',
    event: 'update:text'
  },

  props: {
    text: String,
    readonly: Boolean,
    /** Optional editor config. If undefined, then default config is used*/
    customEditorConfig: Object,
  },

  data: function() {
    return {
      editor: ClassicEditor,

      /** Default editor config. It can be overwritten by props */
      editorConfig: {
        // The configuration of the ck-editor.
        plugins: [
          EssentialsPlugin,
          Alignment,
          Autoformat,
          SimpleUploadAdapter,
          BlockQuote,
          Bold,
          Essentials,
          Heading,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          ImageResize,
          Indent,
          Italic,
          Link,
          List,
          MediaEmbed,
          Paragraph,
          PasteFromOffice,
          Table,
          TableProperties,
          TableCellProperties,
          TableToolbar,
          TextTransformation,
        ],

        toolbar: {
          items: [
            'heading'
            //, '|', 'fontfamily', 'fontsize'
            , '|', 'alignment:left', 'alignment:right', 'alignment:center', 'alignment:justify'
            //, '|', 'fontColor', 'fontBackgroundColor'
            , '|', 'bold', 'italic'
            //, 'strikethrough', 'underline', 'subscript', 'superscript'
            , '|', 'link'
            , '|', 'outdent', 'indent'
            , '|', 'bulletedList', 'numberedList'
            //, '|', 'code', 'codeBlock'
            , '|', 'insertTable'
            , '|', 'uploadImage', 'blockQuote'
            , '|', 'undo', 'redo'
          ]
        },

        table: { //https://ckeditor.com/docs/ckeditor5/latest/features/table.html
          contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties' ]
        },

        placeholder: this.$t("adminMessagesEditor.MessageBody.Placeholder"),

        // The configuration of SimpleUploadAdapter
        // see https://ckeditor.com/docs/ckeditor5/latest/features/image-upload/simple-upload-adapter.html
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: `${this.$store.state.apiUrl}/api/admin/files/images`,

          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: false,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: getAuthorizationHeader()
        }
      },

      /** Editable content */
      content: this.text,

      /** the editor is read-only */
      isDisabled: this.readonly
    }
  },

  watch: {
    "content": function(newText) {
      this.$emit('update:text', newText);
    }
  },

}
</script>

<style scoped>

</style>