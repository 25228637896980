import axios from "axios";
import {getAuthorizationHeader} from "@/helpers/auth-header";
import {handleResponse_401_Unauthorized} from "@/helpers/handle401";

export const adminFilesApi = {
    uploadFiles,
    generateFileTokenById
}

/**
 * Upload files to server
 * Returns a promise, that return list of registered file_id
 * @param filesToUpload
 * @return {Promise<AxiosResponse<Int64>>}
 */
function uploadFiles(rootStoreState, filesToUpload) {
    if (filesToUpload.length > 0) {
        let formData = new FormData();

        // files
        for (let file of filesToUpload) {
            formData.append("files", file, file.name);
        }

        const url = `${rootStoreState.apiUrl}/api/admin/files/hugefilepost`;

        // additional data
        //formData.append("test", "foo bar");
        return axios.post(
            url
            , formData
            , {
                headers: getAuthorizationHeader()
            }
        );
    }
}

/***
 * Generate short-lived file-token
 * to download specified file anonymously
 * @param rootStoreState
 * @param storeDispatch
 * @param fileId
 * @return {Promise<*>}
 */
function generateFileTokenById(rootStoreState, storeDispatch, fileId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/user/files/create/filetoken/${fileId}`;

    //console.log("url", url);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}