<!--
GA: assign / un-assign users / user-groups to/from the course
-->
<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
<!-- Title of the window -->
          <v-toolbar flat>
            <v-toolbar-title>{{$t("adminCoursesGaMembers.FormTitle")}}</v-toolbar-title>
          </v-toolbar>

<!-- Title of the course -->
          <v-toolbar flat>
            <v-toolbar-title>
              {{
                this.$t("adminCoursesGaMembers.CourseTitle"
                    , {
                      title: this.courseData.data.title
                    }
                )
              }}
              <v-subheader class="ma-0 pa-0">id={{courseData.data.courseId}}</v-subheader>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                class="ma-2"
                @click="save()"
            >
              {{$t("adminCoursesGaMembers.button.Save")}}
            </v-btn>

            <router-link to="/view/admin/courses"
                         tag="button"
            >
              <v-btn
                  class="ma-2"
              >
                {{$t("adminCoursesGaMembers.button.Close")}}
              </v-btn>
            </router-link>
          </v-toolbar>
        </v-col>
      </v-row>

<!-- Attached use groups -->
      <v-row>
        <v-col>
          <v-select
              v-model="selectedUserGroupIds"
              :items="listUserGroups"
              :item-text="x => x.title"
              :item-value="x => x.userGroupId"
              :label="$t('adminCoursesGaMembers.AttachToUserGroups')"
              chips
              deletable-chips
              multiple
              clearable
          ></v-select>
        </v-col>
      </v-row>

<!-- Directly attached users -->
      <v-row>
        <v-col>
          <v-select
              v-model="selectedUserIds"
              :items="listUsers"
              :item-text="x => x.title"
              :item-value="x => x.userId"
              :label="$t('adminCoursesGaMembers.AttachToUsers')"
              chips
              deletable-chips
              multiple
              clearable
          ></v-select>
        </v-col>
      </v-row>

    </v-container>

  </div>
</template>

<script>

import {adminCoursesApi} from "@/api/admin.courses.api";
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {adminUserGroupsApi} from "@/api/admin.usergroups.api";
import {adminUsersApi} from "@/api/admin.users.api";

export default {
  name: "adminCoursesPageGaMembers",
  props: [],

  data: function() {
    return {
      /** CourseEditEx */
      courseData: {
        data: {},
        /** For GA we have here only users available for the GA*/
        attachedUsers: [],
        /** For GA we have here only user groups available for the GA*/
        attachedUserGroups: [],
      },

      /** Users selected by GA (from the list of users available for the GA)*/
      selectedUserIds: [],
      /** User groups selected by GA (from the list of user groups available for the GA)*/
      selectedUserGroupIds: [],

      /** Full list of dbUsers available for the GA: DbUserEx */
      listUsers: [],
      /** Full list of user groups available for the GA: UserGroup */
      listUserGroups: [],
    }
  },

  created() {
    //load list of available users
    adminUserGroupsApi.getListUserGroups(this.$store.state, this.$store.dispatch).then(
        user_groups => {
          this.listUserGroups = user_groups
        }
        , errors => showHttpErrorsInToasts(this, errors)
    );

    //load list of available user groups
    adminUsersApi.getListDbUsers(this.$store.state, this.$store.dispatch).then(
        users => {
          this.listUsers = users;
        }
        , errors => showHttpErrorsInToasts(this, errors)
    );

    // load course data
    adminCoursesApi.getEditCourseEx(this.$store.state, this.$store.dispatch, this.$route.params.id).then(
        c => {
          this.courseData = c;
          this.selectedUserIds = this.courseData.attachedUsers.map(x => x.userId);
          this.selectedUserGroupIds = this.courseData.attachedUserGroups.map(x => x.userGroupId);
        },
        error => showHttpErrorsInToasts(this, error)
    );
  },

  methods: {
    save: function() {
      adminCoursesApi.updateGaCourseMembers(this.$store.state, this.$store.dispatch, {
        courseId: this.courseData.data.courseId,
        attachedUserIds: this.selectedUserIds,
        attachedUserGroupIds: this.selectedUserGroupIds,
      }).then(
          () => {
            this.$toast.open({
              message: this.$t("adminCoursesGaMembers.message.Updated", {
                course: this.courseData.data.courseId
              }),
              type: 'success',
              position: 'top-right'
            });
            this.$router.push({ path: `/view/admin/courses` })

            //update statistics on left side-bar (see Normal view)
            this.$bus.$emit("refreshCountUnprocessedItems", {courses: true});
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
    },
  }
}
</script>

<style scoped>

</style>