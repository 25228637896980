var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar-title',[_c('router-link',{attrs:{"to":("/view/admin/courses/" + (this.$route.params.id) + "/edit"),"tag":"button"}},[_vm._v(" "+_vm._s(_vm.$t("adminUserStatByCourses.UserTitle" , { "title": this.userData ? this.userData.title : "" , "id": this.$route.params.id } ))+" ")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listItems,"item-key":"cutaskId","options":_vm.paginationOptions,"server-items-length":_vm.totalCountItems,"loading":_vm.loading},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("adminUserStatByCourses.FormTitle"))+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"small":"","color":item.isDone
                ? 'green'
                : item.isStarted
                  ? 'yellow'
                  : 'red'}},[_vm._v(" "+_vm._s(item.isDone ? "mdi-checkbox-marked-circle" : item.isStarted ? "mdi-checkbox-blank-circle" : "mdi-checkbox-blank-circle-outline")+" ")]),_c('div',{staticClass:"mx-4"},[_vm._v(" "+_vm._s(item.countCompletedParts)+"/"+_vm._s(item.totalCountParts)+" ")])],1)]}},{key:"item.doneAt",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.doneAt ? _vm.dt2str(item.doneAt) : "")+" ")])]}},{key:"item.activeTestfails",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.activeTestfails && item.activeTestfails.length > 0 ? item.activeTestfails.length : "")+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }