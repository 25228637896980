<!--
Admin part.
Statistic by courses for the user
(with server side pagination)
-->
<template>
  <div>
    <v-toolbar-title>
      <router-link :to="`/view/admin/courses/${this.$route.params.id}/edit`"
                   tag="button">
        {{$t("adminUserStatByCourses.UserTitle"
          , {
            "title": this.userData ? this.userData.title : ""
            , "id": this.$route.params.id
          }
      )}}
      </router-link>

    </v-toolbar-title>

    <v-data-table
        :headers="headers"
        :items="listItems"
        item-key="cutaskId"
        class="elevation-1"
        :options.sync="paginationOptions"
        :server-items-length="totalCountItems"
        :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            {{$t("adminUserStatByCourses.FormTitle")}}
          </v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
        </v-toolbar>
      </template>

<!-- Status -->
      <template v-slot:item.status="{ item }">
        <div class="d-flex justify-center">
          <v-icon
              small
              :color="item.isDone
                ? 'green'
                : item.isStarted
                  ? 'yellow'
                  : 'red'"
          >
            {{item.isDone
              ? "mdi-checkbox-marked-circle"
              : item.isStarted
                  ? "mdi-checkbox-blank-circle"
                  : "mdi-checkbox-blank-circle-outline"
            }}
          </v-icon>
          <div class="mx-4">
            {{item.countCompletedParts}}/{{item.totalCountParts}}
          </div>
        </div>
      </template>

<!-- Done at -->
      <template v-slot:item.doneAt="{ item }">
        <div class="d-flex justify-center">
          {{ item.doneAt ? dt2str(item.doneAt) : ""}}
        </div>
      </template>

      <!-- Testfailes (if any) -->
      <template v-slot:item.activeTestfails="{ item }">
        <div class="d-flex justify-center">
          {{item.activeTestfails && item.activeTestfails.length > 0 ? item.activeTestfails.length : ""}}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";
import {adminUsersApi} from "@/api/admin.users.api";
import {debounce} from "lodash";

export default {
  name: "adminСoursesPageStatByUsers",

  data: function() {
    return {
      /** ID of the course provided through URL*/
      userId: this.$route.params.id,

      /** Сourse-struct loaded from server by courseId */
      userData: {
      },

      /** List of UserStatusByСourse */
      listItems: [],

      /** Columns of the list of DB-users
       * Sorting is disabled because of implementation of move up/down
       * */
      headers: [
        {
          text: this.$t("adminUserStatByCourses.headers.TaskId"),
          align: 'start',
          value: 'cutaskId',
          sortable: true,
          width: "100"
        },
        {
          text: this.$t("adminUserStatByCourses.headers.CourseId"),
          align: 'start',
          value: 'courseData.courseId',
          sortable: true,
          width: 120
        },
        {
          text: this.$t("adminUserStatByCourses.headers.UserName"),
          value: 'createdByUserName',
          sortable: true,
          align: 'left',
          width: 200
        },
        {
          text: this.$t("adminUserStatByCourses.headers.Type"),
          value: 'courseTypeTitle',
          sortable: true,
          align: 'center'
        },
        {
          text: this.$t("adminUserStatByCourses.headers.Course"),
          value: 'courseData.title',
          sortable: true,
          align: 'left',
          width: 160
        },
        {
          text: this.$t("adminUserStatByCourses.headers.Status"),
          value: 'status',
          sortable: true,
          width: "100",
          align: 'center'
        },
        {
          text: this.$t("adminUserStatByCourses.headers.DoneAt"),
          value: 'doneAt',
          sortable: true,
          width: "160",
          align: 'center'
        },
        {
          text: this.$t("adminUserStatByCourses.headers.Fails"),
          value: 'activeTestfails',
          sortable: false,
          width: "100",
          align: 'center',
        },
      ],

      /**
       * Current pagination settings
       *
       * Server side pagination is used
       * see: https://vuetifyjs.com/en/api/v-data-footer/#props
       * */
      paginationOptions: {
        sortDesc: [true],
        sortBy: ["cutaskId"]
      },
      totalCountItems: 0,

      /** the data is loading from the server */
      loading: false
    }
  },

  watch: {
    /** When user select a course type, we need to reload list of the courses of the selected type*/
    currentMctypeId() {
      //course type was changed
      //we need to start from page 1, but we can keep currently selected ordering parameters
      this.debouncedLoadDataFromServer();
    },

    paginationOptions: {
      handler(newValue, oldValue) {
        // console.log("options.new", JSON.stringify(newValue));
        // console.log("options.old", JSON.stringify(oldValue));

        if (newValue !== oldValue) {
          this.debouncedLoadDataFromServer();
        }
      },
      deep: true
    },

  },

  created() {
    // _.debounce is a function provided by lodash to limit how
    // often a particularly expensive operation can be run.
    // In this case, we want to limit how often we access
    // yesno.wtf/api, loading until the user has completely
    // finished typing before making the ajax request. To learn
    // more about the _.debounce function (and its cousin
    // _.throttle), visit: https://lodash.com/docs#debounce
    this.debouncedLoadDataFromServer = debounce(this.loadDataFromServer, 50)
    this.debouncedLoadDataFromServer();

    //load info about the course
    adminUsersApi.getUserById(this.$store.state, this.$store.dispatch
        , this.userId
    ).then(
        c => {
          this.userData = c;
        }
    ).catch(error => {
      showHttpErrorsInToasts(this, error);
    });
  },

  methods: {
    /** Load list of courses form server - one page only*/
    loadDataFromServer: function() {
      const pn = {
        page: this.paginationOptions.page,
        itemsPerPage: this.paginationOptions.itemsPerPage,
        sortBy: this.paginationOptions.sortBy,
        sortDesc: this.paginationOptions.sortDesc,
      };

      this.loading = true;
      adminUsersApi.getStatByCourses(this.$store.state, this.$store.dispatch, this.userId, pn).then(
          json => {
            this.listItems = json.items;
            this.paginationOptions.page = json.paginationOptions.page;
            //We need to use arrays: https://github.com/vuetifyjs/vuetify/issues/12929
            this.paginationOptions.sortBy = [json.paginationOptions.sortBy];
            this.paginationOptions.sortDesc = [json.paginationOptions.sortDesc];

            this.paginationOptions.itemsPerPage = json.paginationOptions.itemsPerPage;
            this.totalCountItems = json.totalCountItems;
            this.loading = false;
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });
    },

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },

  }
}
</script>

<style scoped>

</style>