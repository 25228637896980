<template>
<div>
  <v-container>
    <v-row>
      <v-col>
        <v-toolbar flat>
          <v-toolbar-title>
            {{$t("adminTestfailResults.CourseTitle")}} {{testfail.data.courseTitle}}
          </v-toolbar-title>
        </v-toolbar>
        <v-toolbar flat>
          <v-toolbar-title>
            {{$t("adminTestfailResults.TestTitle")}} {{testfail.data.testTitle}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-chip class="mx-4"
                  v-if="testfail.data.testfail.isAutoReopened"
          >
            Auto-reopened
          </v-chip>
          <v-btn
              color="primary"
              class="ma-2"
              @click="reopenItem()"
              v-if="!testfail.permittedBy"
          >
            {{$t("adminTestfails.tooltip.Reopen")}}
          </v-btn>
          <v-chip color="success"
                  v-else
          >
            {{$t("adminTestfailResults.PermittedBy"
              , {
                user: testfail.permittedBy.title,
                date: testfail.data.testfail.permittedAt
                    ? dt2str(testfail.data.testfail.permittedAt)
                    : ""
              }
            )}}
          </v-chip>
          <router-link to="/view/admin/testfails"
                       tag="button"
          >
            <v-btn
                color="info"
                class="ma-2"
            >
              {{$t("adminCoursesEditor.button.Close")}}
            </v-btn>
          </router-link>
        </v-toolbar>
        <v-toolbar flat>
          <v-toolbar-title>
            {{$t("adminTestfailResults.PassedBy"
              , {
                "user": testfail.user.title
                , "date": dt2str(testfail.data.testfail.createdAt)
              }
            )}}
          </v-toolbar-title>
        </v-toolbar>
        <v-toolbar flat>
          <v-toolbar-title>
            {{$t("adminTestfailResults.TestResults")}}
          </v-toolbar-title>
        </v-toolbar>
      </v-col>
    </v-row>
    <v-row v-for="(q, index) in testfail.results.questions" :key="index">
      <v-col>
        <v-card>
          <v-card-title>
            {{index + 1}}. {{q.question}}
          </v-card-title>
          <v-card-text>
            {{q.userAnswer}}
            <v-chip class="success mx-4" v-if="!q.isAnswerIncorrect">Correct</v-chip>
            <v-chip class="error mx-4" v-if="q.isAnswerIncorrect">Error</v-chip>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- a dialog to confirm reopening of the test -->
  <v-dialog v-model="dialogReopen"
            max-width="520px"
  >
    <v-card>
      <v-card-title class="headline">
        {{$t("adminTestfails.dialog.Confirm")}}
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeReopenDialog">
          {{$t("button.Cancel")}}
        </v-btn>
        <v-btn color="blue darken-1" text @click="reopenItemConfirm">
          {{$t("button.Ok")}}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
import {adminTestfailsApi} from "@/api/admin.testfails.api";
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";

export default {
  name: "adminTestfailsPageViewResults",
  data: function() {
    return {
      /** TestFailViewWithResults */
      testfail: {
        data: {
          testfail: {}
        },
        user: {},
        results: {},
      },

      /** show test-reopen-confirmation dialog*/
      dialogReopen: false,
    }
  },
  created() {
    adminTestfailsApi.getTestfail(this.$store.state
        , this.$store.dispatch
        , this.$route.params.testfailId
    ).then(
        testfail_with_results => {
          this.testfail = testfail_with_results;
        }
    ).catch(error => {
      this.loading = false;
      showHttpErrorsInToasts(this, error);
    });
  },

  watch: {
    "dialogReopen": function(visible) {
      visible || this.closeReopenDialog();
    },
  },

  methods: {
    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },

//region Reopen item
    /** Show confirmation dialog to reopen the test*/
    reopenItem () {
      this.dialogReopen = true;
    },

    /** Close the dialog to confirm a reopening of the selected test*/
    closeReopenDialog () {
      this.dialogReopen = false;
    },

    /** Item was confirmed to delete. Make real deletion*/
    reopenItemConfirm () {
      this.reopen_testfail(this.testfail);
      this.closeReopenDialog();
    },

    /** Item was confirmed to reopen. Make real reopening*/
    reopen_testfail: function(testfailView) {
      adminTestfailsApi.updateTestfail(this.$store.state, this.$store.dispatch
          , testfailView.data.testfail.testfailId
      ).then(
          () => {
            this.$toast.open({
              message: `The test ${testfailView.data.testId} was reopend`,
              type: 'success',
              position: 'top-right'
            });
            this.$router.push({ path: `/view/admin/testfails` })
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
      this.closeReopenDialog();
    },
//endregion Reopen item
  }
}
</script>

<style scoped>

</style>