var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("dashboard.Dashboard")))]),_c('v-spacer')],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dashboardData.messageMctypes,"item-key":"mctypeId"},on:{"click:row":_vm.handleMessageClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("dashboard.Messages")))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.mctypeTitle",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left",class:{'font-weight-bold' : item.countUndoneItems > 0}},[_vm._v(" "+_vm._s(item.mctypeTitle)+" ")])]}},{key:"item.totalCountItems",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left",class:{'font-weight-bold' : item.countUndoneItems > 0}},[_vm._v(" "+_vm._s(item.totalCountItems)+" ")])]}},{key:"item.countUndoneItems",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left",class:{'font-weight-bold' : item.countUndoneItems > 0}},[_vm._v(" "+_vm._s(item.countUndoneItems)+" ")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left",class:{'font-weight-bold' : item.countUndoneItems > 0}},[_vm._v(" "+_vm._s(item.title)+" ")])]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dashboardData.courseMctypes,"item-key":"mctypeId"},on:{"click:row":_vm.handleCourseClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("dashboard.Courses")))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.mctypeTitle",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left",class:{'font-weight-bold' : item.countUndoneItems > 0}},[_vm._v(" "+_vm._s(item.mctypeTitle)+" ")])]}},{key:"item.totalCountItems",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left",class:{'font-weight-bold' : item.countUndoneItems > 0}},[_vm._v(" "+_vm._s(item.totalCountItems)+" ")])]}},{key:"item.countUndoneItems",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left",class:{'font-weight-bold' : item.countUndoneItems > 0}},[_vm._v(" "+_vm._s(item.countUndoneItems)+" ")])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left",class:{'font-weight-bold' : item.countUndoneItems > 0}},[_vm._v(" "+_vm._s(item.title)+" ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }