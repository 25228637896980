/** Keep user-settings for course-related pages
 * - list of courses - admin part: mctypeId
 * - list of courses - user part: mctypeId, Undone only
 *
 * */
export const storeCourses = {
    /** All of its getters, actions and mutations (but not state properties) are automatically namespaced based on the path the module is registered at
     *  https://vuex.vuejs.org/guide/modules.html#module-local-state */
    namespaced: true,
    state: {
        /** Currently selected mctype_id with kind "courses" in admin part */
        adminMctypeId: null,
        /** Currently selected mctype_id with kind "courses" in user part */
        userMctypeId: null,
        /** Show Undone courses only - list of courses, user part*/
        userUndoneOnly: true,
    },

    mutations: {
        /** Set currently selected mctype_id for the list of courses in admin part */
        setAdminMctypeId(state, mctypeId) {
            state.adminMctypeId = mctypeId;
        },

        /** Set currently selected mctype_id for the list of courses in user part */
        setUserMctypeId(state, mctypeId) {
            state.userMctypeId = mctypeId;
        },

        /** Set value of flag "Undone only" for the list of courses, user part */
        setUserUndoneOnly(state, undoneOnly) {
            state.userUndoneOnly = undoneOnly;
        },
    }
}
