<!--
  Get search params in query parameters: languages, targets, text
      i.e. http://localhost:8081/#/view/user/search?languages=dan,eng&targets=Messages,Courses,Documents,Files&text=123
  Send request to the server.
  Show progress bar until the results are not received ( this.loading === true)
  Display search results with pagination.
-->
<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="searchResults.searchItems"
        hide-default-footer
        item-key="contentUid"
        class="elevation-1"

        show-expand
        :expanded.sync="expanded"

        :loading="loading"
        @click:row="handleClick"
    >
      <template v-slot:top>
  <!-- toolbar -->
        <v-toolbar flat>
          <v-toolbar-title>
            {{
              loading
                  ? $t("searchPage.Searching")
                  : `${$t("searchPage.SearchResults")} ~${searchResults.totalCount}`
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

    <!-- Prev page button -->
          <v-btn class="ma-2"
                 :disabled="loading || currentPageIndex <= 1"
                 @click="changePage(false)"
          >
            {{$t("searchPage.PrevPage")}}
          </v-btn>

          {{currentPageIndex}}

    <!-- Next page button -->
          <v-btn class="ma-2"
                :disabled="loading || searchResults.noMoreResults"
                 @click="changePage(true)"
          >
            {{$t("searchPage.NextPage")}}
          </v-btn>
        </v-toolbar>
      </template>

  <!-- Date creation template - reformat created-at date -->
      <template v-slot:item.dateCreation ="{ item }">
        <div class="d-flex justify-left"
             :class="{'font-weight-bold' : !item.isDone}"
        >
          {{item.dateCreation ? dt2str(item.dateCreation) : ""}}
        </div>
      </template>

  <!-- Item kind template: 0x1 - Messages, 0x2 - Courses and so on  -->
      <template v-slot:item.itemKind ="{ item }">
        <v-chip class="ma-0" color="orange" v-if="item.itemKind === 0x1">{{$t("searchPage.Message")}}</v-chip>
        <v-chip class="ma-0" color="blue" v-else-if="item.itemKind === 0x2">{{$t("searchPage.Course")}}</v-chip>
        <v-chip class="ma-0" color="green" v-else-if="item.itemKind === 0x4">{{$t("searchPage.Document")}}</v-chip>
        <v-chip class="ma-0" color="gray" v-else-if="item.itemKind === 0x8">{{$t("searchPage.File")}}</v-chip>
      </template>

  <!-- Row details - html with highlighted entries of the target keywords -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div class="highlight_details" v-html="get_html(item.highlightedContent)">

          </div>
        </td>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {searchApi} from "@/api/search.api";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";
import {fileUtils} from "@/helpers/fileUtils";

export default {
  name: "SearchPage",
  data: function () {
    return {
      /** The data is loading from the server */
      loading: true,

      /** current page index: 0, 1, 2..*/
      currentPageIndex: 0,

      /** How much results should be displayed on single page */
      countSearchResultsPerPage: 10,

      /** Current query */
      queryParams: {
        /** comma-separated list of languages */
        languages: "",
        /** comma-separated list of targets */
        targets: "",
        text: ""
      },

      /**
       * startIndex0 values for previous pages.
       * Each search request returns searchResults with info about new page.
       * So, we have
       *    searchResults-1
       *    searchResults-2
       *    searchResults-3
       *    ...
       * Each searchResults has nextStartIndex - start position for next search.
       * So we have
       *    0 => searchResults-1 (nextStartIndex = N1)
       *    N1 => searchResults-2 (nextStartIndex = N2)
       *    N2 => searchResults-3 (nextStartIndex = N3)
       *    ...
       * Here we save start positions for previous pages
       *  [0, N1, N2, .. ]
       * to be able to return to previous search page.
       *
       * Last item points on start-index of the current page.
       */
      historyPositions: [],

      /** Last search results received from the server, see E21Model.UserData.SearchPage */
      searchResults: {
        /** Approx total count of found items
         * Actual count can be less then expected,
         * because this is a total count of the found items for any users,
         * current user may not have access to  some of them
         * */
        totalCount: 0,
        /** There are no more results, it was the last page */
        noMoreResults: 0,
        /** Index from which next search should be started */
        nextStartIndex: 0,
        /** The items of the current search page */
        searchItems: []
      },

      /** List of expanded items from searchResults.searchItems*/
      expanded: [],

      headers: [
        {
          text: this.$t("searchPage.CreatedAt"),
          value: 'dateCreation',
          width: 150,
          sortable: false,
        },
        {
          text: this.$t("searchPage.Kind"),
          value: 'itemKind',
          width: 100,
          sortable: false,
        },
        {
          text: this.$t("searchPage.Title"),
          value: 'displayTitle',
          sortable: false,
        },
      ],

      /**
       * Current pagination settings
       *
       * Server side pagination is used
       * see: https://vuetifyjs.com/en/api/v-data-footer/#props
       * */
      paginationOptions: {
      },
    };
  },

  mounted() {
    //subscribe on searchPageShouldMakeNewSearch
    //if user presses Search button when searchPage is active,
    //we don't need to make route.push. Instead of it, it's
    //just enough to resend search query right on the current page
    this.$bus.$on("searchPageShouldMakeNewSearch", this.makeSearch);
    this.makeSearch(this.$route.query);
  },

  beforeDestroy() {
    //unsubscribe from searchPageShouldMakeNewSearch (see mounted() for subscription code)
    this.makeSearch && this.$bus.$off("searchPageShouldMakeNewSearch", this.makeSearch);
  },

  created() {
  },

  methods: {
    /**
     * Send async search request to the server.
     * Set "loading" to true to display progress bar
     * and hide results of the previous search if any
     *
     * @param queryParams
     *    json with search parameters
     *    {
     *      languages: "dan,eng",
     *      targets: "Messages, Courses",
     *      text: "Text to search"
     *    }
     *
     * */
    makeSearch(queryParams) {
      //make new search - clear up.
      this.historyPositions = [];
      this.searchResults = {};
      this.queryParams = queryParams;
      this.currentPageIndex = 0;

      this.loadDataFromServer(0);
    },

    /** Move to next or prev search page */
    changePage(next) {
      if (next) {
        if (this.searchResults.nextStartIndex) {
          const next_start_index_0 = this.searchResults.nextStartIndex;
          this.loadDataFromServer(next_start_index_0);
        }
      } else {
        if (this.historyPositions.length > 1) {
          const prev_start_index_0 = this.historyPositions[this.historyPositions.length - 2];
          this.loadDataFromServer(prev_start_index_0);
        }
      }
    },

    /** Load one page of search results form the server*/
    loadDataFromServer: function(startIndex0) {
      this.loading = true;

      let input_model = {
        query: this.queryParams.text,
        languages: this.queryParams.languages,
        targets: this.queryParams.targets.split(","),
      };

      //startIndex0 and count should be add only if they are not undefined
      input_model = {
        ... (startIndex0) && {startIndex0: startIndex0},
        ... {count: this.countSearchResultsPerPage}, //by default, get only 10 search results
        ... input_model
      };

      searchApi.makeSearch(this.$store.state, this.$store.dispatch, input_model).then(
          results => {
            this.searchResults = results;
            this.loading = false;
            //by default all items are expanded
            this.expanded = this.searchResults.searchItems;

            if (this.historyPositions.length === 0) {
              //we have moved to next page; keep startIndex for the new page in the history
              this.historyPositions.push(startIndex0);
              this.currentPageIndex++;
            } else {
              if (startIndex0 === this.historyPositions[this.historyPositions.length - 1]) {
                //we have just reopened current page, no changes
              } else if (startIndex0 > this.historyPositions[this.historyPositions.length - 1]) {
                //we have moved to next page; keep startIndex for the new page in the history
                this.historyPositions.push(startIndex0);
                this.currentPageIndex++;
              } else {
                //we have returned back to prev page; remove startIndex of the previous current page from the history
                this.historyPositions.pop();
                this.currentPageIndex--;
              }
            }
          },
          error => {
            this.loading = false;
            showHttpErrorsInToasts(this, error);
          }
      );
    },

    handleClick: function(item) {
      switch (item.itemKind) {
        case 0x1: //message
          this.$router.push({path: `/view/user/messages/${item.dbUid}/view`});
          break;
        case 0x2: //course
          this.$router.push({path: `/view/user/courses/${item.dbUid}/view`});
          break;
        case 0x4: //document
          this.$router.push({path: `/view/user/documents/document/view/${item.dbUid}`});
          break;
        case 0x8: //file
          this.download_file(item);
          break;
      }
    },

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },

    /**
     * Wrap v-html string, generated by ES, by div with predefined class.
     * We need this trick to apply scoped styles to v-html, see
     * https://medium.com/@brockreece/scoped-styles-with-v-html-c0f6d2dc5d8e
     * @param itemHtml
     * @return {string}
     */
    get_html(itemHtml) {
      return `<div class="highlight_internal">${itemHtml}</div>`;
    },

    download_file(item) {
      return fileUtils.downloadFileUsingFileToken(this, item.dbUid);
    },
  }
}
</script>

<style scoped>
/*
  v-html string is generated by ES.
  The parts that should be highlighted are marked by <em>

  There is a tricky way to apply scoped styles to v-html
  https://medium.com/@brockreece/scoped-styles-with-v-html-c0f6d2dc5d8e
*/
.highlight_details >>> .highlight_internal {
  /*background-color: lightgray;*/
}
.highlight_details >>> .highlight_internal em {
  background-color: yellow;
  font-weight: bold;
}
</style>