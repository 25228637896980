<!--
  The dialog to show file URL
  User is able to copy URL by pressing Ctrl+C and then close the dialog by ESC
-->

<template>
  <v-dialog v-model="theDialog"
            max-width="720px"
  >
    <v-card class="mx-auto my-12">
      <v-card-title class="headline">
        {{this.dialogTitle}}
      </v-card-title>
      <v-card-text>
        <div class="mb-2">
          <v-text-field
              v-model="textToShow"
              ref="input"
              :name="`username_${Math.random()}`"
              @focus="$event.target.select()"
              autofocus
          ></v-text-field>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">{{$t("button.Cancel")}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialogShowUrl",

  props: [
      "text"
  ],

  data: function() {
    return {
      /** show delete-confirmation dialog*/
      theDialog: false,

      /** Confirmation message. It's assigned when the dialog is opened, see showDialog */
      dialogTitle: "",

      textToShow: this.text,
    }
  },

  watch: {
    theDialog(visible) {
      visible || this.closeDialog();
    },
  },

  methods: {
    /** Display deletion-confirmation dialog */
    showDialog(title, newText) {
      this.theDialog = true;
      this.dialogTitle = title;
      this.textToShow = newText;
    },

    /** Hide deletion-confirmation dialog */
    closeDialog() {
      this.theDialog = false;
    },
  }
}
</script>

<style scoped>

</style>