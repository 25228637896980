/** Keep user-settings for testfail-related pages
 * - list of testfails - admin part: "Show reopened tests"
 *
 * */
export const storeTestfails = {
    /** All of its getters, actions and mutations (but not state properties) are automatically namespaced based on the path the module is registered at
     *  https://vuex.vuejs.org/guide/modules.html#module-local-state */
    namespaced: true,
    state: {
        /** Show reopened tests*/
        adminShowReopenedTests: false,
    },

    mutations: {
        /** Set value of flag "Show reopened tests" for the list of testfails, admin part */
        setAdminShowReopenedTests(state, showReopenedTests) {
            state.adminShowReopenedTests = showReopenedTests;
        },
    }
}
