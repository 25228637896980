var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listItems,"item-key":"messageId","options":_vm.paginationOptions,"server-items-length":_vm.totalCountItems,"loading":_vm.loading},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("adminMessages.Title"))+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-select',{attrs:{"items":_vm.mctypes,"item-text":"title","item-value":"mctypeId","menu-props":"auto","label":_vm.$t('adminMessages.MessageType'),"hide-details":"","prepend-icon":"mdi-map-marker","single-line":""},model:{value:(_vm.currentMctypeId),callback:function ($$v) {_vm.currentMctypeId=$$v},expression:"currentMctypeId"}}),_c('v-spacer'),_c('router-link',{attrs:{"to":("/view/admin/messages/new/" + _vm.currentMctypeId),"tag":"button"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","disabled":_vm.currentMctypeId === 0}},[_vm._v(" "+_vm._s(_vm.$t("adminMessages.button.NewMessage"))+" ")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('router-link',{attrs:{"to":'/view/admin/messages/' + item.data.messageId + '/edit',"tag":"button"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("adminMessages.tooltip.EditMessage"))+" ")])])],1),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('router-link',{attrs:{"to":'/view/admin/messages/' + item.data.messageId + '/view',"tag":"button"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("adminMessages.tooltip.ViewMessage"))+" ")])])],1),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(item.publishedAt),expression:"item.publishedAt"}],staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(item.publishedAt),expression:"item.publishedAt"}],attrs:{"to":'/view/admin/messages/' + item.data.messageId + '/stat',"tag":"button"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-check ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("adminMessages.tooltip.StatByUsers"))+" ")])])],1)],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.dt2str(item.createdAt))+" ")])]}},{key:"item.publishedAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.publishedAt ? _vm.dt2str(item.publishedAt) : "Not published")+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }