import {handleResponse_401_Unauthorized} from "@/helpers/handle401";
import {getAuthorizationHeader} from "@/helpers/auth-header";
import {getUrlWithParams} from "@/helpers/url_utils";

export const adminUsersApi = {
    getListDbUsers
    , getListAdUsers
    , getUserById
    , createDbUserAd
    , createDbUserLocal
    , updateDbUser
    , deleteDbUser
    , setFA

    , getStatByMessages
    , getStatByCourses
};

//region Get users
/**
 * Get list of registered DB-users
 * @param rootStoreState
 * @param storeDispatch
 * @return {Promise<Response>}
 */
function getListDbUsers(rootStoreState, storeDispatch) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/users/dblist`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get user by user_id, DbUserEx
 * @param rootStoreState
 * @param storeDispatch
 * @param userId
 * @return {Promise<Response>}
 */
function getUserById(rootStoreState, storeDispatch, userId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/users/${userId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get list of un-registered AD-users
 * @param rootStoreState
 * @param storeDispatch
 * @return {Promise<Response>}
 */
function getListAdUsers(rootStoreState, storeDispatch) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/users/adlist`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Get users

//region Create users
/**
 * Register new AD-based user in DB
 * @param rootStoreState
 * @param storeDispatch
 * @param arrayInputModels {array}
 *      array of InputModelCreate-objects
 *      see FaUsersController.InputModelCreate:
 *      {
 *          UserName,
 *          Title,
 *          Flags
 *      }
 */
function createDbUserAd(rootStoreState, storeDispatch, arrayInputModels) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(arrayInputModels)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/users/ad`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
/**
 * Register new local user in DB (not Ad)
 * @param rootStoreState
 * @param storeDispatch
 * @param localUserData {array}
 *      array of InputModelCreate-objects
 *      see FaUsersController.InputModelCreate:
 *      {
 *          UserName,
 *          Title,
 *          Flags
 *      }
 */
function createDbUserLocal(rootStoreState, storeDispatch, localUserData) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(localUserData)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/users/local`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Create users

//region Update, delete users
/**
 * Update DB-user
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param updateModel
 *      see FaUsersController.InputModelUpdate
 *           {
 *               UserId,
 *               UpdateMask,
 *               Title,
 *               Disabled,
 *               SetNewPassword,
 *               LocalPassword,
 *               LocalUserLogin
 *           }
 *      UpdateMask can contain follow flags (see UserUpdate.tuser_ufields):
 *          TITLE = 0x1,
 *          DISABLED = 0x2
 */
function updateDbUser(rootStoreState, storeDispatch, updateModel) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(updateModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/users`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

function deleteDbUser(rootStoreState, storeDispatch, userId) {
    const requestOptions = {
        method: 'DELETE',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/users/${userId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Update, delete users

//region User rights
/**
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel
 *      InputModelSetFA = {
 *          UserId,  //target user_id
 *          FullAdmin  //grand or revoke FA
 *      }
 * @return {Promise<*>}
 */
function setFA(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/users/setfa`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion User rights

//region GetStatByMessages
/**
 * Get list of messages assigned to the user
 * @param rootStoreState
 * @param storeDispatch
 * @param userId
 * @param paginationOptions
 *      optional JSON with set of (optional) pagination options
 *      paginationOptions: {
 *           page: 1,
 *           pageCount: 0,
 *           itemsPerPage: 15,
 *           sortBy: "data.userId",
 *           sortDesc: true
 *     },
 * @return {Promise<Response>}
 */
function getStatByMessages(rootStoreState, storeDispatch, userId, paginationOptions) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = getUrlWithParams(`${rootStoreState.apiUrl}/api/admin/users/${userId}/stat/messages`, paginationOptions);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get list of courses assigned to the user
 * @param rootStoreState
 * @param storeDispatch
 * @param userId
 * @param paginationOptions
 *      optional JSON with set of (optional) pagination options
 *      paginationOptions: {
 *           page: 1,
 *           pageCount: 0,
 *           itemsPerPage: 15,
 *           sortBy: "data.userId",
 *           sortDesc: true
 *     },
 * @return {Promise<Response>}
 */
function getStatByCourses(rootStoreState, storeDispatch, userId, paginationOptions) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = getUrlWithParams(`${rootStoreState.apiUrl}/api/admin/users/${userId}/stat/courses`, paginationOptions);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion GetStatByMessages