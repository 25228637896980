<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-toolbar flat>
            <v-toolbar-title>
              {{$t("adminMessagesView.FormTitle", {id: messageData.data.messageId})}}
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                class="ma-2"
                @click="close"
            >
              {{$t("button.Close")}}
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>
      <!-- Message ID, from-name -->
      <v-row>
        <v-col>
          {{$t("label.From")}}: {{messageData.createdByUserName}}
        </v-col>
        <v-col>
          {{$t("label.Type")}}: {{messageData.messageTypeTitle}}
        </v-col>
        <v-col>
          {{$t("label.CreatedAt")}}: {{dt2str(messageData.createdAt)}}
        </v-col>
        <v-col>
          {{$t("label.PublishedAt")}}: {{messageData.publishedAt ? dt2str(messageData.publishedAt) : ""}}
        </v-col>
      </v-row>

      <!-- Recipients + message subject : attached files -->
      <v-row>
        <v-col cols="8">
          <v-container>
            <v-row>
              <v-col>
                <!-- Recipients - user groups -->
                <v-select
                    v-model="messageData.userGroupRecipients"
                    :items="listUserGroups"
                    :item-text="x => x.title"
                    :item-value="x => x.userGroupId"
                    :label="$t('adminMessagesView.UserGroups')"
                    chips
                    multiple
                    readonly
                ></v-select>
              </v-col>
            </v-row>
            <!-- Recipients - users -->
            <v-row>
              <v-col>
                <v-select
                    v-model="messageData.userRecipients"
                    :items="listUsers"
                    :item-text="x => x.title"
                    :item-value="x => x.userId"
                    :label="$t('adminMessagesView.Users')"
                    chips
                    multiple
                    readonly
                ></v-select>
              </v-col>
            </v-row>

<!-- Message subject -->
            <v-row>
              <v-col>
                <v-text-field v-model="messageData.data.title"
                              :label="$t('adminMessagesView.Subject')"
                              readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

<!-- Attached files -->
        <v-col cols="4">
          <v-sheet
              elevation="10"
              rounded="xl"
          >
            <v-sheet
                class="pa-3 accent text-right"
                dark
                rounded="t-xl"
            >
              {{$t("label.AttachedFiles")}}
            </v-sheet>

            <div class="pa-4">
              <v-list flat
                      max-height="20vh"
                      class="overflow-y-auto"
              >
                <v-list-item
                    v-for="(item, i) in messageData.attachedFiles"
                    :key="i"
                >
                  <v-chip class="ma-2">
                    {{item.originalFileName}}
                  </v-chip>
                </v-list-item>
              </v-list>
            </div>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-subheader>
            {{$t("adminMessagesView.MessageBody")}}
          </v-subheader>
          <div id="message_body" v-html="messageData.messageBody"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import {adminMessagesApi} from "@/api/admin.messages.api";
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {adminUserGroupsApi} from "@/api/admin.usergroups.api";
import {adminUsersApi} from "@/api/admin.users.api";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";

export default {
  name: "adminMessagesPageViewer",
  props: [
  ],

  data: function() {
    return {
      /**
       * All data of the message that is being edited (MessageEditEx)
       */
      messageData: {
        data: {},
        userRecipients: [],
        userGroupRecipients: [],
        attachedFiles: []
      },

      /** Full list of available dbUsers: DbUserEx */
      listUsers: [],
      /** Full list of available user groups: UserGroup */
      listUserGroups: [],
      fileToUpload: [],
    }
  },

  created() {
    //load list of available users
    adminUserGroupsApi.getListUserGroups(this.$store.state, this.$store.dispatch).then(
        user_groups => {
          this.listUserGroups = user_groups
        }
        , errors => showHttpErrorsInToasts(this, errors)
    );

    //load list of available user groups
    adminUsersApi.getListDbUsers(this.$store.state, this.$store.dispatch).then(
        users => {
          this.listUsers = users;
        }
        , errors => showHttpErrorsInToasts(this, errors)
    );

    // load messageData data
    adminMessagesApi.getEditMessageEx(this.$store.state, this.$store.dispatch, this.$route.params.id).then(
        m => {
          this.messageData = m;
        },
        error => showHttpErrorsInToasts(this, error)
    );
  },

  methods: {
    /** Save all message data and optionally publish the message */
    close: function() {
      this.$router.push({ path: `/view/admin/messages` })
    },

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },
  }
}
</script>

<style scoped>
/**
DOM content created with v-html are not affected by scoped styles, but you can still style them using deep selectors.
https://vue-loader-v14.vuejs.org/en/features/scoped-css.html
*/

div >>> #message_body img
{
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}
</style>