import {handleResponse_401_Unauthorized} from "@/helpers/handle401";
import {getAuthorizationHeader} from "@/helpers/auth-header";

export const masterUserApi = {
    getUsers
    , updateUsersRights
};

/**
 * Get full list of users to show in MasterUserPanel.
 * The users are the objects of type E21Model.DataRich.User
 *
 * @param rootStoreState
 *      root store.state (vuex)
 * @param storeDispatch
 *      store.dispatch (vuex)
 * @return {Promise<object>}
 *      see E21Model.DataRich.User
 */
function getUsers(rootStoreState, storeDispatch) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/masteruser`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Grand and/or revoke full admin rights
 *
 * @param rootStoreState
 *      root store.state (vuex)
 * @param storeDispatch
 *      store.dispatch (vuex)
 * @param delta {object}
 *      The object like follow one:
 *              {
 *                  "GrandFA": ["user-login-1", "user-login-2", "user-login-3"]
 *                  , "RevokeFA": ["user-login-4", "user-login-5"]
 *              }
 *      GrandFA = array with AD-user-names for which server must create user-db and assign FA-rights
 *      RevokeFA = array with AD-user-names for which server must unassign FA-rights
 */
function updateUsersRights(rootStoreState, storeDispatch, delta) {
    const request_options = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(delta)
    };

    return fetch(`${rootStoreState.apiUrl}/api/masteruser`, request_options)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
