import {handleResponse_401_Unauthorized} from "@/helpers/handle401";
import {getAuthorizationHeader} from "@/helpers/auth-header";

export const userTestsApi = {
    getTestUserView,

    checkTestResults,
};

//region Get
/**
 * Get TestUserView for specified test
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param testId
 * @return {Promise<Response>}
 */
function getTestUserView(rootStoreState, storeDispatch, testId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/user/tests/edit/${testId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Get

//region Set test as read or unread
/**
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel
 *         UserTestsController.InputModelUpdate
 *         {
 *             TestId,
 *             StartedAt,
 *             CompletedAt,
 *             Results
 *         }
 */
function checkTestResults(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModel)
    };

    const url = `${rootStoreState.apiUrl}/api/user/tests/check`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Set test as read or unread

