<template>
  <v-container>
      <v-sheet
          min-height="80vh"
          rounded="lg"
      >
        <router-view class ="us__content"/>
      </v-sheet>
  </v-container>
</template>

<script>

export default {
  name: "AdminView",
  data: () => ({
    authenticated: false,
    drawer: null
  }),
  mounted() {
    if(!this.$store.state.authentication.status.loggedIn) {
      this.$router.replace({ name: "login" });
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('authentication/logout').then(
          () => {
            this.$router.replace({ name: "login" });
          }
      )
    },
    /**
     * @return {boolean}
     *    False if we are on mobile
     */
    isDesktop: function() {
      return this.$vuetify.breakpoint.name !== "xs"
          && this.$vuetify.breakpoint.name !== "sm";
    }
  }
}
</script>

<style scoped>

</style>