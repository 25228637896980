<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-toolbar flat>
            <v-toolbar-title>
              {{$t("adminMessagesEditor.FormTitle")}}
              <v-subheader class="ma-0 pa-0">id={{messageData.data.messageId}}</v-subheader>
            </v-toolbar-title>


            <v-spacer></v-spacer>
            <v-btn
                color="warning"
                class="ma-2"
                @click="deleteItem()"
                v-if="!isNewMode()"
            >
              Delete
            </v-btn>

            <v-btn
                color="success"
                class="ma-2"
                :disabled="messageData.data.isPublished || (selectedUserIds.length === 0 && selectedUserGroupIds.length === 0)"
                @click="saveAndPublish(true)"
            >
              {{$t("adminMessagesEditor.button.SaveAndPublish")}}
            </v-btn>

            <v-btn
                color="primary"
                class="ma-2"
                :disabled="messageData.data.isPublished"
                @click="saveAndPublish(false)"
            >
              {{$t("button.Save")}}
            </v-btn>

            <v-btn
                class="ma-2"
                @click="close()"
            >
              {{$t("button.Close")}}
            </v-btn>

          </v-toolbar>
        </v-col>
      </v-row>
<!-- Message ID, from-name -->
      <v-row>
        <v-col>
          {{$t("label.From")}}: {{messageData.createdByUserName}}
        </v-col>
        <v-col>
          {{$t("label.Type")}}: {{messageData.messageTypeTitle}}
        </v-col>
        <v-col>
          {{$t("label.CreatedAt")}}: {{
            messageData.createdAt
                ? dt2str(messageData.createdAt)
                : ""
          }}
        </v-col>
        <v-col>
          {{$t("label.PublishedAt")}}: {{messageData.publishedAt ? dt2str(messageData.publishedAt) : ""}}
        </v-col>
      </v-row>

<!-- Recipients + message subject : attached files -->
      <v-row>
        <v-col cols="8">
          <v-container>
            <v-row>
              <v-col>
    <!-- Recipients - user groups -->
                <v-select
                    v-model="selectedUserGroupIds"
                    :items="listUserGroups"
                    :item-text="x => x.title"
                    :item-value="x => x.userGroupId"
                    :label="$t('adminMessagesEditor.UserGroups')"
                    chips
                    deletable-chips
                    multiple
                    clearable
                    :disabled="messageData.data.isPublished"
                ></v-select>
              </v-col>
            </v-row>
    <!-- Recipients - users -->
            <v-row>
              <v-col>
                <v-select
                    v-model="selectedUserIds"
                    :items="listUsers"
                    :item-text="x => x.title"
                    :item-value="x => x.userId"
                    :label="$t('adminMessagesEditor.Users')"
                    chips
                    deletable-chips
                    multiple
                    clearable
                    :disabled="messageData.data.isPublished"
                ></v-select>
              </v-col>
            </v-row>
      <!-- Message subject -->
            <v-row>
              <v-col>
                <v-text-field v-model="messageData.data.title"
                              :label="$t('adminMessagesEditor.Subject')"
                              :disabled="messageData.data.isPublished"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

<!-- Attached files -->
        <v-col cols="4">
          <v-sheet
              elevation="10"
              rounded="xl"
          >
            <v-sheet
                class="pa-3 accent text-right"
                dark
                rounded="t-xl"
            >
              <v-file-input v-model="fileToUpload"
                            :disabled="messageData.data.isPublished"
                            small-chips
                            show-size
                            multiple
                            clearable
                            :label="$t('label.AttachedFiles')"
              >
              </v-file-input>

              <div v-if="fileToUpload.length > 0">
                <div class="py-3">
                  <v-btn @click="uploadSelectedFiles">
                    {{$t("label.UploadSelectedFiles")}}
                  </v-btn>
                </div>
              </div>

            </v-sheet>

            <div class="pa-4">
                <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="isUploading"
                ></v-progress-circular>

                <v-list flat
                        max-height="20vh"
                        class="overflow-y-auto"
                >
                  <v-list-item
                      v-for="(item, i) in messageData.attachedFiles"
                      :key="i"
                  >
                    <v-chip
                        class="ma-2"
                        close
                        @click:close="removeAttachedFile(i)"
                    >
                      {{item.originalFileName}}
                    </v-chip>
                  </v-list-item>
                </v-list>
            </div>
          </v-sheet>
        </v-col>
      </v-row>

<!-- Message body -->
      <v-row>
        <v-col>
          <div>
            <v-subheader class="pa-0">
              {{$t("adminMessagesEditor.MessageBody")}}
            </v-subheader>

            <ckeditor-wrapper
                ref="ckeditorWrapper"
                v-bind:text.sync="messageData.messageBody"
                :readonly="messageData.data.isPublished"
            >
            </ckeditor-wrapper>

          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- a dialog to confirm deletion of the item -->
    <v-dialog v-model="dialogDelete"
              max-width="520px"
    >
      <v-card color="warning">
        <v-card-title class="headline">
          {{$t("adminMessagesEditor.delete.Confirm")}}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteDialog">
            {{$t("button.Cancel")}}
          </v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">
            {{$t("button.Ok")}}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import {adminMessagesApi} from "@/api/admin.messages.api";
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {adminUserGroupsApi} from "@/api/admin.usergroups.api";
import {adminUsersApi} from "@/api/admin.users.api";
import {adminFilesApi} from "@/api/admin.files.api";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";
import CkeditorWrapper from "@/components/shared/ckeditorWrapper";

export default {
  name: "adminMessagesPageEditor",
  components: {CkeditorWrapper},
  props: [
  ],

  data: function() {
    return {
      /**
       * All data of the message that is being edited (MessageEditEx)
       */
      messageData: {
        data: {},
        userRecipients: [],
        userGroupRecipients: [],
        attachedFiles: []
      },

      selectedUserIds: [],
      selectedUserGroupIds: [],

      /** Full list of available dbUsers: DbUserEx */
      listUsers: [],
      /** Full list of available user groups: UserGroup */
      listUserGroups: [],
      fileToUpload: [],
      /** file is being uploaded */
      isUploading: false,

      /** show delete-confirmation dialog*/
      dialogDelete: false,
    }
  },

  created() {
    this.init();
  },

  watch: {
    "dialogDelete": function(visible) {
      visible || this.closeDeleteDialog();
    },
  },

  /**
   * When new user group is created, the page is redirected to itself with different set of params
   * (to change isNewMode from true to false)
   *
   * So, "created" is not called second time if we move from one list to another.
   * We need to use beforeRouteEnter to workaround the problem.
   *
   * https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation
   * https://github.com/vuejs/vue-router/issues/1875
   **/
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.init();
    });
  },

  methods: {
//region Init
    /** true - new course is being created
     *  false - exist course is being edited */
    isNewMode: function() {
      return !this.$route.params.id;
    },

    init: function() {
      //load list of available users
      //FA - all users, GA - only users from the users groups for which GA is admin
      adminUserGroupsApi.getListUserGroups(this.$store.state, this.$store.dispatch).then(
          user_groups => {
            this.listUserGroups = user_groups
          }
          , errors => showHttpErrorsInToasts(this, errors)
      );

      //load list of available user groups
      //FA - all user groups, GA - only users groups for which GA is admin
      adminUsersApi.getListDbUsers(this.$store.state, this.$store.dispatch).then(
          users => {
            this.listUsers = users;
          }
          , errors => showHttpErrorsInToasts(this, errors)
      );

      if (! this.isNewMode()) {
        // load messageData data
        adminMessagesApi.getEditMessageEx(this.$store.state, this.$store.dispatch, this.$route.params.id).then(
            m => {
              this.messageData = m;
              this.selectedUserIds = this.messageData.userRecipients.map(x => x.userId);
              this.selectedUserGroupIds = this.messageData.userGroupRecipients.map(x => x.userGroupId);
              this.$refs.ckeditorWrapper.content = this.messageData.messageBody;
              this.$refs.ckeditorWrapper.isDisabled = this.messageData.data.isPublished;
            },
            error => showHttpErrorsInToasts(this, error)
        );
      }
    },
//endregion Init

//region Save and publish
    /** Save all message data and optionally publish the message */
    saveAndPublish: function(publishTheMessageAfterSaving) {
      if (this.isNewMode()) {
        this.save_and_create(publishTheMessageAfterSaving);
      } else {
        this.save_and_update(publishTheMessageAfterSaving);
      }
    },

    /** Save all message data and optionally publish the message */
    save_and_create: function(publishTheMessageAfterSaving) {
      adminMessagesApi.createMessage(this.$store.state, this.$store.dispatch
          , {
            mctypeId: this.$route.params.mctype_id,
            messageTitle: this.messageData.data.title,
            recipientsUserIds: this.selectedUserIds,
            recipientsUserGroupIds: this.selectedUserGroupIds,
            attachedFileIds: this.messageData.attachedFiles.map(x => x.fileId),
            messageBody: this.messageData.messageBody,
            messageBodyFormat: "HTML",
            isPublished: publishTheMessageAfterSaving,
          }
      ).then(
          message_id => {
            this.$toast.open({
              message: publishTheMessageAfterSaving
                  ? this.$t("adminMessagesEditor.message.Published", {id: message_id})
                  : this.$t("adminMessagesEditor.message.Created", {id: message_id}),
              type: 'success',
              position: 'top-right'
            });
            this.$router.push({ path: `/view/admin/messages` })

            //update statistics on left side-bar (see Normal view)
            this.$bus.$emit("refreshCountUnprocessedItems", {messages: true});
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
    },

    save_and_update: function(publishTheMessageAfterSaving) {
      adminMessagesApi.updateMessage(this.$store.state, this.$store.dispatch
          , {
            messageId: this.messageData.data.messageId,
            messageTitle: this.messageData.data.title,
            recipientsUserIds: this.selectedUserIds,
            recipientsUserGroupIds: this.selectedUserGroupIds,
            attachedFileIds: this.messageData.attachedFiles.map(x => x.fileId),
            messageBody: this.messageData.messageBody,
            messageBodyFormat: this.messageData.messageBodyFormat,
            isPublished: publishTheMessageAfterSaving,
          }
      ).then(
          () => {
            this.$toast.open({
              message: publishTheMessageAfterSaving
                  ? this.$t("adminMessagesEditor.message.Published", {id: this.messageData.data.messageId})
                  : this.$t("adminMessagesEditor.message.Saved", {id: this.messageData.data.messageId}),
              type: 'success',
              position: 'top-right'
            });

            //update statistics on left side-bar (see Normal view)
            this.$bus.$emit("refreshCountUnprocessedItems", {messages: true});
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
    },
//endregion Save and publish

//region Close
    close() {
      this.$router.push({ path: `/view/admin/messages` })
    },

//endregion Close

//region Attached files
    /**
     * Upload and register all selected files on the server.
     */
    uploadSelectedFiles: function() {
      if (this.fileToUpload.length > 0) {
        const files_to_upload = [
          ...this.fileToUpload
        ];
        this.fileToUpload = [];
        this.isUploading = true;
        adminFilesApi.uploadFiles(this.$store.state, files_to_upload)
            .then(response => {
              this.isUploading = false;
              let files = response.data;
              for (const f of files) {
                this.messageData.attachedFiles.push(f);
              }
            })
            .catch(error => {
              this.isUploading = false;
              showHttpErrorsInToasts(this, error);
            });
      }
    },

    /**
     * Detach the file from the message
     * (but don't delete already uploaded file from the server)
     * */
    removeAttachedFile(chipIndex) {
      if (chipIndex > -1 && chipIndex < this.messageData.attachedFiles.length) {
        this.messageData.attachedFiles.splice(chipIndex, 1);
      }
    },
//endregion Attached files

//region Delete item
    /** Show confirmation dialog to delete selected item*/
    deleteItem () {
      this.dialogDelete = true;
    },

    /** Cancel the dialog to confirm a deletion of the selected item*/
    closeDeleteDialog () {
      this.dialogDelete = false;
    },

    /** Item was confirmed to delete. Make real deletion*/
    deleteItemConfirm () {
      adminMessagesApi.deleteMessage(this.$store.state, this.$store.dispatch
          , this.messageData.data.messageId
      ).then(
          () => {
            this.$toast.open({
              message: this.$t("adminMessagesEditor.message.Deleted", {id: this.messageData.data.messageId}),
              type: 'success',
              position: 'top-right'
            });
            this.$router.replace({ path: `/view/admin/messages` })

            //update statistics on left side-bar (see Normal view)
            this.$bus.$emit("refreshCountUnprocessedItems", {messages: true});
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
      this.closeDeleteDialog();
    },
//endregion Delete item

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },
  }
}
</script>

<style scoped>

</style>