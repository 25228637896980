import {getAuthorizationHeader} from "@/helpers/auth-header";
import {handleResponse_401_Unauthorized} from "@/helpers/handle401";

/**
 * If function can be used in both admin and user mode,
 * the mode is passed as param to the function
 * */
export const documentsApi = {
    getDocument,

    createDocument,
    updateDocument,
    deleteDocument,
}

//region Get
/**
 * Get info about given document from the document archive
 * @param rootStoreState
 * @param storeDispatch
 * @param documentId
 * @param adminMode
 * @param printVersion
 *      true - get full HTML content to display in separate tab (=== print version of the document)
 * @return {Promise<Response>}
 *      function returns a promise that returns JSON with follow data:
 *          DocumentEditEx or DocumentUserContent
 *          {
 *              data: { //document
 *                  documentId,
 *                  title,
 *                  folderId,
 *                  cardId,
 *                  flags,
 *                  remarks
 *              }
 *              htmlContent //string
 *          }
 */
function getDocument(rootStoreState, storeDispatch, documentId, adminMode, printVersion) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/${adminMode ? "admin" : "user"}/documents/${documentId}${printVersion ? "/1" : ""}`

    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Get

//region Create, delete
/**
 * Create new document in the Document archive (section "Documents")
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel {object}
 *      see DocumentAppController.InputData
 *      {
 *          title,
 *          folderId,
 *          htmlContent,
 *          remarks
 *      }
 */
function createDocument(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/documents/create`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Delete the document from the document archive
 * @param rootStoreState
 * @param storeDispatch
 * @param documentId
 * @return {Promise<*>}
 */
function deleteDocument(rootStoreState, storeDispatch, documentId) {
    const requestOptions = {
        method: 'DELETE',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/documents/${documentId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Create, delete

//region Update
/**
 * Update document in the Document archive
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param updateModel
 *      see FaDocumentsController.InputModelUpdate
 *           {
 *              documentId,
 *              maskToUpdate, //see DocumentAppController.WhatToUpdate
 *              title,
 *              folderId,
 *              htmlContent,
 *              remarks
 *           }
 */
function updateDocument(rootStoreState, storeDispatch, updateModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(updateModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/documents/edit`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Update