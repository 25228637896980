var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listItems,"item-key":"data.userGroupId"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("adminUserGroupsEditor.FormTitle"))+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('router-link',{attrs:{"to":"/view/admin/user_groups/new","tag":"button"}},[(!_vm.isCurrentUserGAandNotFA())?_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","dark":""}},[_vm._v(" "+_vm._s(_vm.$t("adminUserGroups.button.NewUserGroup"))+" ")]):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("adminUserGroups.tooltip.delete.Confirm"))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDeleteDialog}},[_vm._v(" "+_vm._s(_vm.$t("button.Cancel"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" "+_vm._s(_vm.$t("button.Ok"))+" ")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[(_vm.isCurrentUserFA())?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{attrs:{"to":("/view/admin/user_groups/" + (item.data.userGroupId) + "/edit"),"tag":"button"}},[_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("adminUserGroups.FormTitle"))+" ")])]):_vm._e(),(_vm.isCurrentUserFA())?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e(),(_vm.isCurrentUserFA())?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("adminUserGroups.tooltip.delete"))+" ")])]):_vm._e(),(_vm.isCurrentUserFA())?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e(),_c('router-link',{attrs:{"to":'/view/admin/user_groups/' + item.data.userGroupId + '/stat/messages',"tag":"button"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-check ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("adminUserGroups.tooltip.StatByMessages"))+" ")])])],1),_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('router-link',{attrs:{"to":'/view/admin/user_groups/' + item.data.userGroupId + '/stat/courses',"tag":"button"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-alert ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("adminUserGroups.tooltip.StatByCourses"))+" ")])])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }