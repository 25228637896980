import {getAuthorizationHeader} from "@/helpers/auth-header";
import {handleResponse_401_Unauthorized} from "@/helpers/handle401";
import {getUrlWithParams} from "@/helpers/url_utils";

export const searchApi = {
    makeSearch,
};

//region Get

/**
 * Send search request to the server.
 * Get list of search results.
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel
 *  JSON, see SearchController.InputModelSearch for the list of possible fields
 * @return {Promise<Response>}
 */
function makeSearch(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const query = {...inputModel};

    const url = getUrlWithParams(`${rootStoreState.apiUrl}/api/search`, query);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Get