import {handleResponse_401_Unauthorized} from "@/helpers/handle401";
import {getAuthorizationHeader} from "@/helpers/auth-header";

export const userDashboardApi = {
    getDashboardInfo,
    getCountUnprocessedItemsForTheUser
};

//region Get
/**
 * Get dashboard info from
 *      UserDashboardController.Get
 *
 * @param rootStoreState
 * @param storeDispatch
 * @return {Promise<*>}
 *      Returns DashboardUserView
 */
function getDashboardInfo(rootStoreState, storeDispatch) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/user/dashboard`;

    //console.log("url", url);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get count of unprocessed messages and courses for specified user
 * @param rootStoreState
 * @param storeDispatch
 * @return {Promise<*>}
 */
function getCountUnprocessedItemsForTheUser(rootStoreState, storeDispatch) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/user/dashboard/stat`;

    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

//endregion Get