<!--
Pass final test
-->
<template>
  <div>
    <v-container>
<!-- Header: course, part -->
      <v-row>
        <v-col>
          <v-toolbar flat>
            <v-toolbar-title>
              <router-link :to="`/view/user/courses/${this.testData.course.courseId}/view`"
                           tag="button">
                {{`${this.$t("userCoursesTest.Course")}: ${this.testData.course.title} (id=${this.testData.course.courseId})`}}
              </router-link>
              <v-divider vertical class="mx-4"></v-divider>
              <router-link :to="`/view/user/parts/${this.testData.currentPart.part.partId}/view`"
                           tag="button">
                {{testData.currentPart.part.title}}
              </router-link>
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
      </v-row>

<!-- Title of the test -->
      <v-row>
        <v-col>
          <v-toolbar-title>{{testData.testContent.title}}</v-toolbar-title>
        </v-col>
      </v-row>

<!-- Test -->
      <v-row v-if="!testResults && !isTestFailedAndForbidden">
          <v-col>
            <v-container>
  <!-- Test instructions -->
              <v-row>
                <v-col>
                  <div id="test_body" v-html="testData.testContent.description"/>
                </v-col>
              </v-row>

  <!-- questions with possible answers -->
              <v-row v-for="(q, index) in testData.testContent.questions" :key="index">
                <v-col>
                  <v-card>
                    <v-card-title :class="{ error: displayErrors && !answers[index]}">
                      {{index + 1}}. {{q.question}}
                    </v-card-title>
                    <v-card-text>
                      <v-radio-group
                          v-model="answers[index]"
                          column
                      >
                        <v-radio v-for="a in testData.testContent.questions[index].answers"
                                 :key="a"
                                 :label="a"
                                 :value="a"
                        >
                        </v-radio>
                      </v-radio-group>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

    <!-- Check results -->
              <v-row>
                <v-col>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="primary"
                        class="ma-2"
                        @click="check_results()"
                    >
                      {{$t("userCoursesTest.CheckAndViewResults")}}
                    </v-btn>
                  </v-toolbar>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
      </v-row>

<!-- Test was failed and not permitted to pass -->
      <v-row v-if="isTestFailedAndForbidden">
        <v-col>
          <v-card>
            <v-alert color="error"
                     border="left"
                     type="error"
            >
              {{$t("userCoursesTest.MessageTestReopeningIsRequired")}}
            </v-alert>
            <v-card-text>
              <v-btn
                  color="secondary"
                  class="ma-2"
                  @click="close()"
              >
                {{$t("userCoursesTest.BackToCourse")}}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

<!-- Next actions after test completion-->
      <v-row v-if="testResults">
        <v-col>
          <v-container>
            <v-row v-if="!testResults.success">
              <v-col>
                <v-card>
                  <v-card-text>
                    <v-alert color="error"
                             border="left"
                             type="error"
                    >
                      {{$t("userCoursesTest.MessageTestNotPassed")}}
                    </v-alert>
                    <v-btn
                        color="secondary"
                        class="ma-2"
                        @click="close()"
                    >
                      {{$t("userCoursesTest.BackToCourse")}}
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="testResults.success">
              <v-col>
                <v-card>
                  <v-card-text>
                    <v-alert border="left"
                             type="success"
                    >
                      {{$t("userCoursesTest.MessageTestIsPassed")}}
                    </v-alert>
                    <router-link :to="`/view/user/parts/${this.testData.nextPart.part.partId}/view`"
                                 tag="button"
                                 v-if="isNextPartAvailable">
                      <v-btn
                          color="primary"
                          class="ma-2"
                      >
                        {{$t("userCoursesTest.button.NextPart")}}
                      </v-btn>
                    </router-link>

                    <router-link :to="`/view/user/courses/${this.testData.course.courseId}/view`"
                                 tag="button"
                                 v-if="isFinishCourseButtonAvailable">
                      <v-btn
                          color="success"
                          class="ma-2"
                      >
                        {{$t("userCoursesTest.button.FinishCourse")}}
                      </v-btn>
                    </router-link>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>/** Possible states of the current page */
import {userTestsApi} from "@/api/user.tests.api";

import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {getDateAsString} from "@/helpers/gutils";

export default {
  name: "userCoursesPageTest",
  props: [
  ],

  data: function() {
    return {
      /** Test is started to pass when it's opened */
      startedAt: getDateAsString(new Date()),

      /** Map:
       *    "questionIndex0": "selected answer"*/
      answers: {},

      /** Highlight all questions with missed answers. By default it's false,
       * but we set it to true on validation of results */
      displayErrors: false,

      /** All data of the part and it's active edition, see TestUserView */
      testData: {
        /** Test */
        data: {},
        /** Course */
        course: {},
        /** PartAndEdition */
        currentPart: {
          part: {}
        },
        /** JsonFinalTest_UserView */
        testContent: {
          Questions: [],
        }
      },

      /** Results received from the server
       * FinalTestResults */
      testResults: null,

      /** Current 0-based index of the question in the list of questions*/
      currentQuestionId: 0,

      /** User has failed the test.
       * Test is forbidden, permission of administrator is required to make next attempt to pass the test**/
      isTestFailedAndForbidden: false,
    }
  },

  computed: {
    isNextPartAvailable: function() {
      return this.testData.nextPart;
    },

    isFinishCourseButtonAvailable: function() {
      return !this.testData.nextPart;
    }
  },

  created() {
    this.init(this.$route.params.id);
  },

  /**
   * All parts use same component to display data.
   * So, "created" is not called second time if we move from one list to another.
   * We need to use beforeRouteEnter to workaround the problem.
   *
   * https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
   **/
  beforeRouteUpdate (to) {
    this.init(to.params.id);
  },

  methods: {
//region Init, close
    init(partId) {
      // load testData data
      userTestsApi.getTestUserView(this.$store.state, this.$store.dispatch, partId).then(
          m => {
            this.testData = m;
            this.isTestFailedAndForbidden = m.currentPart.isTestFailedAndForbidden;
          },
          error => showHttpErrorsInToasts(this, error)
      );
    },

    /** Return to course page */
    close: function() {
      this.$router.push({ path: `/view/user/courses/${this.testData.course.courseId}/view` });
    },
//endregion Init, close

//region Check results
    /** At first, ensure that all questions have not empty answers.
     * If any question has no answer, set displayError to true. !TODO: scroll to the answer
     * if all questions have answer then send results to server.
     * */
    check_results: function() {
      let q_indices = this.get_questions_without_answers();
      if (q_indices.length > 0) {
        this.displayErrors = true;
      } else {
        userTestsApi.checkTestResults(this.$store.state, this.$store.dispatch
          , {
              testId: this.testData.data.testId,
              startedAt: this.startedAt,
              completedAt: getDateAsString(new Date()),
              results: JSON.stringify(this.get_JsonFinalTestResults())
          }
        ).then(
            results => {
              this.testResults = results;

              //update statistics on left side-bar (see Normal view)
              this.$bus.$emit("refreshCountUnprocessedItems", {courses: true});
            },
        ).catch(error => showHttpErrorsInToasts(this, error));
      }
    },

    /** Return array of indices of the questions with not-selected answers */
    get_questions_without_answers: function() {
      let dest = [];
      for (let i = 0; i < this.testData.testContent.questions.length; ++i) {
        if (! this.answers[i]) {
          dest.push(i);
        }
      }
      return dest;
    },

    /** answers => JsonFinalTestResults*/
    get_JsonFinalTestResults: function() {
      let dest = [];
      for (let i = 0; i < this.testData.testContent.questions.length; ++i) {
        dest.push({
          question: this.testData.testContent.questions[i].question,
          user_answer: this.answers[i]
        });
      }
      return {
        Questions: dest
      }
    }
//endregion Check results
  }
}
</script>

<style scoped>
/**
DOM content created with v-html are not affected by scoped styles, but you can still style them using deep selectors.
https://vue-loader-v14.vuejs.org/en/features/scoped-css.html
*/

div >>> #test_body img
{
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}
</style>