<!--
Admin part.
List of parts registered for specified course.

Admin can see follow list:
    parts with editions (active edition, next edition, view all)
Part properties can be edited in embedded dialog.
New part and new edition can be added.
Any edition can be open for editing.
-->
<template>
  <div>
    <v-toolbar-title>
      <router-link :to="`/view/admin/courses/${this.$route.params.id}/edit`"
                   tag="button">
        {{
          this.$t("adminCoursesParts.CourseTitle"
              , {
                title: this.courseInfo ? this.courseInfo.title : ""
              }
          )
        }}
      </router-link>
    </v-toolbar-title>
    <v-subheader class="ma-0 pa-0">id={{this.$route.params.id}}</v-subheader>

    <v-data-table
        v-model="selected"
        :headers="headers"
        :items="listItems"
        item-key="data.partId"
        class="elevation-1"
        show-select
        single-select
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            {{$t("adminCoursesParts.FormTitle")}}
          </v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>

          <v-spacer></v-spacer>
<!-- Move up -->
          <v-btn
              class="ma-2"
              :disabled="selected.length === 0"
              @click="moveSelectedPart(true)"
          >
            {{$t("adminCoursesParts.button.MoveUp")}}
          </v-btn>

<!-- Move down -->
          <v-btn
              class="ma-2"
              :disabled="selected.length === 0"
              @click="moveSelectedPart(false)"
          >
            {{$t("adminCoursesParts.button.MoveDown")}}
          </v-btn>

          <!-- a dialog to insert/edit part -->
          <v-dialog
              v-model="dialogs.dialogEdit"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="ma-2"
                  v-bind="attrs"
                  v-on="on"
              >
                {{$t("adminCoursesParts.button.NewPart")}}
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">{{ dialogs.dialogEditTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <!-- About v-if, see: https://stackoverflow.com/questions/51472947/vuetifys-autofocus-works-only-on-first-modal-open -->
                      <v-text-field
                          v-model="dialogs.editedItem.data.title"
                          label="Title"
                          v-if="dialogs.dialogEdit" autofocus
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-subheader class="ma-0 pa-0">id={{dialogs.editedItem.data.partId}}</v-subheader>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="closeEditDialog"
                >
                  {{$t("button.Cancel")}}
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                >
                  {{$t("button.Save")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- a dialog to confirm deletion of the part/edition -->
          <v-dialog v-model="dialogs.dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">
                {{$t("adminCoursesParts.delete.Confirm")}}
              </v-card-title>
              <v-card-actions>
                <v-subheader class="ma-0 pa-0">id={{dialogs.editedItem.data.partId}}</v-subheader>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDeleteDialog">
                  {{$t("button.Cancel")}}
                </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                  {{$t("button.Ok")}}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

    <!-- Close buttons -->
          <router-link :to="`/view/admin/courses/${courseId}/edit`"
                       tag="button"
          >
            <v-btn
                color="info"
                class="ma-2"
            >
              {{$t("adminCoursesEditor.button.Close")}}
            </v-btn>
          </router-link>

        </v-toolbar>
      </template>

<!-- Active edition -->
      <template v-slot:item.active_content="{ item }">
  <!-- Status: [Not published] or OrderIndex -->
        <div v-if="item.activeEdition.orderIndex">
          {{$t("adminCoursesParts.edition.Published"
            , {index: item.activeEdition.orderIndex}
          )}}
        </div>
        <div v-else>
          {{$t("adminCoursesParts.edition.NotPublished")}}
        </div>
  <!-- Button to edit the active edition -->
        <router-link :to="`/view/admin/editions/${item.activeEdition.editionId}`">
          {{$t("adminCoursesParts.edition.Edit")}}
        </router-link>
        <div v-if="item.data.isPublished && item.nextEditions.length === 0">
          <router-link :to="`/view/admin/editions/new/${item.data.partId}`">
            {{$t("adminCoursesParts.edition.CreateNext")}}
          </router-link>
        </div>
        <div v-if="item.nextEditions.length !== 0">
          <router-link :to="`/view/admin/editions/${item.nextEditions[0].editionId}`">
            {{$t("adminCoursesParts.edition.Next")}}
          </router-link>
        </div>
      </template>

<!-- Final test -->
      <template v-slot:item.test="{ item }">
        <v-icon
            v-if="item.activeEdition.finalTestId"
            small
            class="mr-2"
        >
          mdi-checkbox-marked-circle
        </v-icon>
      </template>

<!-- Status: Not published or the date of part's publication -->
      <template v-slot:item.status="{ item }">
        <div v-if="item.data.isPublished">
          {{dt2str(item.publishedAt)}}
        </div>
        <div v-else>
          {{$t("adminCoursesParts.status.NotPublished")}}
        </div>
      </template>

<!-- actions column: Edit, delete, move up/down - with tooltips -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-center">
  <!-- Edit button (to edit part's title) -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>
              {{$t("adminCoursesParts.actions.EditPart")}}
            </span>
          </v-tooltip>

<v-divider vertical class="mx-4"/>

<!--  &lt;!&ndash; View all registered editions &ndash;&gt;-->
<!--          <v-tooltip top>-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <v-icon-->
<!--                  small-->
<!--                  v-bind="attrs"-->
<!--                  v-on="on"-->
<!--              >-->
<!--                mdi-file-multiple-->
<!--              </v-icon>-->
<!--            </template>-->
<!--            <span>-->
<!--              {{$t("adminCoursesParts.actions.ViewEditions")}}-->
<!--            </span>-->
<!--          </v-tooltip>-->

<!--  <v-divider vertical class="mx-4"/>-->

          <!-- Delete button (to delete the part) -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>
              {{$t("adminCoursesParts.actions.DeletePart")}}
            </span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <div class="d-flex justify-center">
          {{ dt2str(item.createdAt) }}
        </div>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {adminPartsApi} from "@/api/admin.parts.api";
import {adminCoursesApi} from "@/api/admin.courses.api";
import {convertIsoDateToDisplayDate, deepClone} from "@/helpers/gutils";

export default {
  name: "adminCoursesPageParts",

  data: function() {
    return {
      /** ID of the course provided through URL*/
      courseId: this.$route.params.id,

      /** Course-struct loaded from server by courseId */
      courseInfo: {},

      /** List of parts */
      listItems: [],

      /** Selected items to move up/down*/
      selected: [],

      /** Columns of the list of DB-users
       * Sorting is disabled because of implementation of move up/down
       * */
      headers: [
        {
          text: this.$t("adminCoursesParts.headers.Id"),
          align: 'start',
          value: 'data.partId',
          sortable: false,
          width: "80"
        },
        {
          text: this.$t("adminCoursesParts.headers.Title"),
          value: 'data.title',
          sortable: false,
        },
        {
          text: this.$t("adminCoursesParts.headers.Created"),
          value: 'createdAt',
          sortable: false,
          width: 150,
        },
        {
          text: this.$t("adminCoursesParts.headers.Creator"),
          value: 'createdByUserName',
          sortable: false,
        },
        {
          text: this.$t("adminCoursesParts.headers.ActiveEdition"),
          value: 'active_content',
          sortable: false,
          width: 200
        },
        {
          text: this.$t("adminCoursesParts.headers.FinalTest"),
          value: 'test',
          sortable: false,
          width: 100,
          align: 'center'
        },
        {
          text: this.$t("adminCoursesParts.headers.Status"),
          value: 'status',
          sortable: false,
          width: 150
        },
        {
          text: this.$t("adminCoursesParts.headers.Actions"),
          value: 'actions',
          align: 'center',
          sortable: false,
          width: 200
        },
      ],

      /** dialogs to edit, insert or delete items*/
      dialogs: {
        /** show edit dialog to edit DB-user properties*/
        dialogEdit: false,
        /** show delete-confirmation dialog*/
        dialogDelete: false,

        /** The index of the currently edited item in listItems
         * -1 - there is no item that is being edited*/
        editedIndex: -1,
        /** A copy of the item that is being edited*/
        editedItem: {
          data: {
            title: "",
            partId: 0
          }
        },
        /** Default values of the item.
         * When a dialog is close, we copy these values to editedItem */
        defaultItem: {
          data: {
            title: "",
            partId: 0
          }
        },

        /** A title of edit dialog.
         * We refresh it using refreshEditDialogTitle*/
        dialogEditTitle: "",
      },

    }
  },

  watch: {
    "dialogs.dialogEdit": function(visible) {
      visible || this.closeEditDialog();
    },
    "dialogs.dialogDelete": function(visible) {
      visible || this.closeDeleteDialog();
    },
    "dialogs.editedIndex": function () {
      this.refreshEditDialogTitle();
    }
  },

  created() {
    this.loadDataFromServer();
    this.refreshEditDialogTitle();

    //load info about the course
    adminCoursesApi.getCourse(this.$store.state, this.$store.dispatch
        , this.courseId
    ).then(
        c => {
          this.courseInfo = c;
        }
    ).catch(error => {
      showHttpErrorsInToasts(this, error);
    });
  },

  methods: {
//region Load data
    /** Load list of all parts of the course from the server */
    loadDataFromServer: function() {
      adminPartsApi.getListParts(this.$store.state, this.$store.dispatch
          , this.courseId
          , true //all parts and editions (active and inactive)
      ).then(
          list => {
            this.listItems = list;
          }
      ).catch(error => {
        showHttpErrorsInToasts(this, error);
      });
    },
//endregion Load data

    refreshEditDialogTitle() {
      this.dialogs.dialogEditTitle = this.dialogs.editedIndex === -1
          ? this.$t("adminCoursesParts.dialog.New")
          : this.$t("adminCoursesParts.dialog.Edit")
    },

//region Edit/insert item
    /** Show dialog to edit selected item*/
    editItem (item) {
      this.dialogs.editedIndex = this.listItems.indexOf(item);
      this.dialogs.editedItem = deepClone(item);
      this.dialogs.dialogEdit = true;
    },

    /** Close the dialog to edit selected item*/
    closeEditDialog () {
      this.dialogs.dialogEdit = false;
      this.$nextTick(() => {
        this.dialogs.editedItem = deepClone(this.dialogs.defaultItem);
        this.dialogs.editedIndex = -1;
      })
    },

    /** Save changes after editing/inserting */
    save () {
      if (this.dialogs.editedIndex > -1) {
//edit exist part
        adminPartsApi.updatePart(this.$store.state
            , this.$store.dispatch
            , {
              partId: this.listItems[this.dialogs.editedIndex].data.partId,
              title: this.dialogs.editedItem.data.title
            }
        ).then(
            () => {
              this.loadDataFromServer();
            }
        ).catch(error => {
          showHttpErrorsInToasts(this, error);
        });
      } else {
//create new part
        adminPartsApi.createPart(this.$store.state
            , this.$store.dispatch
            , {
              courseId: this.courseId,
              title: this.dialogs.editedItem.data.title
            }
        ).then(
            () => {
              this.loadDataFromServer();
            }
        ).catch(error => {
          showHttpErrorsInToasts(this, error);
        });
      }

      this.closeEditDialog();
    },
//endregion Edit/insert item

//region Delete item
    /** Show confirmation dialog to delete selected item*/
    deleteItem (item) {
      this.dialogs.editedIndex = this.listItems.indexOf(item);
      this.dialogs.editedItem = deepClone(item);
      this.dialogs.dialogDelete = true;
    },

    /** Close the dialog to confirm a deletion of the selected item*/
    closeDeleteDialog () {
      this.dialogs.dialogDelete = false;
      this.$nextTick(() => {
        this.dialogs.editedItem = deepClone(this.dialogs.defaultItem);
        this.dialogs.editedIndex = -1;
      })
    },

    /** Item was confirmed to delete. Make real deletion*/
    deleteItemConfirm () {
      adminPartsApi.deletePart(this.$store.state
          , this.$store.dispatch
          , this.listItems[this.dialogs.editedIndex].data.partId
      ).then(
          () => {
            this.listItems.splice(this.dialogs.editedIndex, 1)
          }
      ).catch(error => {
        showHttpErrorsInToasts(this, error);
      });
      this.closeDeleteDialog();
    },
//endregion Delete item

//region Move up/down
    moveSelectedPart(moveUp) {
      if (this.selected && this.selected.length === 1) {
        //load info about the course
        adminPartsApi.movePart(this.$store.state, this.$store.dispatch
            , {
              partId: this.selected[0].data.partId
              , moveUp: moveUp
            }
        ).then(
            /** actual list of part positions - array of all parts and their positions
             *     PartPosition = {partId, orderIndex}
             *  ordered by orderIndex
             * */
            pp => {
              for (let i = 0; i < this.listItems.length; ++i) {
                for (let n = 0; n < pp.length; ++n) {
                  if (this.listItems[i].data.partId === pp[n].partId) {
                    this.listItems[i].data.orderIndex = pp[n].orderIndex;
                  }
                }
              }
              this.listItems.sort(function(a, b) {
                return a.data.orderIndex - b.data.orderIndex;
              });

              this.$forceUpdate();
            }
        ).catch(error => {
          showHttpErrorsInToasts(this, error);
        });
      }
    },
//endregion Move up/down

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },
  }
}
</script>

<style scoped>

</style>