<template>
  <router-view/>
</template>

<script>
//npm install vue-router
//npm install vuex --save
//npm install vue-toast-notification
//npm install --save-dev jest
//npm install --save @ckeditor/ckeditor5-vue2 @ckeditor/ckeditor5-build-classic
//npm install axios
//npm install --save @ckeditor/ckeditor5-upload
//npm i --save lodash
//npm install --save @ckeditor/ckeditor5-alignment
//npm install vue-cookies --save
//npm install material-design-icons-iconfont -D



export default {
  data: () => ({
    authenticated: false,

    radioGroup: 1,
  }),
}
</script>
