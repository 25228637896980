import Vue from 'vue'
import VueRouter from 'vue-router'

import loginPage from "../components/login/login.vue"
import masterPage from "../components/master-user/MasterUserPanel.vue"

import userDashboardPage from "../components/user/userDashboardPage";
import adminUsersPage from "../components/admin/adminUsersPage";
import adminCoursesPage from "../components/admin/adminCoursesPage";

import NormalView from "@/components/app-view/NormalView";
import AdminView from "@/components/app-view/AdminView";
import adminUserGroupsPage from "@/components/admin/adminUserGroupsPage";
import adminMctypesPage from "@/components/admin/adminMctypesPage";
import adminMessagesPage from "@/components/admin/adminMessagesPage";
import adminMessagesPageEditor from "@/components/admin/adminMessagesPageEditor";
import adminMessagesPageViewer from "@/components/admin/adminMessagesPageViewer";
import adminCoursesPageEditor from "@/components/admin/adminCoursesPageEditor";
import adminCoursesPageParts from "@/components/admin/adminCoursesPageParts";
import adminEditionPage from "@/components/admin/adminEditionPage";
import adminUserGroupsPageEditor from "@/components/admin/adminUserGroupsPageEditor";
import UserView from "@/components/app-view/UserView";
import userMessagesPage from "@/components/user/userMessagesPage";
import userMessagesPageViewer from "@/components/user/userMessagesPageViewer";
import userCoursesPage from "@/components/user/userCoursesPage";
import userCoursesPageViewer from "@/components/user/userCoursesPageViewer";
import userCoursesPagePart from "@/components/user/userCoursesPagePart";
import userCoursesPageTest from "@/components/user/userCoursesPageTest";
import adminTestfailsPage from "@/components/admin/adminTestfailsPage";
import adminCoursesPageGaMembers from "@/components/admin/adminCoursesPageGaMembers";
import adminCoursesPageStatByUsers from "@/components/admin/adminCoursesPageStatByUsers";
import adminMessagesPageStatByUsers from "@/components/admin/adminMessagesPageStatByUsers";
import adminUsersPageStatByMessages from "@/components/admin/adminUsersPageStatByMessages";
import adminUsersPageStatByCourses from "@/components/admin/adminUsersPageStatByCourses";
import adminUserGroupsPageStatByMessages from "@/components/admin/adminUserGroupsPageStatByMessages";
import adminUserGroupsPageStatByCourses from "@/components/admin/adminUserGroupsPageStatByCourses";
import adminTestfailsPageViewResults from "@/components/admin/adminTestfailsPageViewResults";
import searchPage from "@/components/search/searchPage";
import adminFormsPage from "@/components/admin/adminFormsPage";
import adminDocumentsPage from "@/components/admin/adminDocumentsPage";
import adminDocumentPageEditor from "@/components/admin/adminDocumentPageEditor";
import userFormsPage from "@/components/user/userFormsPage";
import userDocumentsPage from "@/components/user/userDocumentsPage";
import userDocumentPageViewer from "@/components/user/userDocumentPageViewer";

Vue.use(VueRouter);

export const router = new VueRouter({
    routes: [
        {
            path: '/',
            redirect: {
                name: "login"
            }
        },
        {
            path: "/login",
            name: "login",
            component: loginPage
        },
        {
            path: "/master",
            name: "master",
            component: masterPage,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/view",
            component: NormalView,
            children: [
                {
                    path: 'dashboard',
                    component: userDashboardPage
                }, {
                    path: 'admin',
                    component: AdminView,
                    children: [
                        {
                            path: 'users',
                            component: adminUsersPage
                        }, {
                            path: 'users/:id/stat/messages',
                            component: adminUsersPageStatByMessages
                        }, {
                            path: 'users/:id/stat/courses',
                            component: adminUsersPageStatByCourses
                        }, {
                            path: 'user_groups',
                            component: adminUserGroupsPage
                        }, {
                            path: 'user_groups/:id/edit',
                            component: adminUserGroupsPageEditor
                        }, {
                            path: 'user_groups/new',
                            component: adminUserGroupsPageEditor
                        }, {
                            path: 'user_groups/:id/stat/messages',
                            component: adminUserGroupsPageStatByMessages
                        }, {
                            path: 'user_groups/:id/stat/courses',
                            component: adminUserGroupsPageStatByCourses
                        }, {
                            path: 'messages',
                            component: adminMessagesPage
                        }, {
                            path: 'messages/:id/edit',
                            component: adminMessagesPageEditor
                        }, {
                            path: 'messages/:id/stat',
                            component: adminMessagesPageStatByUsers
                        }, {
                            path: 'messages/:id/view',
                            component: adminMessagesPageViewer
                        }, {
                            path: 'messages/new/:mctype_id',
                            component: adminMessagesPageEditor
                        }, {
                            path: 'courses',
                            component: adminCoursesPage
                        }, {
                            path: 'courses/:id/edit',
                            component: adminCoursesPageEditor                        }, {
                            path: 'courses/:id/gamembers',
                            component: adminCoursesPageGaMembers
                        }, {
                            path: 'courses/:id/parts',
                            component: adminCoursesPageParts
                        }, {
                            path: 'courses/:id/stat',
                            component: adminCoursesPageStatByUsers
                        }, {
                            path: 'courses/new/:mctype_id',
                            component: adminCoursesPageEditor
                        }, {
                            path: 'message_types',
                            component: adminMctypesPage,
                            props: {kind: 1}
                        }, {
                            path: 'course_types',
                            component: adminMctypesPage,
                            props: {kind: 0}
                        }, {
                            path: 'editions/:edition_id',
                            component: adminEditionPage
                        }, {
                            path: 'editions/new/:part_id',
                            component: adminEditionPage
                        }, {
                            path: 'tests/new/:part_id',
                            component: adminEditionPage
                        }, {
                            path: 'tests/:test_id/edit',
                            component: adminEditionPage
                        }, {
                            path: 'testfails',
                            component: adminTestfailsPage
                        }, {
                            path: 'testfails/:testfailId/view_results',
                            component: adminTestfailsPageViewResults
                        }, {
                            path: 'forms/:folder_id?',
                            component: adminFormsPage
                        }, {
                            //it can be used as:
                            //   documents
                            //   documents/openFolder/folder_id
                            //   documents/openDocument/document_id
                            path: 'documents/:kind?/:id?',
                            component: adminDocumentsPage
                        }, {
                            path: 'documents/document/edit/:documentId',
                            component: adminDocumentPageEditor
                        }, {
                            path: 'documents/document/create/:folderId',
                            component: adminDocumentPageEditor
                        }
                    ]
                }, {
                    path: 'user',
                    component: UserView,
                    children: [
                        {
                            path: 'messages/:mctype_id?',
                            component: userMessagesPage
                        }, {
                            path: 'messages/:id/view',
                            component: userMessagesPageViewer
                        }, {
                            path: 'courses/:mctype_id?',
                            component: userCoursesPage
                        }, {
                            path: 'courses/:id/view',
                            component: userCoursesPageViewer
                        }, {
                            path: 'parts/:id/view',
                            component: userCoursesPagePart
                        }, {
                            path: 'parts/:id/test',
                            component: userCoursesPageTest
                        }, {
                            path: 'search',
                            component: searchPage
                        }, {
                            path: 'forms/:folder_id?',
                            component: userFormsPage
                        }, {
                            //it can be used as:
                            //   documents
                            //   documents/openFolder/folder_id
                            //   documents/openDocument/document_id
                            path: 'documents/:kind?/:id?',
                            component: userDocumentsPage
                        }, {
                            // admin = 1: return to Document in admin section on close
                            // admin = 0: return to Documents in user section on close
                            path: 'documents/document/view/:documentId/:adminMode?',
                            component: userDocumentPageViewer
                        }
                    ]
                }
            ],
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "*",
            redirect: {
                path: "/view"
            }
        },
    ]
});

router.beforeEach((to, from, next) => {
    if (window.vFact3) {
        window.vFact3.stop();
    } else if (window.vFact_dostop) {
        window.vFact_dostop();
    }

    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const auth_required = !publicPages.includes(to.path);

    if (auth_required) {
        const current_user = localStorage.getItem('user');
        if (current_user) {
            const user = JSON.parse(current_user);
            const masterPages = ['/master'];
            const master_auth_required = masterPages.includes(to.path);
            if (user.isMasterUser) {
                //master user doesn't have access to any pages except master-panel
               if (! master_auth_required) {
                   return next('/login');
               }
            } else {
                //not-master user doesn't have access master-panel
                if (master_auth_required) {
                    return next('/login');
                }
            }
        } else {
            //user is not logged-in
            return next('/login');
        }
    }

    next();
})

export default router;