import {handleResponse_401_Unauthorized} from "@/helpers/handle401";
import {getAuthorizationHeader} from "@/helpers/auth-header";
import {getUrlWithParams} from "@/helpers/url_utils";

export const userMessagesApi = {
    getListMessages,
    getMessageViewEx,

    setMessageStatus
};

//region Get
/**
 * Get list of messages available for specified user
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel
 *      MessageAppController.InputDataGetUserMessages
 *      {
 *          McTypeId: optional,
 *          UnreadOnly: false
 *      }
 * @param paginationOptions
 *      optional JSON with set of (optional) pagination options
 *      paginationOptions: {
 *           page: 1,
 *           pageCount: 0,
 *           itemsPerPage: 15,
 *           sortBy: "messageId",
 *           sortDesc: true
 *     },
 * @return {Promise<Response>}
 *      function returns a promise that returns JSON with follow data:
 *          {
 *              paginationOptions  - current set of the pagination options
 *              , items - list of the messages on the currently selected page
 *          }
 */
function getListMessages(rootStoreState, storeDispatch, inputModel, paginationOptions) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const query = {...paginationOptions, ...inputModel};

    const url = getUrlWithParams(`${rootStoreState.apiUrl}/api/user/messages/list`, query);

    //console.log("url", url);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get MessageUserViewEx for specified message
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param messageId
 * @return {Promise<Response>}
 */
function getMessageViewEx(rootStoreState, storeDispatch, messageId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/user/messages/view/${messageId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Get

//region Set message as read or unread
/**
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel
 *         UserMessagesController.InputModelUpdate
 *         {
 *             MessageId,
 *             IsRead
 *         }
 */
function setMessageStatus(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModel)
    };

    const url = `${rootStoreState.apiUrl}/api/user/messages/update`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Set message as read or unread