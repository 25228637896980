<template>
  <v-app id="inspire">
    <script type="application/javascript"
            defer
            :src="get_vfact_client_script()"
    />

<!-- Drawer -->
    <v-navigation-drawer
        app
        v-model="drawer"
    >
  <!-- Image and name of the logged user -->
      <template v-slot:prepend>
        <v-list-item two-line>
<!--          <v-list-item-avatar>-->
<!--            <img src="https://randomuser.me/api/portraits/women/81.jpg">-->
<!--          </v-list-item-avatar>-->
          <v-list-item-content>
            <v-list-item-title>{{getCurrentUserName()}}</v-list-item-title>
            <v-list-item-subtitle>{{$t("normalView.LoggedIn")}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>
  <!-- Sidebar menu for normal user -->
      <v-list dense>
        <!--
        for append-icon trick see https://github.com/vuetifyjs/vuetify/issues/4369
        -->
        <div v-for="item in sideBarItems"
             :key="item.title">
          <v-list-group
              v-model="item.active"
              no-action
              @click="on_click_menu_item(item)"
              :append-icon="!item.items ? '' : undefined"
              v-if="is_sidebar_menu_available(item)"
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon v-if="item.id === 'messages'" color="red">{{getIconForUnreadMessages()}}</v-icon>
                <v-icon v-if="item.id === 'courses'" color="red">{{getIconForUndoneCourses()}}</v-icon>
              </v-list-item-icon>
            </template>

            <v-list-item
                v-for="child in item.items"
                :key="child.title"
                :to="child.link"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-icon>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>

  <!-- Sidebar menu for admin user -->
      <div v-if="isCurrentUserFA() || isCurrentUserGAandNotFA()">
        <v-divider></v-divider>

        <v-list dense>
          <!--
          for append-icon trick see https://github.com/vuetifyjs/vuetify/issues/4369
          -->
          <div v-for="item in adminSideBarItems"
               :key="item.title">
            <v-list-group
                v-model="item.active"
                no-action
                @click="on_click_menu_item(item)"
                :append-icon="!item.items ? '' : undefined"
                v-if="is_sidebar_menu_available(item)"
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                  v-for="child in item.items"
                  :key="child.title"
                  :to="child.link"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </div>
        </v-list>
      </div>
  <!-- logout button -->
      <template v-slot:append>
        <v-list-item>
          <v-list-item-content>
            <router-link
                to="/login"
                tag="button"
            >
              <v-btn block>
                {{$t("normalView.Logout")}}
              </v-btn>
            </router-link>
            <v-layout class="ma-0 pa-0">
              <v-subheader class="ma-0 pa-0">build {{get_app_version()}}</v-subheader>
              <v-spacer></v-spacer>
            </v-layout>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>

<!-- Application bar -->
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

<!-- Search bar -->
      <v-container class="search-bar-container">
        <v-row no-gutters>
          <v-col cols="2" class="search-bar-column">
            <v-toolbar-title>{{$t("login.appTitle")}}</v-toolbar-title>
          </v-col>
          <v-col cols="1" class="search-bar-column">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    v-on:click="play_vfact()"
                >
                  <v-icon>
                    mdi-play
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{$t("vfact.play")}}
              </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    v-on:click="stop_vfact()"
                >
                  <v-icon>
                    mdi-stop
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{$t("vfact.stop")}}
              </span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    v-on:click="config_vfact()"
                >
                  <v-icon>
                    mdi-cog
                  </v-icon>
                </v-btn>
              </template>
              <span>
                {{$t("vfact.config")}}
              </span>
            </v-tooltip>

<!--            <v-btn-->
<!--                icon-->
<!--                small-->
<!--                v-on:click="help_vfact()"-->
<!--            >-->
<!--              <v-icon>-->
<!--                mdi-help-->
<!--              </v-icon>-->
<!--            </v-btn>-->

          </v-col>

  <!-- List of search targets -->
          <v-col cols="3" class="search-bar-column">
            <v-select
                hide-details
                class="mx-2"
                v-model="selectedSearchTargets"
                :items="searchTargets"
                :label="$t('normalViewSearch.WhereToSearch')"
                multiple
            >
              <template v-slot:prepend-item>
                <v-list-item
                    ripple
                    @click="toggle_search_targets"
                >
                  <v-list-item-action>
                    <v-icon :color="selectedSearchTargets.length > 0 ? 'indigo darken-4' : ''">
                      {{ search_targets_icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{$t('normalViewSearch.SelectAll')}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="{ item, index }">
                <div v-if="selectedSearchTargets.length === searchTargets.length">
                  <!-- all targets are selected -->
                  <span v-if="index === 0">{{$t('normalViewSearch.Everywhere')}}</span>
                </div>
                <div v-else>
                  <div style="white-space:pre;" v-if="index < maxDisplayTargets">{{`${item}`}} &nbsp;</div>
                  <div
                      v-if="index === maxDisplayTargets"
                      class="grey--text caption"
                  > &nbsp;(+{{ selectedSearchTargets.length - maxDisplayTargets }})</div>
                </div>
              </template>
            </v-select>
          </v-col>

  <!-- List of search languages -->
          <v-col cols="1" class="search-bar-column">
            <v-select
                hide-details
                class="mx-2"
                v-model="selectedSearchLanguages"
                :items="searchLanguages"
                :label="$t('normalView.Languages')"
                multiple
                item-text="abbr"
                item-value="abbr"
                :hint="`${selectedSearchLanguages.name}, ${selectedSearchLanguages.abbr}`"
                return-object
            >
              <template v-slot:prepend-item>
                <v-list-item
                    ripple
                    @click="toggle_search_languages"
                >
                  <v-list-item-action>
                    <v-icon :color="selectedSearchLanguages.length > 0 ? 'indigo darken-4' : ''">
                      {{ search_languages_icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{$t("normalView.SelectAll")}}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:item="{ parent, item, on, attrs }">
                <!-- https://stackoverflow.com/questions/66250354/vuetify-how-to-include-checkbox-to-v-select-when-customizing-text -->
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        {{ item.name }}, {{ item.abbr }}
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item, index }">
                <div v-if="selectedSearchLanguages.length === searchLanguages.length">
                  <div v-if="index === 0">{{$t('normalViewSearch.Any')}}</div>
                </div>
                <div v-else>
                  <div style="white-space:pre;" v-if="index < maxDisplayLanguages">{{`${item.abbr}`}} &nbsp;</div>
                  <div
                      v-if="index === maxDisplayLanguages"
                      class="grey--text caption"
                  > &nbsp;(+{{ selectedSearchLanguages.length - maxDisplayLanguages }})</div>
                </div>
              </template>
            </v-select>
          </v-col>

  <!-- Search editbox to enter query text -->
          <v-col cols="3" class="search-bar-column">
            <v-text-field
                v-model="textToSearch"
                hide-details
                class="mx-2"
                :label="$t('normalViewSearch.TextToSearch')"
                @keydown.enter="make_search"
            ></v-text-field>
          </v-col>

  <!-- Search button -->
          <v-col cols="2" class="search-bar-column">
            <v-btn
                icon
                v-on:click="make_search()"
                :disabled="this.selectedSearchTargets.length === 0 || !textToSearch || selectedSearchLanguages.length === 0">
              <v-icon
                  color="primary"
              >
                mdi-magnify
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

<!-- Main view -->
    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col>
            <v-sheet
                min-height="80vh"
                rounded="lg"
            >
              <router-view class ="us__content" ref="view_child_control"/>
            </v-sheet>
          </v-col>


        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
//npm install vue-router
//npm install vuex --save

import {userDashboardApi} from "@/api/user.dashboard.api";
import {foldersApi} from "@/api/folders.api";
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {treeDocumentsUtils} from "@/helpers/treeDocumentsUtils";
import {compareUtils} from "@/helpers/compareUtils";

export default {
  name: "NormalView",
  data: function() {
    return {
      authenticated: false,

      /** Sidebar = left-side menu in the drawer
       *
       *  For all not-expandable root menu items,
       *  we need to specify not null id, see on_click_menu_item
       * */
      sideBarItems: [
        {title: this.$t("normalView.Dashboard"), icon: 'mdi-view-dashboard', link: '/view/dashboard', id: "dashboard"},
        {title: this.$t("normalView.Messages"), icon: 'mdi-email-outline', link: '/view/user/messages', id: "messages"},
        {title: this.$t("normalView.Courses"), icon: 'mdi-view-list', link: '/view/user/courses', id: "courses"},
        {title: this.$t("normalView.Forms"), icon: 'mdi-file-multiple', link: '/view/user/forms', id: "forms"},

          //#110: instead menu item "Documents" we should show list of subfolders created directly in the folder Documents
          //as result, users need to make less clicks to access the subfolders
          //New menu item are added on create and are modified in response of the event
          //{title: this.$t("normalView.Documents"), icon: 'mdi-note-multiple-outline', link: '/view/user/documents', id: "documents"},
      ],

      adminSideBarItems: [
        {
          title: this.$t("normalView.GroupAdmin"),
          icon: 'mdi-application-settings',
          link: '/view/admin/users',
          group_admin_only: true,
          items: [
            {title: this.$t("normalView.UserGroups"), icon: 'mdi-account-multiple', link: '/view/admin/user_groups'},
            {title: this.$t("normalView.EditMessages"), icon: 'mdi-message-plus', link: '/view/admin/messages'},
            {title: this.$t("normalView.EditCourses"), icon: 'mdi-playlist-plus', link: '/view/admin/courses'},
            {title: this.$t("normalView.FailedTests"), icon: 'mdi-bell-outline', link: '/view/admin/testfails'},
          ]
        },
        {
          title: this.$t("normalView.Admin"),
          icon: 'mdi-application-settings',
          link: '/view/admin/users',
          full_admin_only: true,
          items: [
            {title: this.$t("normalView.Users"), icon: 'mdi-account', link: '/view/admin/users'},
            {title: this.$t("normalView.UserGroups"), icon: 'mdi-account-multiple', link: '/view/admin/user_groups'},
            {title: this.$t("normalView.EditMessages"), icon: 'mdi-message-plus', link: '/view/admin/messages'},
            {title: this.$t("normalView.EditCourses"), icon: 'mdi-playlist-plus', link: '/view/admin/courses'},
            {title: this.$t("normalView.EditForms"), icon: 'mdi-file-plus', link: '/view/admin/forms'},
            {title: this.$t("normalView.EditDocuments"), icon: 'mdi-note-plus-outline', link: '/view/admin/documents'},
            {title: this.$t("normalView.MessageTypes"), icon: 'mdi-map-marker', link: '/view/admin/message_types'},
            {
              title: this.$t("normalView.CourseTypes"),
              icon: 'mdi-map-marker-outline',
              link: '/view/admin/course_types'
            },
            {title: this.$t("normalView.FailedTests"), icon: 'mdi-bell-outline', link: '/view/admin/testfails'},
          ],
        },
      ],
      drawer: null,

//region Search targets
      textToSearch: null,
      /** how much selected targets can be displayed.
       * If more targets are displayed,
       * they will be omitted and replaced by ...
       * */
      maxDisplayTargets: 1,
      /** All possible search targets */
      searchTargets: [
        this.$t("normalViewSearch.Messages"),
        this.$t("normalViewSearch.Courses"),
        this.$t("normalViewSearch.Documents"),
        this.$t("normalViewSearch.Files"),
      ],
      /** The search targets that should be used in search*/
      selectedSearchTargets: [],
//endregion Search targets

//region Search languages
      /** All possible search languages */
      searchLanguages: [
        {name: this.$t("normalViewSearch.Danish"), abbr: "dan"},
        {name: this.$t("normalViewSearch.English"), abbr: "eng"},
      ],
      /** how much selected languages can be displayed.
       * If more languages are displayed,
       * they will be omitted and replaced by ...
       * */
      maxDisplayLanguages: 1,
      /** The search languages that should be used in search*/
      selectedSearchLanguages: []
//endregion Search Languages
    }
  },

  mounted() {
    if (!this.$store.state.authentication.status.loggedIn) {
      this.$router.replace({ name: "login" });
    }

    //subscribe on events
    //there is corresponded unsubscribing code in beforeDestroy (!)
    this.$bus.$on("refreshCountUnprocessedItems",this.updateCountUnprocessedItems);
    this.$bus.$on("refreshListDocumentSubfolders",this.update_sideBarItems);

    // let vfact_script  = document.createElement('script');
    // document.head.appendChild(vfact_script);
    // vfact_script.setAttribute('src', this.get_vfact_client_script());
  },

  beforeDestroy() {
    //unsubscribe from the events (see mounted() for subscription code)
    this.updateCountUnprocessedItems && this.$bus.$off("refreshCountUnprocessedItems",this.updateCountUnprocessedItems);
    this.update_sideBarItems && this.$bus.$off("refreshListDocumentSubfolders",this.update_sideBarItems);
  },

  created() {
    this.updateCountUnprocessedItems();
    //by default: select all targets and select all languages
    this.toggle_search_languages();
    this.toggle_search_targets();
    this.update_sideBarItems();

    //#116: temporary workaround for vfact.api2
    //startleseweb is not called when the app is opened first time, but it's called after pressing F5
    //we check if vFact_audioFrame exists and if it doesn't we call startleseweb function manually
    const f = this.ensure_vFact_audioFrame_exists;
    setTimeout(
        function() {
          f()
        },
        3000
    );
  },

  computed: {
//region Search targets
    selectAllSearchTargets () {
      return this.selectedSearchTargets.length === this.searchTargets.length
    },
    selectSomeSearchTargets () {
      return this.selectedSearchTargets.length > 0 && !this.searchTargets
    },
    search_targets_icon () {
      if (this.selectAllSearchTargets) return 'mdi-close-box'
      if (this.selectSomeSearchTargets) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
//endregion Search targets

//region Search languages
    selectAllSearchLanguages () {
      return this.selectedSearchLanguages.length === this.searchLanguages.length
    },
    selectSomeSearchLanguages () {
      return this.selectedSearchLanguages.length > 0 && !this.searchLanguages
    },
    search_languages_icon () {
      if (this.selectAllSearchLanguages) return 'mdi-close-box'
      if (this.selectSomeSearchLanguages) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
//endregion Search languages
  },

  methods: {
//region Logout, app version
    logout() {
      this.$store.dispatch('authentication/logout').then(
          () => {
            this.$router.replace({ name: "login" });
          }
      )
    },

    get_app_version: function() {
      return this.$store.state.appVersion;
    },
//endregion Logout, app version

//region Sidebar menu for normal user, FA or GA
    // /**
    //  * @return {boolean}
    //  *    False if we are on mobile
    //  */
    // isDesktop: function() {
    //   return this.$vuetify.breakpoint.name !== "xs"
    //       && this.$vuetify.breakpoint.name !== "sm";
    // },

    /** If user clicks on not-expandable root menu item,
     * it's necessary to open the link specified in the properties of the menu item.
     * I.e.: messages, courses, dashboard */
    on_click_menu_item(item) {
      if (item.id) {
          this.$router.push({ path: item.link })
      }
    },

    is_sidebar_menu_available: function(item) {
      if (item.full_admin_only) {
        return this.isCurrentUserFA();
      } else if (item.group_admin_only) {
        return this.isCurrentUserGAandNotFA();
      } else {
        return true; //normal user
      }
    },
//endregion Sidebar menu for normal user, FA or GA

//region Current user rights
    getCurrentUserName: function() {
      const current_user = localStorage.getItem('user');
      return JSON.parse(current_user).displayName;
    },

    isCurrentUserFA: function() {
      let user = JSON.parse(localStorage.getItem('user'));
      return user.isFullAdmin;
    },

    isCurrentUserGAandNotFA: function() {
      let user = JSON.parse(localStorage.getItem('user'));
      return !user.isFullAdmin
          && user.gaUserGroupIds
          && user.gaUserGroupIds.length > 0;
    },
//endregion Current user rights

//region Unprocessed items
    getIconForUnreadMessages: function () {
      return this.get_numeric_icon(this.$store.state.storeUnreadItems.userCountUnreadMessages);
    },

    getIconForUndoneCourses: function() {
      return this.get_numeric_icon(this.$store.state.storeUnreadItems.userCountUndoneCourses);
    },

    /**
     * Get an icon to display specified count of unprocessed items
     * see
     *    https://pictogrammers.github.io/@mdi/font/2.0.46/
     *
     * there are several possible variants of the icons:
     *        mdi-numeric-1-box
     *        mdi-numeric-1-box-multiple-outline
     *        mdi-numeric-1-box-outline
     *
     * @param count
     *    count of unread messages/courses
     * @return {string}
     *    empty
     *    or an icon corresponded to the count
     */
    get_numeric_icon: function(count) {
      if (count > 0 && count < 10) {
        return `mdi-numeric-${count}-box`;
      } else if (count > 9) {
        return `mdi-numeric-9-plus-box`;
      }
    },

    /**
     * Update count of unprocessed messages and courses on left side bar
     *
     * TODO: each event receives an object as parameter with two possible properties:
     * {
     *    messages: true
     *    courses: true
     * }
     * we can use this parameter to get from the server only stat for messages or courses
     * and reduce number of the queries to DB
     **/
    updateCountUnprocessedItems() {
      userDashboardApi.getCountUnprocessedItemsForTheUser(this.$store.state, this.$store.dispatch).then(
          stat => {
            this.$store.commit("storeUnreadItems/setCountUnreadMessages", stat.countUnreadMessages);
            this.$store.commit("storeUnreadItems/setCountUndoneCourses", stat.countUndoneCourses);
          }
      );
    },
//endregion Unprocessed items

//region Search
    /**
     * Search targets: toggle All / None selected targets
     * */
    toggle_search_targets () {
      this.$nextTick(() => {
        if (this.selectAllSearchTargets) {
          this.selectedSearchTargets = []
        } else {
          this.selectedSearchTargets = this.searchTargets.slice()
        }
      })
    },

    /**
     * Search languages: toggle All / None selected languages
     * */
    toggle_search_languages () {
      this.$nextTick(() => {
        if (this.selectAllSearchLanguages) {
          this.selectedSearchLanguages = []
        } else {
          this.selectedSearchLanguages = this.searchLanguages.slice()
        }
      })
    },

    /**
     * Start search request
     * Route to searchPage, pass search parameters as query parameters
     * */
    make_search() {
      if (this.textToSearch && this.selectedSearchTargets.length > 0) {
        const query_params = {
          languages: this.selectedSearchLanguages.map(x => x.abbr).join(","),
          targets: this.selectedSearchTargets.join(","),
          text: this.textToSearch
        };

        if (this.$route.path !== '/view/user/search') {
          this.$router.push({path: "/view/user/search", query: query_params});
        } else {
          //we need to update URL - it should contains most recent search params
          //if user will click on i.e. document, the document will open
          //if now the user will press Back button, he should return to the page with current search results, not previous ones
          this.$router.replace({path: "/view/user/search", query: query_params});
          this.$bus.$emit("searchPageShouldMakeNewSearch", query_params);
        }
      }
    },

//endregion Search

//region #110 - Add Document-subfolders to user's menu
    /**
     * Remove from
     *    sideBarItems
     * all items with id
     *    documentXXX
     * Read list of subfolders directly inserted to Documents folder,
     * generate menu item for the each subfolder
     * and append the menu items to
     *    sideBarItems
     */
    update_sideBarItems() {
      foldersApi.getDocumentsFolder(this.$store.state
          , this.$store.dispatch
          , 0
          , false //adminMode
      ).then(
          archiveFolder => {    //ArchiveFolder<ArchiveFile>

            //remove all documentXXX items from sideBarItems
            this.sideBarItems = this.sideBarItems.filter(x => !x.id.startsWith("documents"));

            //list of subfolders directly included to the folder Documents
            //each item is a json generated by treeDocumentsUtils.getFolderItem
            const items = treeDocumentsUtils.getChildren(archiveFolder.subfolders, []);
            items.sort(function (a, b) {
              //https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare#browser_compatibility
              //correct order: abcdefghijklmnopqrstuvwxyzæøå
              //aa means å

              const a_title = treeDocumentsUtils.getItemTitle(a);
              const b_title = treeDocumentsUtils.getItemTitle(b);
              const a_sortorder = treeDocumentsUtils.getItemSortorder(a);
              const b_sortorder = treeDocumentsUtils.getItemSortorder(b);

              return compareUtils.compareBySortorderAndTitle(a_sortorder, a_title, b_sortorder, b_title);
              //return a.data.title.localeCompare(b.data.title, 'da', {sensitivity: 'variant'});
            });

            for (const item of items) {
              this.sideBarItems.push({
                title: item.data.title
                , icon: 'mdi-note-multiple-outline'
                , link: `/view/user/documents/openFolder/${item.data.folderId}`
                , id: `documents${item.data.folderId}`
              });
            }

            this.currentFolder = archiveFolder.data;
          }
      ).catch(error => {
        showHttpErrorsInToasts(this, error);
      });
    },

//endregion #110 - Add Document-subfolders to user's menu

//region VFact support
    play_vfact() {
      if (window.vFact3) {
        window.vFact3.play();
      } else if (window.vFact_doplay) {
        this.ensure_vFact_audioFrame_exists();
        window.vFact_doplay();
      }
    },
    stop_vfact() {
      if (window.vFact3) {
        window.vFact3.stop();
      } else if (window.vFact_dostop) {
        this.ensure_vFact_audioFrame_exists();
        window.vFact_dostop();
      }
    },
    config_vfact() {
      if (window.vFact3) {
        window.vFact3.showConfig();
      } else if (window.vFact_showconfigbox) {
        this.ensure_vFact_audioFrame_exists();
        window.vFact_showconfigbox();
      }
    },
    /** Locally we use http:
     * but in staging and in production
     * we should use https: only
     * */
    get_vfact_client_script() {
      return this.$store.state.apiUrl === 'https://localhost:5001'
        ? "http://speech.leseweb.dk/script/8tn768i3er9a3jt5v8gu.js"
        : "https://speech.leseweb.dk/script/8tn768i3er9a3jt5v8gu.js";
    },

    /**
     * #116: Workaround: startleseweb is not called jsut after opening the app, but it works after pressing F5
     * */
    ensure_vFact_audioFrame_exists() {
      if (! window.document.getElementById("vFact_audioFrame")) {
        if (window.startleseweb) {
          window.startleseweb();
        }
      }
    }

    // help_vfact() {
    //   window.open("http://help.leseweb.dk/?id=dk", "winhelp");
    // }
//endregion VFact support
  }
}
</script>

<style scoped>
.search-bar-container {
  margin-top: 10px;
}
.search-bar-column {

}
</style>