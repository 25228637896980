import {handleResponse_401_Unauthorized} from "@/helpers/handle401";
import {getAuthorizationHeader} from "@/helpers/auth-header";
import {getUrlWithParams} from "@/helpers/url_utils";

export const adminMessagesApi = {
    getListMessages,
    getEditMessageEx,

    createMessage,
    deleteMessage,

    updateMessage,

    getStatByUsers,
};

//region Get
/**
 * Get list of messages of specified type
 * @param rootStoreState
 * @param storeDispatch
 * @param mctypeId
 *      message type, a value of mctypes.mctype_id
 * @param paginationOptions
 *      optional JSON with set of (optional) pagination options
 *      paginationOptions: {
 *           page: 1,
 *           pageCount: 0,
 *           itemsPerPage: 15,
 *           sortBy: "data.messageId",
 *           sortDesc: true
 *     },
 * @return {Promise<Response>}
 *      function returns a promise that returns JSON with follow data:
 *          {
 *              paginationOptions  - current set of the pagination options
 *              , items - list of the messages on the currently selected page
 *          }
 */
function getListMessages(rootStoreState, storeDispatch, mctypeId, paginationOptions) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = getUrlWithParams(`${rootStoreState.apiUrl}/api/admin/messages/list/${mctypeId}`, paginationOptions);

    //console.log("url", url);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get MessageEditEx for specified message
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param messageId
 * @return {Promise<Response>}
 */
function getEditMessageEx(rootStoreState, storeDispatch, messageId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/messages/edit/${messageId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Get

//region Create, delete message
/**
 * Create new message
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel {object}
 *      see FaMessagesController.InputModelCreate:
 *      {
 *          messageTitle,
 *          flags,
 *          messageBody,
 *          mctypeId
 *      }
 */
function createMessage(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/messages`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

function deleteMessage(rootStoreState, storeDispatch, messageId) {
    const requestOptions = {
        method: 'DELETE',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/messages/${messageId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Create, delete message

//region Update
/**
 * Update message title
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param updateModel
 *      see MessagesAppController.InputModelUpdate
 *           {
 *               messageId,
 *               messageTitle,
 *               recipientsUserIds,
 *               recipientsUserGroupIds,
 *               attachedFileIds,
 *               messageBody,
 *               messageBodyFormat,
 *               isPublished
 *           }
 */
function updateMessage(rootStoreState, storeDispatch, updateModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(updateModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/messages/update`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

//endregion Update

//region GetStatByUsers
/**
 * Get stat for the course by users
 * @param rootStoreState
 * @param storeDispatch
 * @param messageId
 * @return {Promise<Response>}
 *      promise returns array of CourseStatusByUser
 */
function getStatByUsers(rootStoreState, storeDispatch, messageId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/messages/stat/${messageId}`;

    //console.log("url", url);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion GetStatByUsers