import { loginApi } from '@/api';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null };

/**
 * Authentication: login, logout
 */
export const authentication = {
    /** All of its getters, actions and mutations (but not state properties) are automatically namespaced based on the path the module is registered at
     *  https://vuex.vuejs.org/guide/modules.html#module-local-state */
    namespaced: true,
    state: initialState,
    actions: {
        /**
         * Login, save user info to the local storage, return the user object
         * @param dispatch
         * @param commit
         * @param rootState
         * @param username
         * @param password
         * @return {Promise<user object>}
         */
        login({ dispatch, commit, rootState }, { username, password }) {
            return new Promise(function (resolve, reject) {
                commit('loginRequest', { username });

                loginApi.login(rootState, dispatch, username, password)
                    .then(
                        user => {
                            commit('loginSuccess', user);
                            resolve(user);
                        },
                        error => {
                            commit('loginFailure', error);
                            reject(error);
                        }
                    );
            })
        },

        /**
         * Logout - clear info about logged user in the local storage
         * @param commit
         * @return {Promise<unknown>}
         */
        logout({ commit }) {
            return new Promise(function(resolve) {
                loginApi.logout();
                commit('logout');
                resolve();
            });
        }
    },
    mutations: {
        /** Attempt to login is made, user has specified user name */
        loginRequest(state, user) {
            state.status = { loggingIn: true };
            state.user = user;
        },
        /** Attempt to login was successfully, we need to store logged user details (=== AuthenticateResponse) */
        loginSuccess(state, user) {
            state.status = { loggedIn: true };
            state.user = user;
        },
        /** Fail to login. Clear the state*/
        loginFailure(state) {
            state.status = {};
            state.user = null;
        },
        /** Logout. Clear the state*/
        logout(state) {
            state.status = {};
            state.user = null;
        }
    }
}
