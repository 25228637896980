<template>
<div>
  <v-toolbar flat>
    <v-toolbar-title>{{$t("dashboard.Dashboard")}}</v-toolbar-title>

    <v-spacer></v-spacer>

  </v-toolbar>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
<!-- list of messages -->
        <v-data-table
            :headers="headers"
            :items="dashboardData.messageMctypes"
            item-key="mctypeId"
            class="elevation-1"
            @click:row="handleMessageClick"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{$t("dashboard.Messages")}}</v-toolbar-title>

              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>

            </v-toolbar>
          </template>

      <!-- Highlight all columns by BOLD for all unread items -->
          <template v-slot:item.mctypeTitle ="{ item }">
            <div class="d-flex justify-left"
                 :class="{'font-weight-bold' : item.countUndoneItems > 0}"
            >
              {{item.mctypeTitle}}
            </div>
          </template>

          <template v-slot:item.totalCountItems ="{ item }">
            <div class="d-flex justify-left"
                 :class="{'font-weight-bold' : item.countUndoneItems > 0}"
            >
              {{item.totalCountItems}}
            </div>
          </template>

          <template v-slot:item.countUndoneItems ="{ item }">
            <div class="d-flex justify-left"
                 :class="{'font-weight-bold' : item.countUndoneItems > 0}"
            >
              {{item.countUndoneItems}}
            </div>
          </template>

          <template v-slot:item.title ="{ item }">
            <div class="d-flex justify-left"
                 :class="{'font-weight-bold' : item.countUndoneItems > 0}"
            >
              {{item.title}}
            </div>
          </template>
        </v-data-table>
      </v-col>

<!-- list of courses -->
      <v-col cols="12" md="6">
        <v-data-table
            :headers="headers"
            :items="dashboardData.courseMctypes"
            item-key="mctypeId"
            class="elevation-1"
            @click:row="handleCourseClick"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{$t("dashboard.Courses")}}</v-toolbar-title>

              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>

            </v-toolbar>
          </template>

          <!-- Highlight all columns by BOLD for all unread items -->
          <template v-slot:item.mctypeTitle ="{ item }">
            <div class="d-flex justify-left"
                 :class="{'font-weight-bold' : item.countUndoneItems > 0}"
            >
             {{item.mctypeTitle}}
            </div>
          </template>

          <template v-slot:item.totalCountItems ="{ item }">
            <div class="d-flex justify-left"
                 :class="{'font-weight-bold' : item.countUndoneItems > 0}"
            >
              {{item.totalCountItems}}
            </div>
          </template>

          <template v-slot:item.countUndoneItems ="{ item }">
            <div class="d-flex justify-left"
                 :class="{'font-weight-bold' : item.countUndoneItems > 0}"
            >
              {{item.countUndoneItems}}
            </div>
          </template>

          <template v-slot:item.title ="{ item }">
            <div class="d-flex justify-left"
                 :class="{'font-weight-bold' : item.countUndoneItems > 0}"
            >
              {{item.title}}
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>

import {userDashboardApi} from "@/api/user.dashboard.api";

export default {
  name: 'userDashboardPage',

  props: [
  ],

  data: function() {
    return {
      /** DashboardUserView from the server */
      dashboardData: {
        messageMctypes: [],
        courseMctypes: []
      },

      /** Columns of the list of mctypes */
      headers: [
        // {
        //   text: this.$t("dashboard.header.Id"),
        //   align: 'start',
        //   value: 'mctypeId',
        //   max_width: "80"
        // },
        {
          text: this.$t("dashboard.header.Title"),
          value: 'mctypeTitle',
        },
        {
          text: this.$t("dashboard.header.Active"),
          value: 'countUndoneItems',
          max_width: "100"
        },
        {
          text: this.$t("dashboard.header.Total"),
          value: 'totalCountItems',
          max_width: "100"
        },
      ],

      showUnreadOnly: false,
    }
  },

  created() {
    userDashboardApi.getDashboardInfo(this.$store.state, this.$store.dispatch).then(
        data => {
          this.dashboardData = data;
        }
    );
  },

  methods: {
    /** User has clicked on message-mctype line - we need to open list of the messages of the selected mctype */
    handleMessageClick: function(item) {
        this.$router.push({
        path: `/view/user/messages/${item.mctypeId}`
      });
    },

    /** User has clicked on course-mctype line - we need to open list of the courses of the selected mctype */
    handleCourseClick: function(item) {
      this.$router.push({
        path: `/view/user/courses/${item.mctypeId}`
      });
    },

  },
}
</script>

<style scoped>
</style>