var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listItems,"_hide-default-footer":"","item-key":"data.testfail.testfailId","options":_vm.paginationOptions,"server-items-length":_vm.totalCountItems,"loading":_vm.loading},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("adminTestfails.FormTitle"))+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-switch',{staticClass:"mx-4",attrs:{"label":_vm.$t('adminTestfails.ShowReopened'),"inset":"","false-value":false,"true-value":true},model:{value:(_vm.showReopenedTests),callback:function ($$v) {_vm.showReopenedTests=$$v},expression:"showReopenedTests"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!item.data.isTestfailReopened),expression:"!item.data.isTestfailReopened"}],attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.reopenItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-undo-variant ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("adminTestfails.tooltip.Reopen"))+" ")])]),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(!item.data.isTestfailReopened),expression:"!item.data.isTestfailReopened"}],staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('router-link',{attrs:{"to":("/view/admin/testfails/" + (item.data.testfail.testfailId) + "/view_results"),"tag":"button"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("adminTestfails.tooltip.View"))+" ")])])],1)],1)]}},{key:"item.data.testfail.permittedAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.data.testfail.permittedAt ? _vm.dt2str(item.data.testfail.permittedAt) : "")+" ")])]}},{key:"item.data.testfail.createdAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.dt2str(item.data.testfail.createdAt))+" ")])]}},{key:"item.data.testTitle",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(("[" + (item.data.testId) + "] " + (item.data.testTitle)))+" ")])]}},{key:"item.data.courseTitle",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(("[" + (item.data.testfail.courseId) + "] " + (item.data.courseTitle)))+" ")])]}}])}),_c('v-dialog',{attrs:{"max-width":"520px"},model:{value:(_vm.dialogReopen),callback:function ($$v) {_vm.dialogReopen=$$v},expression:"dialogReopen"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t("adminTestfails.dialog.Confirm"))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeReopenDialog}},[_vm._v(" "+_vm._s(_vm.$t("button.Cancel"))+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.reopenItemConfirm}},[_vm._v(" "+_vm._s(_vm.$t("button.Ok"))+" ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }