/** Make deep copy of object
 *          item
 *  Calling Object.assign({}, item)
 *  is not enough if
 *          item
 *  contains embedded objects
 **/
export function deepClone(item) {
    return JSON.parse(JSON.stringify(item));
}

export function getDateAsString(date) {
    return date.toISOString();
}

/**
 * Convert date
 *      2021-04-09T14:48:34
 * to
 *      DD-MM-YYYY HH-MM
 * @param sharpDate
 *      2021-04-09T14:48:34
 */
export function convertIsoDateToDisplayDate(sharpDate) {
    try {
        const dt = new Date(Date.parse(sharpDate));

        //https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
        const year = dt.getFullYear().toString();
        const month = (dt.getMonth() + 101).toString().substring(1);
        const day = (dt.getDate() + 100).toString().substring(1);

        const hours = (dt.getHours() + 100).toString().substring(1);
        const minutes = (dt.getMinutes() + 100).toString().substring(1);
        return day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    } catch {
        return sharpDate;
    }
}