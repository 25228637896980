var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.searchResults.searchItems,"hide-default-footer":"","item-key":"contentUid","show-expand":"","expanded":_vm.expanded,"loading":_vm.loading},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.loading ? _vm.$t("searchPage.Searching") : ((_vm.$t("searchPage.SearchResults")) + " ~" + (_vm.searchResults.totalCount)))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":_vm.loading || _vm.currentPageIndex <= 1},on:{"click":function($event){return _vm.changePage(false)}}},[_vm._v(" "+_vm._s(_vm.$t("searchPage.PrevPage"))+" ")]),_vm._v(" "+_vm._s(_vm.currentPageIndex)+" "),_c('v-btn',{staticClass:"ma-2",attrs:{"disabled":_vm.loading || _vm.searchResults.noMoreResults},on:{"click":function($event){return _vm.changePage(true)}}},[_vm._v(" "+_vm._s(_vm.$t("searchPage.NextPage"))+" ")])],1)]},proxy:true},{key:"item.dateCreation",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left",class:{'font-weight-bold' : !item.isDone}},[_vm._v(" "+_vm._s(item.dateCreation ? _vm.dt2str(item.dateCreation) : "")+" ")])]}},{key:"item.itemKind",fn:function(ref){
var item = ref.item;
return [(item.itemKind === 0x1)?_c('v-chip',{staticClass:"ma-0",attrs:{"color":"orange"}},[_vm._v(_vm._s(_vm.$t("searchPage.Message")))]):(item.itemKind === 0x2)?_c('v-chip',{staticClass:"ma-0",attrs:{"color":"blue"}},[_vm._v(_vm._s(_vm.$t("searchPage.Course")))]):(item.itemKind === 0x4)?_c('v-chip',{staticClass:"ma-0",attrs:{"color":"green"}},[_vm._v(_vm._s(_vm.$t("searchPage.Document")))]):(item.itemKind === 0x8)?_c('v-chip',{staticClass:"ma-0",attrs:{"color":"gray"}},[_vm._v(_vm._s(_vm.$t("searchPage.File")))]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"highlight_details",domProps:{"innerHTML":_vm._s(_vm.get_html(item.highlightedContent))}})])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }