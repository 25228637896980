/**
 * return authorization header with jwt token
 * @return {{Authorization: string}|{}}
 */
export function getAuthorizationHeader() {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        return { 'Authorization': 'Bearer ' + user.token };
    } else {
        return {};
    }
}