import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
//import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// We use default Material Design Icons library
//      https://v15.vuetifyjs.com/ru/framework/icons/
// Full list of icons is here
//      https://materialdesignicons.com/
// In the app we should use icon name as
//          mdi-[icon-name]
// The correctness of the icon name can be checked here
//     https://vuetifyjs.com/en/features/icon-fonts/#installing-icon-fonts
// Example
//      settings
//      We get name "ogg" from https://materialdesignicons.com/ for the settings
//      and ensure that "mdi-ogg" works fine by entering this name in the edit box
//      in https://vuetifyjs.com/en/features/icon-fonts/#installing-icon-fonts

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // 'md' || 'mdi' || 'fa' || 'fa4' // default - only for display purposes
    },
});
