import {handleResponse_401_Unauthorized} from "@/helpers/handle401";
import {getAuthorizationHeader} from "@/helpers/auth-header";
import {getUrlWithParams} from "@/helpers/url_utils";

export const adminUserGroupsApi = {
    getUserGroup
    , getListUserGroups
    , getListUserGroupsViewEx

    , createUserGroup
    , updateUserGroup
    , deleteUserGroup

    , changeMembers
    , getMembers
    , changeGA
    , getGroupAdmins

    , getStatByMessages
    , getStatByCourses
};

//region Get user groups

/**
 * Get selected user group - UserGroupEx
 * @param rootStoreState
 * @param storeDispatch
 * @param userGroupId
 * @return {Promise<Response>}
 */
function getUserGroup(rootStoreState, storeDispatch, userGroupId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/usergroups/${userGroupId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get list of registered user groups (UserGroup)
 * @param rootStoreState
 * @param storeDispatch
 * @return {Promise<Response>}
 */
function getListUserGroups(rootStoreState, storeDispatch) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/usergroups/list`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get list of registered user groups (UserGroupView)
 * @param rootStoreState
 * @param storeDispatch
 * @return {Promise<Response>}
 */
function getListUserGroupsViewEx(rootStoreState, storeDispatch) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/usergroups/listex`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

//endregion Get user groups

//region Create, update, delete user groups
/**
 * Register new user group
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel {object}
 *      see FaUserGroupsController.InputModelCreate:
 *      {
 *          title,
 *          description,
 *          flags
 *      }
 */
function createUserGroup(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/usergroups`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Update user group
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param updateModel
 *      see FaUserGroupsController.InputModelUpdate
 *           {
 *               userGroupId,
 *               title,
 *               description,
 *               flags
 *           }
 *      UpdateMask can contain follow flags (see UserUpdate.tuser_ufields):
 *          TITLE = 0x1,
 *          DISABLED = 0x2
 */
function updateUserGroup(rootStoreState, storeDispatch, updateModel) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(updateModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/usergroups`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

function deleteUserGroup(rootStoreState, storeDispatch, userGroupId) {
    const requestOptions = {
        method: 'DELETE',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/usergroups/${userGroupId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Create, update, delete user groups

//region Members

/**
 * Get list of user group members
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param userGroupId
 * @return {Promise<Response>}
 *      array of DbUser
 */
function getMembers(rootStoreState, storeDispatch, userGroupId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/usergroups/members/${userGroupId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel
 *      InputModelChangeMembers = {
 *          userGroupId,  //target user_group_id
 *          userIdsToAdd,  //array of user_id to add to the group
 *          userIdsToRemove //array of user_id to revoke from the group
 *      }
 * @return {Promise<*>}
 */
function changeMembers(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/usergroups/members`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Members

//region GA
/**
 * Grand/revoke GA-rights to specified users
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel
 *      FaUserRolesController.InputModel
 * @return {Promise<*>}
 */
function changeGA(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/roles`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get list of registered user groups
 * @param rootStoreState
 * @param storeDispatch
 * @param userGroupId
 * @return {Promise<Response>}
 */
function getGroupAdmins(rootStoreState, storeDispatch, userGroupId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/roles/list/${userGroupId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

//endregion GA

//region GetStatByMessages/Courses
/**
 * Get list of messages assigned to the user group
 * @param rootStoreState
 * @param storeDispatch
 * @param userGroupId
 * @param paginationOptions
 *      optional JSON with set of (optional) pagination options
 *      paginationOptions: {
 *           page: 1,
 *           pageCount: 0,
 *           itemsPerPage: 15,
 *           sortBy: "data.userId",
 *           sortDesc: true
 *     },
 * @return {Promise<Response>}
 */
function getStatByMessages(rootStoreState, storeDispatch, userGroupId, paginationOptions) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = getUrlWithParams(`${rootStoreState.apiUrl}/api/admin/usergroups/${userGroupId}/stat/messages`, paginationOptions);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get list of courses assigned to the user group
 * @param rootStoreState
 * @param storeDispatch
 * @param userGroupId
 * @param paginationOptions
 *      optional JSON with set of (optional) pagination options
 *      paginationOptions: {
 *           page: 1,
 *           pageCount: 0,
 *           itemsPerPage: 15,
 *           sortBy: "data.userId",
 *           sortDesc: true
 *     },
 * @return {Promise<Response>}
 */
function getStatByCourses(rootStoreState, storeDispatch, userGroupId, paginationOptions) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = getUrlWithParams(`${rootStoreState.apiUrl}/api/admin/usergroups/${userGroupId}/stat/courses`, paginationOptions);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion GetStatByMessages/Courses