<!--
Show course page for the user.
- course description
- list of course parts and their statuses
-->
<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-toolbar flat>
            <v-toolbar-title>{{$t("userCoursesViewer.FormTitle")}}</v-toolbar-title>
            <v-chip class="ma-2"
                    color="warning"
                    v-if="!courseData.isDone"
            >{{$t("userCoursesViewer.Unpassed")}}</v-chip>
            <v-chip class="ma-2"
                    color="success"
                    v-if="courseData.isDone"
            >{{$t("userCoursesViewer.Passed")}}</v-chip>
            <v-chip class="ma-2"
                    color="success"
                    v-if="courseData.isDone"
            >{{courseData.countCompletedParts}}/{{courseData.parts.length}}</v-chip>

            <v-spacer></v-spacer>
<!-- Start -->
            <v-btn
                color="primary"
                class="ma-2"
                @click="start_next_part()"
                :disabled="courseData.isDone || isAnyPartDone"
            >
              {{$t("userCoursesViewer.Start")}}
            </v-btn>
<!-- Continue -->
            <v-btn
                color="secondary"
                class="ma-2"
                @click="start_next_part()"
                :disabled="courseData.isDone || !isAnyPartDone"
            >
              {{$t("userCoursesViewer.Continue")}}
            </v-btn>

            <v-btn
                class="ma-2"
                @click="close"
            >
              {{$t("userCoursesViewer.Close")}}
            </v-btn>

          </v-toolbar>
        </v-col>
      </v-row>
<!-- Course ID, from-name -->
      <v-row>
        <v-col>
          {{$t("userCoursesViewer.Author")}}: {{courseData.createdByUserName}}
        </v-col>
        <v-col>
          {{$t("userCoursesViewer.Type")}}: {{courseData.courseTypeTitle}}
        </v-col>
        <v-col>
          {{$t("userCoursesViewer.PublishedAt")}}: {{courseData.publishedAt ? dt2str(courseData.publishedAt) : ""}}
        </v-col>
      </v-row>

<!-- Course title -->
      <v-row>
        <v-col>
          <v-text-field v-model="courseData.title"
                        :label="$t('userCoursesViewer.CourseTitle')"
                        readonly
          ></v-text-field>
        </v-col>
      </v-row>

<!-- Course body -->
      <v-row>
        <v-col>
          <template>
            <div id="course_body">
              <v-subheader class="pa-0">
                {{$t("userCoursesViewer.CourseDescription")}}
              </v-subheader>
              <div v-html="courseData.bodyCard">
              </div>
            </div>
          </template>
        </v-col>
      </v-row>

<!-- List of course parts with current status -->
      <v-row>
        <v-col>
          <v-subheader class="pa-0">
            {{$t("userCoursesViewer.CourseParts")}}
          </v-subheader>
          <v-data-table
              :headers="headers"
              :items="courseData.parts"
              _hide-default-footer
              item-key="courseId"
              class="elevation-1"
              @click:row="handleClick"
          >
<!-- Show icon status: border - availability, background - status
  Border: R - not available, G - available
  Background: W - not started, R - failed, G - success, Y - partial success (i.e. test is not passed)
-->
            <template v-slot:item.status="{ item }">
              <div class="d-flex justify-center">
                <v-icon
                    small
                    :color="item.isDone ? 'green' : 'red'"
                >
                  {{item.isDone ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle-outline" }}
                </v-icon>
              </div>
            </template>

<!-- Highlight all columns by BOLD for all unread items -->
            <template v-slot:item.courseId ="{ item }">
              <div class="d-flex justify-left"
                   :class="{'font-weight-bold' : item.isPartOpened}"
              >
                {{item.part.orderIndex}}
              </div>
            </template>

            <template v-slot:item.final_test ="{ item }">
              <div class="d-flex justify-center"
                   :class="{'font-weight-bold' : item.isPartOpened, 'error': item.isTestFailedAndForbidden}"
              >
                {{item.hasTest
                    ? item.isDone
                      ? $t("userCoursesViewer.status.Passed")
                      : item.isTestFailedAndForbidden
                          ? $t("userCoursesViewer.status.Forbidden")
                          : item.IsPartOpened
                            ? $t("userCoursesViewer.status.ReadyToPass")
                            : $t("userCoursesViewer.status.ToDo")
                    : "-"
                }}
              </div>
            </template>

            <!-- Show title + icon of ATTACHED file exists -->
            <template v-slot:item.title ="{ item }">
              <div class="d-flex justify-left"
                   :class="{'font-weight-bold' : item.isPartOpened}"
              >
                {{item.part.title}}
                <v-spacer></v-spacer>
              </div>
            </template>

<!-- Actions -->
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-center">
                <!-- Open the part -->
                <router-link :to="'/view/user/parts/' + item.part.partId + '/view'"
                             tag="button"
                             v-if="item.isPartOpened"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          small
                          v-bind="attrs"
                          v-on="on"
                          color="primary"
                      >
                        mdi-play
                      </v-icon>
                    </template>
                    <span>{{$t("userCoursesViewer.OpenPart")}}</span>
                  </v-tooltip>
                </router-link>
              </div>
            </template>
          </v-data-table>

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {userCoursesApi as userCoursesApi} from "@/api/user.courses.api";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";

export default {
  name: "userCoursesPageViewer",
  props: [
  ],

  data: function() {
    return {
      /**  All data of the course, CourseUserViewEx */
      courseData: {
        parts: []
      },

      /** Is any part finished or not.
       * If it's finished, user should Start the course, otherwise he should Continue the course
       **/
      isAnyPartDone: false,

      /** Columns of the list of parts */
      headers: [
        {
          text: this.$t("userCoursesViewer.header.Number"),
          align: 'start',
          value: 'courseId',
          width: "80"
        },
        {
          text: this.$t("userCoursesViewer.header.Status"),
          value: 'status',
          width: "100"
        },
        {
          text: this.$t("userCoursesViewer.header.FinalTest"),
          value: 'final_test',
          width: "100"
        },
        {
          text: this.$t("userCoursesViewer.header.Title"),
          value: 'title'
        },
        {
          text: this.$t("userCoursesViewer.header.Actions"),
          value: 'actions',
          align: 'center',
          sortable: false,
          width: "160"
        },
      ],
    }
  },

  created() {
    // load courseData data
    userCoursesApi.getCourseViewEx(this.$store.state, this.$store.dispatch, this.$route.params.id).then(
        m => {
          this.courseData = m;
          if (this.courseData && this.courseData.parts && this.courseData.parts.length > 0) {
            this.isAnyPartDone = this.courseData.parts.filter(x => x.isDone).length > 0;
          }
        },
        error => showHttpErrorsInToasts(this, error)
    );
  },

  methods: {
    /** Save all course data and optionally publish the course */
    close: function() {
      this.$router.push({ path: `/view/user/courses` })
    },

    /** Continue to pass next available not-done part*/
    start_next_part: function() {
      //let's find first available not-done part and open it
      for (var i = 0; i < this.courseData.parts.length; ++i) {
        if (! this.courseData.parts[i].isDone && this.courseData.parts[i].isPartOpened) {
          this.$router.push({ path: '/view/user/parts/' + this.courseData.parts[i].part.partId + '/view' });
          break;
        }
      }
    },

    /** User has clicked on the item of the table */
    handleClick: function(item) {
      if (item.isPartOpened) {
        this.$router.push({
          path: '/view/user/parts/' + item.part.partId + '/view'
        });
      }
    },

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },

  }
}
</script>

<style scoped>
/**
DOM content created with v-html are not affected by scoped styles, but you can still style them using deep selectors.
https://vue-loader-v14.vuejs.org/en/features/scoped-css.html
*/

div >>> #course_body img
{
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}


</style>