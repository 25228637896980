var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","items-per-page":-1},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-breadcrumbs',{staticClass:"ma-0 pa-0",attrs:{"items":_vm.path2folder,"divider":"-"}}),_c('v-spacer'),(_vm.adminMode)?_c('v-btn',{staticClass:"ma-2",attrs:{"dark":""},on:{"click":_vm.newFolder}},[_vm._v(_vm._s(_vm.$t("documentsListView.button.NewFolder")))]):_vm._e(),(_vm.adminMode)?_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","disabled":_vm.is_current_folder_most_top()},on:{"click":_vm.createNewDocument}},[_vm._v(" "+_vm._s(_vm.$t("documentsListView.button.NewDocument"))+" ")]):_vm._e()],1)]},proxy:true},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[(item.isFolder)?_c('v-icon',{on:{"click":function($event){return _vm.openItem(item)}}},[_vm._v(" mdi-folder ")]):_c('v-icon',{on:{"click":function($event){return _vm.openItem(item)}}},[_vm._v(" mdi-note-outline ")])],1)]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(_vm.get_item_title(item))+" ")])]}},{key:"item.createdByUserName",fn:function(ref){
var item = ref.item;
return [(!item.isFolder)?_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.data.createdByUserName)+" ")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [(!item.isFolder)?_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(_vm.dt2str(item.data.createdAt))+" ")]):_vm._e()]}},{key:"item.publishedAt",fn:function(ref){
var item = ref.item;
return [(!item.isFolder)?_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.data.publishedAt ? _vm.dt2str(item.data.publishedAt) : _vm.$t("label.NotPublished"))+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[(_vm.adminMode && item.isFolder && !item.isPathToRoot)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editFolder(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("documentsListView.tooltip.rename")))])]):_vm._e(),(!item.isFolder)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.view_document(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("documentsListView.tooltip.view")))])]):_vm._e(),(_vm.adminMode && !item.isFolder)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("documentsListView.tooltip.edit")))])]):_vm._e(),(_vm.adminMode &&!item.isPathToRoot)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("documentsListView.tooltip.delete")))])]):_vm._e()],1)]}}])}),_c('dialog-edit-folder',{ref:"refDialogEditFolder",on:{"folder:save":_vm.saveFolderDialog}}),_c('dialog-delete-confirmation',{ref:"refDialogDeleteConfirmation",on:{"deletion:confirmed":_vm.deleteItemConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }