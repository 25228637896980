<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="listItems"
        _hide-default-footer
        item-key="data.testfail.testfailId"
        class="elevation-1"
        :options.sync="paginationOptions"
        :server-items-length="totalCountItems"
        :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            {{$t("adminTestfails.FormTitle")}}
          </v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>

          <!-- Switcher to view all/not-reopened testfails -->
          <v-switch
              v-model="showReopenedTests"
              :label="$t('adminTestfails.ShowReopened')"
              inset
              class="mx-4"
              :false-value=false
              :true-value=true
          ></v-switch>
        </v-toolbar>
      </template>

<!-- actions column: reopen test -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  @click="reopenItem(item)"
                  v-show="!item.data.isTestfailReopened"
              >
                mdi-undo-variant
              </v-icon>
            </template>
            <span>
              {{$t("adminTestfails.tooltip.Reopen")}}
            </span>
          </v-tooltip>

          <v-divider
              class="mx-2"
              inset
              vertical
              v-show="!item.data.isTestfailReopened"
          ></v-divider>

          <router-link :to="`/view/admin/testfails/${item.data.testfail.testfailId}/view_results`"
                       tag="button"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>
                {{$t("adminTestfails.tooltip.View")}}
              </span>
            </v-tooltip>
          </router-link>
        </div>
      </template>

      <template v-slot:item.data.testfail.permittedAt="{ item }">
        <div class="d-flex justify-center">
          {{ item.data.testfail.permittedAt
              ? dt2str(item.data.testfail.permittedAt)
              : ""
          }}
        </div>
      </template>

      <template v-slot:item.data.testfail.createdAt="{ item }">
        <div class="d-flex justify-center">
          {{ dt2str(item.data.testfail.createdAt) }}
        </div>
      </template>

      <template v-slot:item.data.testTitle="{ item }">
        <div class="d-flex justify-center">
          {{ `[${item.data.testId}] ${item.data.testTitle}` }}
        </div>
      </template>

      <template v-slot:item.data.courseTitle="{ item }">
        <div class="d-flex justify-center">
          {{ `[${item.data.testfail.courseId}] ${item.data.courseTitle}` }}
        </div>
      </template>
    </v-data-table>

    <!-- a dialog to confirm reopening of the test -->
    <v-dialog v-model="dialogReopen"
              max-width="520px"
    >
      <v-card>
        <v-card-title class="headline">
          {{$t("adminTestfails.dialog.Confirm")}}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeReopenDialog">
            {{$t("button.Cancel")}}
          </v-btn>
          <v-btn color="blue darken-1" text @click="reopenItemConfirm">
            {{$t("button.Ok")}}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {debounce} from "lodash";
import {adminTestfailsApi} from "@/api/admin.testfails.api";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";

export default {
  name: "adminTestfailsPage",
  props: [
  ],

  data: function() {
    return {
      /** Kind of the list of testfails:
       * 0: list of new (not reopened) testfailes only
       * 1: full list of testfails
       **/
      showReopenedTests: false,

      /** Main list of testfails loaded from the server, TestfailView*/
      listItems: [],

      /** Columns of the list of DB-users */
      headers: [
        {
          text: this.$t("adminTestfails.headers.Id"),
          align: 'start',
          value: 'data.testfail.testfailId',
          width: 80,
        },
        {
          text: this.$t("adminTestfails.headers.FailedAt"),
          value: 'data.testfail.createdAt',
          width: 150,
          align: 'center'
        },
        {
          text: this.$t("adminTestfails.headers.User"),
          value: 'user.title',
        },
        {
          text: this.$t("adminTestfails.headers.Course"),
          value: 'data.courseTitle',
          align: 'center'
        },
        {
          text: this.$t("adminTestfails.headers.Test"),
          value: 'data.testTitle',
          align: 'center'
        },
        {
          text: this.$t("adminTestfails.headers.ReopenedBy"),
          value: 'permittedBy.title',
        },
        {
          text: this.$t("adminTestfails.headers.ReopenedAt"),
          value: 'data.testfail.permittedAt',
          width: 150,
          align: 'center'
        },
        {
          text: this.$t("adminTestfails.headers.Actions"),
          value: 'actions',
          align: 'center',
          sortable: false,
          width: 160
        },
      ],

      /**
       * Current pagination settings
       *
       * Server side pagination is used
       * see: https://vuetifyjs.com/en/api/v-data-footer/#props
       * */
      paginationOptions: {
        sortDesc: [true],
        sortBy: ["data.testfail.testfailId"]
      },
      totalCountItems: 0,

      /** the data is loading from the server */
      loading: false,

      /** show test-reopen-confirmation dialog*/
      dialogReopen: false,

      itemToReopenIndex: -1,
    }
  },

  watch: {
    /** When user changes a list kind, we need to reload list of the testfails */
    showReopenedTests() {
      //testfail type was changed
      //we need to start from page 1, but we can keep currently selected ordering parameters
      this.debouncedLoadDataFromServer();
      this.$store.commit("storeTestfails/setAdminShowReopenedTests", this.showReopenedTests)
    },

    "dialogReopen": function(visible) {
      visible || this.closeReopenDialog();
    },

    paginationOptions: {
      handler(newValue, oldValue) {
        // console.log("options.new", JSON.stringify(newValue));
        // console.log("options.old", JSON.stringify(oldValue));

        if (newValue !== oldValue) {
          this.debouncedLoadDataFromServer();
        }
      },
      deep: true
    },
  },

  created() {
    this.showReopenedTests = this.$store.state.storeTestfails.adminShowReopenedTests;

    // _.debounce is a function provided by lodash to limit how
    // often a particularly expensive operation can be run.
    // In this case, we want to limit how often we access
    // yesno.wtf/api, loading until the user has completely
    // finished typing before making the ajax request. To learn
    // more about the _.debounce function (and its cousin
    // _.throttle), visit: https://lodash.com/docs#debounce
    this.debouncedLoadDataFromServer = debounce(this.loadDataFromServer, 50)
    // load list of testfails
    this.debouncedLoadDataFromServer();
  },

  methods: {
    /** Load list of testfails form server - one page only*/
    loadDataFromServer: function() {
      const kind = this.showReopenedTests;
      const pn = {
        page: this.paginationOptions.page,
        itemsPerPage: this.paginationOptions.itemsPerPage,
        sortBy: this.paginationOptions.sortBy,
        sortDesc: this.paginationOptions.sortDesc,
      };

      this.loading = true;
      adminTestfailsApi.getListTestfails(this.$store.state
          , this.$store.dispatch
          , kind ? 1 : 0
          , pn
      ).then(
          json => {
            this.listItems = json.items;
            this.paginationOptions.page = json.paginationOptions.page;
            //We need to use arrays: https://github.com/vuetifyjs/vuetify/issues/12929
            this.paginationOptions.sortBy = [json.paginationOptions.sortBy];
            this.paginationOptions.sortDesc = [json.paginationOptions.sortDesc];

            this.paginationOptions.itemsPerPage = json.paginationOptions.itemsPerPage;
            this.totalCountItems = json.totalCountItems;
            this.loading = false;
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });
    },

//region Reopen item
    /** Show confirmation dialog to reopen selected test*/
    reopenItem (item) {
      this.itemToReopenIndex = this.listItems.indexOf(item);
      this.dialogReopen = true;
    },

    /** Close the dialog to confirm a reopening of the selected test*/
    closeReopenDialog () {
      this.dialogReopen = false;
      this.$nextTick(() => {
        this.itemToReopenIndex = -1;
      })
    },

    /** Item was confirmed to delete. Make real deletion*/
    reopenItemConfirm () {
      this.reopen_testfail(this.listItems[this.itemToReopenIndex]);
      this.closeReopenDialog();
    },

    /** Item was confirmed to reopen. Make real reopening*/
    reopen_testfail: function(testfailView) {
      adminTestfailsApi.updateTestfail(this.$store.state, this.$store.dispatch
          , testfailView.data.testfail.testfailId
      ).then(
          () => {
            this.$toast.open({
              message: `The test ${testfailView.data.testId} was reopend`,
              type: 'success',
              position: 'top-right'
            });
            this.loadDataFromServer();
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
      this.closeReopenDialog();
    },

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },
//endregion Reopen item
  }
}
</script>

<style scoped>

</style>