import Vue from 'vue';
import Vuex from 'vuex';

import { authentication } from './authentication.module';
import {storeCourses} from "@/store/storeCourses.module";
import {storeMessages} from "@/store/storeMessages.module";
import {storeTestfails} from "@/store/storeTestfails.module";
import {storeUnreadItems} from "@/store/storeUnreadItems.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
    namespaced: true,
    state: {
        /** eLearning server URL*/
        apiUrl: "" //the value will be initially set through setApiUrl
        , appVersion: "" //the value will be initially set through setAppVersion
    },
    modules: {
        authentication,
        storeCourses,
        storeMessages,
        storeTestfails,
        storeUnreadItems,
    },
    mutations: {
        set_api_url(state, value) {
            state.apiUrl = value;
        },
        set_app_version(state, value) {
            state.appVersion = value;
        }
    },
    actions: {
        /** Set initial value of apiUrl on loading*/
        setApiUrl ({commit}, {api_url}) {
            commit("set_api_url", api_url)
        },

        /** Set app version */
        setAppVersion ({commit}, {app_version}) {
            commit("set_app_version", app_version)
        },
    }
});
