import {getAuthorizationHeader} from "@/helpers/auth-header";
import {handleResponse_401_Unauthorized} from "@/helpers/handle401";

/**
 * If function can be used in both admin and user mode,
 * the mode is passed as param to the function
 * */
export const foldersApi = {
    getDocumentsFolder,
    getFilesFolder,
    getParents,

    createFolder,
    updateFolder,
    deleteEmptyFolder,

    attachFilesToFolder,
    detachFilesFromFolder,

    generateFileLink
}

//region Get
/**
 * Get list of the specified folder from Documents section of Document Archive
 * @param rootStoreState
 * @param storeDispatch
 * @param folderId
 *      null - get content of the root folder of Documents section
 *      valid id - get content of the specified folder
 * @param mode boolean
 *      0 - user, 1 - admin
 * @return {Promise<Response>}
 *      function returns a promise that returns JSON with follow data:
 *          ArchiveFolder<Document>
 *              {
 *                  data: {     //Folder
 *                      folderId,
 *                      title,
 *                      itemKind,
 *                      parentFolderId,
 *                      flags
 *                  },
 *
 *                  subfolders[], //array of Folder
 *                  items[] //array of Document
 *              }
 */
function getDocumentsFolder(rootStoreState, storeDispatch, folderId, mode) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = folderId
        ? `${rootStoreState.apiUrl}/api/${mode === true ? "admin" : "user"}/folders/documents/${folderId}`
        : `${rootStoreState.apiUrl}/api/${mode === true ? "admin" : "user"}/folders/documents`;

    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get list of the specified folder from Forms section of Document Archive
 * @param rootStoreState
 * @param storeDispatch
 * @param mode boolean
 *      0 - user, 1 - admin
 * @param folderId
 *      null - get content of the root folder of Forms section
 *      valid id - get content of the specified folder
 * @return {Promise<Response>}
 *      function returns a promise that returns JSON with follow data:
 *          ArchiveFolder<File>
 *              {
 *                  data: {     //Folder
 *                      folderId,
 *                      title,
 *                      itemKind,
 *                      parentFolderId,
 *                      flags
 *                  },
 *
 *                  subfolders[], //array of Folder
 *                  items[] //array of ArchiveFile
 *              }
 */
function getFilesFolder(rootStoreState, storeDispatch, folderId, mode) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = folderId
        ? `${rootStoreState.apiUrl}/api/${mode === true ? "admin" : "user"}/folders/files/${folderId}`
        : `${rootStoreState.apiUrl}/api/${mode === true ? "admin" : "user"}/folders/files`;

    //console.log("url", url);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get all path2folder of the folder starting from the most top (root) folder
 * @param rootStoreState
 * @param storeDispatch
 * @param folderId
 * @param mode boolean
 *      0 - user, 1 - admin
 * @return {Promise<Response>}
 *      function returns a promise that returns JSON with follow data:
 *          Folder[]
 */
function getParents(rootStoreState, storeDispatch, folderId, mode) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/${mode === true ? "admin" : "user"}/folders/parents/${folderId}`;

    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

//endregion Get

//region Create, delete
/**
 * Create new sub-folder of the folder in Document archive
 * Root folders are pre-defined and cannot be created on client side.
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel {object}
 *      see FolderAppController.InputData:
 *      {
 *          title,
 *          parentFolderId
 *      }
 */
function createFolder(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/folders/create`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Remove EMPTY folder in Document archive
 * @param rootStoreState
 * @param storeDispatch
 * @param emptyFolderId
 * @return {Promise<*>}
 */
function deleteEmptyFolder(rootStoreState, storeDispatch, emptyFolderId) {
    const requestOptions = {
        method: 'DELETE',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/folders/${emptyFolderId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Create, delete

//region Update
/**
 * Update courseData title
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param updateModel
 *      see FaFoldersController.InputModelUpdate
 *           {
 *               title,
 *               parentFolderId,
 *               folderId,
 *           }
 */
function updateFolder(rootStoreState, storeDispatch, updateModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(updateModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/folders/edit`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

//endregion Update

//region Attach/detach ready files to/from the folder
/**
 * Attach the files to the folder
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param attachModel
 *      see FaFoldersController.InputModeAttachFiles
 *           {
 *               folderId: int,
 *               fileIds: []
 *           }
 */
function attachFilesToFolder(rootStoreState, storeDispatch, attachModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(attachModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/folders/attach/files`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Detach files from the given folder
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param detachModel
 *      see FaFoldersController.InputModeAttachFiles
 *           {
 *               folderId: int,
 *               fileIds: []
 *           }
 */
function detachFilesFromFolder(rootStoreState, storeDispatch, detachModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(detachModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/folders/detach/files`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Attach/detach ready files to/from the folder

//region File links
/**
 * Update courseData title
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModelFileLink
 *      see FaFoldersController.InputModelFileLink
 *           {
 *               folderId,
 *               fileName,
 *           }
 */
function generateFileLink(rootStoreState, storeDispatch, inputModelFileLink) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModelFileLink)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/folders/filelink`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion File links