import {handleResponse_401_Unauthorized} from "@/helpers/handle401";
import {getAuthorizationHeader} from "@/helpers/auth-header";
import {getUrlWithParams} from "@/helpers/url_utils";

export const adminCoursesApi = {
    getListCourses,
    getCourse,
    getEditCourseEx,

    createCourse,
    deleteCourse,

    updateCourse,

    updateGaCourseMembers,

    getStatByUsers
};

//region Get
/**
 * Get list of courses of specified type
 * @param rootStoreState
 * @param storeDispatch
 * @param mctypeId
 *      courseData type, a value of mctypes.mctype_id
 * @param paginationOptions
 *      optional JSON with set of (optional) pagination options
 *      paginationOptions: {
 *           page: 1,
 *           pageCount: 0,
 *           itemsPerPage: 15,
 *           sortBy: "data.courseId",
 *           sortDesc: true
 *     },
 * @return {Promise<Response>}
 *      function returns a promise that returns JSON with follow data:
 *          {
 *              paginationOptions  - current set of the pagination options
 *              , items - list of the courses on the currently selected page
 *          }
 */
function getListCourses(rootStoreState, storeDispatch, mctypeId, paginationOptions) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = getUrlWithParams(`${rootStoreState.apiUrl}/api/admin/courses/list/${mctypeId}`, paginationOptions);

    //console.log("url", url);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get CourseEditEx for specified course_id
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param courseId
 * @return {Promise<Response>}
 */
function getEditCourseEx(rootStoreState, storeDispatch, courseId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/courses/edit/${courseId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get Course for specified course_id
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param courseId
 * @return {Promise<Response>}
 */
function getCourse(rootStoreState, storeDispatch, courseId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/courses/${courseId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Get

//region Create, delete courseData
/**
 * Create new courseData
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel {object}
 *      see FaCoursesController.InputModelCreate:
 *      {
 *          courseTitle,
 *          flags,
 *          courseBody,
 *          mctypeId
 *      }
 */
function createCourse(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/courses/create`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

function deleteCourse(rootStoreState, storeDispatch, courseId) {
    const requestOptions = {
        method: 'DELETE',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/courses/${courseId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Create, delete courseData

//region Update
/**
 * Update courseData title
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param updateModel
 *      see CoursesAppController.InputModelUpdate
 *           {
 *               courseId,
 *               courseTitle,
 *               recipientsUserIds,
 *               recipientsUserGroupIds,
 *               attachedFileIds,
 *               courseBody,
 *               courseBodyFormat,
 *               isPublished
 *           }
 */
function updateCourse(rootStoreState, storeDispatch, updateModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(updateModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/courses/update`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Update courseData title
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param updateModel
 *      see CoursesAppController.InputModelUpdateMembersByGa
 *           {
 *               courseId,
 *               recipientsUserIds,
 *               recipientsUserGroupIds,
 *           }
 */
function updateGaCourseMembers(rootStoreState, storeDispatch, updateModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(updateModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/courses/gamembers`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Update

//region GetStatByUsers
/**
 * Get stat for the course by users
 * @param rootStoreState
 * @param storeDispatch
 * @param courseId
 * @return {Promise<Response>}
 *      promise returns array of CourseStatusByUser
 */
function getStatByUsers(rootStoreState, storeDispatch, courseId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/courses/stat/${courseId}`;

    //console.log("url", url);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion GetStatByUsers