<!--
  A component to display List of folders and documents
  inside single given folder in Documents sections of the Document Archive
  see #27

it can be opened as:
   documents
   documents/openFolder/folder_id
   documents/openDocument/document_id
There are two optional parameters in the URL: kind and id

#110: The component works differently in admin and user modes.
  Admin mode:
    root folder "Documents" is available for the users
  User mode:
    root folder "Documents" is hidden from the users,
    so user cannot go to "Documents" using different methods:
    - ".." is hidden
    - "Documents" is hidden inside paths-to-folder
-->
<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        class="elevation-1"
        :loading="loading"

        hide-default-footer
        disable-pagination
        :items-per-page="-1"

        @click:row="onRowClick"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- path to the folder -->
          <v-breadcrumbs
              :items="path2folder"
              divider="-"
              class="ma-0 pa-0"
          ></v-breadcrumbs>

          <v-spacer></v-spacer>

      <!-- Add buttons -->
          <v-btn
              dark
              class="ma-2"
              @click="newFolder"
              v-if="adminMode"
          >{{$t("documentsListView.button.NewFolder")}}</v-btn>

          <v-btn
              color="primary"
              class="ma-2"
              @click="createNewDocument"
              v-if="adminMode"
              :disabled="is_current_folder_most_top()"
          >
            {{$t("documentsListView.button.NewDocument")}}
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.icon="{ item }">
        <div class="d-flex justify-center">
          <v-icon v-if="item.isFolder" @click="openItem(item)">
            mdi-folder
          </v-icon>
          <v-icon v-else @click="openItem(item)">
            mdi-note-outline
          </v-icon>
        </div>
      </template>

      <template v-slot:item.title="{ item }">
        <div class="d-flex">
          {{
            get_item_title(item)
          }}
        </div>
      </template>

      <template v-slot:item.createdByUserName="{ item }">
        <div class="d-flex justify-center"  v-if="!item.isFolder">
          {{
            item.data.createdByUserName
          }}
        </div>
      </template>


      <template v-slot:item.createdAt="{ item }">
        <div class="d-flex justify-center" v-if="!item.isFolder">
          {{ dt2str(item.data.createdAt) }}
        </div>
      </template>

      <template v-slot:item.publishedAt="{ item }">
        <div class="d-flex justify-center" v-if="!item.isFolder">
          {{ item.data.publishedAt
            ? dt2str(item.data.publishedAt)
            : $t("label.NotPublished")
          }}
        </div>
      </template>
      <!-- actions column: Edit, delete - with tooltips -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-center">
          <!-- Rename the folder -->
          <v-tooltip top v-if="adminMode && item.isFolder && !item.isPathToRoot">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click.stop="editFolder(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{$t("documentsListView.tooltip.rename")}}</span>
          </v-tooltip>

          <!-- View the document -->
          <v-tooltip top v-if="!item.isFolder">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click.stop="view_document(item)"
              >
                mdi-eye
              </v-icon>
            </template>
            <span>{{$t("documentsListView.tooltip.view")}}</span>
          </v-tooltip>

          <!-- Edit the document -->
          <v-tooltip top v-if="adminMode && !item.isFolder">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click.stop="openItem(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{$t("documentsListView.tooltip.edit")}}</span>
          </v-tooltip>

          <!-- Delete the folder or the document -->
          <v-tooltip top v-if="adminMode &&!item.isPathToRoot">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click.stop="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{$t("documentsListView.tooltip.delete")}}</span>
          </v-tooltip>

        </div>
      </template>
    </v-data-table>

    <!-- a dialog to add new folder or rename exist folder -->
    <dialog-edit-folder
        ref="refDialogEditFolder"
        v-on:folder:save="saveFolderDialog"
    />

    <!-- a dialog to confirm deletion of the folder or document -->
    <dialog-delete-confirmation
        ref="refDialogDeleteConfirmation"
        v-on:deletion:confirmed="deleteItemConfirm"
    />
  </div>
</template>

<script>

import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import DialogDeleteConfirmation from "@/components/shared/dialogDeleteConfirmation";
import DialogEditFolder from "@/components/documents-archive/dialogEditFolder";
import {foldersApi} from "@/api/folders.api";
import {treeDocumentsUtils} from "@/helpers/treeDocumentsUtils";
import {documentsApi} from "@/api/documents.api";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";

export default {
  name: "documentsListView",
  components: {DialogEditFolder, DialogDeleteConfirmation},

  props: [
    "theFolderId",
    /** The control is used in admin mode.
     *  If true, then the user should have admin rights, otherwise control won't work properly*/
    "adminMode",

    /** It's not allowed to edit documents; user can only view them*/
    "userViewMode"
  ],

  model: {
    prop: "theFolderId",
    event: "update:theFolderId"
  },

  data: function() {
    return {
      headers: [
        {
          text: "",
          value: 'icon',
          align: 'center',
          sortable: false,
          width: 60
        },
        {
          text: this.$t("documentsListView.title"),
          value: 'title',
          sortable: false,
        },
        {
          text: this.$t("label.CreatedAt"),
          value: 'createdAt',
          width: 150,
          align: 'center'
        },
        {
          text: this.$t("label.CreatedBy"),
          value: 'createdByUserName',
          width: 200,
          align: 'center'
        },
        {
          text: this.$t("label.PublishedAt"),
          value: 'publishedAt',
          width: 150,
          align: 'center'
        },
        {
          text: this.$t("documentsListView.actions"),
          value: 'actions',
          align: 'center',
          sortable: false,
          width: 160
        },
      ],

      /** All items (folders and documents) in the current folder.
       * Current folder id is stored in this.currentFolder.folderId */
      items: [],

      /** All path2folder of the folder starting from the most top (root) folder
       *  The items have properties according to specification
       *      https://vuetifyjs.com/en/api/v-breadcrumbs/#api-props
       *  {
       *    disabled: boolean,
       *    exact: boolean,
       *    href: string,
       *    link: boolean,
       *    text: string | number,
       *    to: string | object
       *  }
       *
       * In user-view mode the top-most-folder "Documents" is not included here
       * */
      path2folder: [],

      /**
       * Full list of the parent of the current folder.
       * If the current folder is not top-most "Documents",
       * then the array is not empty and it's first item is "Documents" folder
       * */
      parents: [],

      /** data is being loaded from the server */
      loading: false,

      /**
       * Id of the current folder.
       * We cannot use this.$route.params.id
       * because it can contain document_id if this.$route.params.kind === "openDocument"
       */
      currentFolder: {
        folderId: 0
      },
    }
  },

  created() {
  },

  mounted() {
    this.init(this.$route.params.kind, this.$route.params.id);
  },

  watch: {
    theFolderId(value) {
      this.init("openFolder", value)
    },

    dialogFiles(visible) {
      visible || this.closeFilesDialog();
    },
  },

  methods: {
//region Load data
    /** Initialization: it's called on creation and on re-initialization in beforeRouteEnter
     * @param kind
     *    What "id" means
     *       undefined or "openFolder" or "openDocument"
     * @param id
     *    optional id of the document or folder
     *    that should be opened.
     *
     *    If kind is "openDocument" then we need to
     *    open the folder that contains the document
     * */
    init(kind, id) {
      this.openFolder(kind, id);
    },

    /** Show the folder's content in the list
     *
     * @param kind
     *    What "id" means
     *       undefined or "openFolder" or "openDocument"
     * @param id
     *    optional id of the document or folder
     *    that should be opened.
     *
     *    If kind is "openDocument" then we need to
     *    open the folder that contains the document
     * */
    openFolder(kind, id) {
      let dest_folder_id = id; //it can be undefined if the kind is undefined

      if (kind && kind.toLowerCase() === "openDocument") {
      //detect folder_id by document_id
        documentsApi.getDocument(this.$store.state, this.$store.dispatch, id, this.adminMode).then(
            dex => {
              this.currentFolder = dex.data;
            }
        ).catch(error => {
          this.loading = false;
          showHttpErrorsInToasts(this, error);
        });
        return id;
      }

      //open the folder
      foldersApi.getDocumentsFolder(this.$store.state
          , this.$store.dispatch
          , dest_folder_id
          , !!this.adminMode
      ).then(
          archiveFolder => {    //ArchiveFolder<ArchiveFile>
            this.items = this.generate_items_for_root_folder(
                archiveFolder
                , this.is_two_dots_link_enabled(archiveFolder)
            );
            this.currentFolder = archiveFolder.data;
          }
      ).then(
          () => {
            return foldersApi.getParents(this.$store.state
                , this.$store.dispatch
                , this.currentFolder.folderId
                , !!this.adminMode
            );
          }
      ).then(
          parents => {
            //convert Folder[] to breadcrumbs items
            this.parents = parents;

            this.path2folder = parents.map(
                x => {
                  return {
                    text: x.title,
                    to: this.userViewMode
                        ? `/view/user/documents/openFolder/${x.folderId}`
                        : `/view/admin/documents/openFolder/${x.folderId}`
                  };
                }
            );
            if (this.userViewMode) {
              //we need to hide top-most folder Documents
              this.path2folder.splice(0, 1);
            }

            //add current folder to the path as last item (and without  URL)
            this.path2folder.push({
              text: this.currentFolder.title
            });
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });

      return dest_folder_id;
    },

    /** Load this.items from archiveFolder
     * @param addTwoDots
     *      Add ".." - link to the parent folder
     * */
    generate_items_for_root_folder(archiveFolder, addTwoDots) {
      //copy all sub-folders and all nested files to this.Items

      let dest = addTwoDots
          ? [   //items with the root folder ".."
            treeDocumentsUtils.getPathToRootFolder(archiveFolder.data.parentFolderId) //root folder
            , ...treeDocumentsUtils.getChildren(archiveFolder.subfolders, archiveFolder.items)
          ]
          //this is a root folder, there is no ".." item
          : treeDocumentsUtils.getChildren(archiveFolder.subfolders, archiveFolder.items);

      treeDocumentsUtils.sortChildren(this.items);
      return dest;
    },
//endregion Load data

//region Open document / folder
    /** Open folder / open document editor */
    openItem(item) {
      if (item.isFolder) {
        this.$emit("update:theFolderId", item.data.folderId);
        //this.$router.push({ path: `/view/admin/documents/openFolder/${item.data.folderId}` });
      } else {
        if (this.userViewMode) {
          this.view_document(item);
        } else {
          this.$router.push({path: `/view/admin/documents/document/edit/${item.data.data.documentId}`});
        }
      }
    },

    /** open document viewer */
    view_document(item) {
      if (! item.isFolder) {
        this.$router.push({ path: `/view/user/documents/document/view/${item.data.data.documentId}${this.adminMode ? "/1" : ""}` });
      }
    },
//endregion Open document /folder

//region Folder dialog
    /** Edit currently selected folder */
    editFolder(item) {
      this.$refs.refDialogEditFolder.editFolder(item.data);
    },

    /** Create new folder/subfolder */
    newFolder() {
      this.$refs.refDialogEditFolder.newFolder();
    },

    /** This method is called by event folder:save emitted from refDialogEditFolder */
    saveFolderDialog(data) {
      if (data.folderId) {
        this.renameFolder(data.folderId, data.title, data.sortorder);
      } else {
        this.createSubfolder(data.title);
      }
    },
//endregion Folder dialog

//region New document
    /** Open document editor to create new document is the currently selected folder */
    createNewDocument() {
      this.$router.push({ path: `/view/admin/documents/document/create/${this.currentFolder.folderId}` });
    },
//endregion New document

//region Delete folder/document
    /** Display deletion-confirmation dialog */
    deleteItem(item) {
      this.$refs.refDialogDeleteConfirmation.showDialog(
          item.isFolder
              ? this.$t("documentsListView.confirmation.deleteSubfolder", {"title": treeDocumentsUtils.getItemTitle(item)})
              : this.$t("documentsListView.confirmation.deleteFile", {"title": treeDocumentsUtils.getItemTitle(item)})
          , item
      );
    },

    /** Delete folder or document after confirmation*/
    deleteItemConfirm(data) {
      if (data.itemToDelete.isFolder) {
        this.deleteSubfolder(data.itemToDelete.data);
      } else {
        documentsApi.deleteDocument(this.$store.state
            , this.$store.dispatch
            , data.itemToDelete.data.data.documentId
        ).then(
            () => {
              const index = this.items.findIndex(x => !x.isFolder && x.data.data.documentId === data.itemToDelete.data.data.documentId);
              if (index !== -1) {
                this.items.splice(index, 1);
              }
            }
        ).catch(error => {
          this.loading = false;
          showHttpErrorsInToasts(this, error);
        });
      }
    },
//endregion Delete folder/document

//region Data table
    /** User has clicked on the item.
     * Let's open the folder or the file
     * */
    onRowClick(item) {
      if (item.isFolder) {
        this.openItem(item);
      } else {
        //do we need to download item by click on the row?
      }
    },

    get_item_title(item) {
      return treeDocumentsUtils.getItemTitle(item);
    },
//endregion Data table

//region Edit folders
    /** Rename currently selected folder */
    renameFolder(folderId, newTitle, sortOrder) {
      //rename the folder
      foldersApi.updateFolder(this.$store.state, this.$store.dispatch
          , {
            title: newTitle,
            parentFolderId: this.currentFolder.folderId,
            folderId: folderId,
            sortorder: sortOrder ? sortOrder : null
          }
      ).then(
          f => {
            const index = this.items.findIndex(x => x.isFolder && x.data.folderId === folderId);
            if (index !== -1) {
              this.items[index].data = f;
            }
            this.notify_about_current_folder_content_change();

            //let's refresh view (title/sortorder of the folder is probably changed, re-sorting is required)
            this.init(this.$route.params.kind, this.$route.params.id);
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });
    },

    /** Create new subfolder inside current folder */
    createSubfolder(title) {
      foldersApi.createFolder(this.$store.state
          , this.$store.dispatch
          , {
            title: title,
            parentFolderId: this.currentFolder.folderId
          }
      ).then(
          f => {    //ArchiveFolder<Document>
            //copy all subfolders and all nested files to this.Items
            this.items.push(treeDocumentsUtils.getFolderItem(f, [] ))
            treeDocumentsUtils.sortChildren(this.items);
            this.notify_about_current_folder_content_change();
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });
    },

    /** Delete currently selected subfolder */
    deleteSubfolder(folder) {
      foldersApi.deleteEmptyFolder(this.$store.state
          , this.$store.dispatch
          , folder.folderId
      ).then(
          () => {
            const index = this.items.findIndex(x => x.isFolder && x.data.folderId === folder.folderId);
            if (index !== -1) {
              this.items.splice(index, 1);
            }
            this.notify_about_current_folder_content_change();
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });
    },
//endregion Edit folder

    /** true, if the current folder is top root Documents folder */
    is_current_folder_most_top() {
      return this.path2folder && this.path2folder.length === 1;
    },

    /**
     * Content of the current folder is changed:
     *     some subfolder has been created or deleted.
     * If we are inside root "Documents" folder
     * we need to refresh list of subfolders in left-side user menu.
     * To do it, we need to send a notification to NormalView.vue
     **/
    notify_about_current_folder_content_change() {
      if (this.is_current_folder_most_top()) {
        this.$bus.$emit("refreshListDocumentSubfolders", {});
      }
    },

    /**
     * Return true if it's necessary to display ".." folder
     */
    is_two_dots_link_enabled(archiveFolder) {
      return archiveFolder.data.parentFolderId
        && ! ( //#110: in user-view-mode we hide any links to top-most "Documents" folder
            this.userViewMode
            && archiveFolder.isParentFolderRoot
        );
    },

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },
  },

}
</script>

<style scoped>

</style>