<!-- Editor of course types or message types (depending on props)

     It's based on the follow code:
        https://codepen.io/pen/?editors=1010
-->
<template>
    <v-data-table
        :headers="headers"
        :items="listItems"
        :single-select="true"
        item-key="mctypeId"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{titleList}}</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>

          <v-spacer></v-spacer>

<!-- a dialog to edit/insert item -->
          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                {{$t("adminMctypes.button.NewItem")}}
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ dialogTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <!-- About v-if, see: https://stackoverflow.com/questions/51472947/vuetifys-autofocus-works-only-on-first-modal-open -->
                      <v-text-field
                          v-model="editedItem.title"
                          :label="$t('adminMctypes.dialog.Title')"
                          v-if="dialog" autofocus
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-subheader>id={{editedItem.mctypeId}}</v-subheader>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="closeDialog"
                >
                  {{$t("button.Cancel")}}
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                >
                  {{$t("button.Save")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

<!-- a dialog to confirm deletion of the item -->
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="headline">
                {{$t("adminMctypes.delete.Confirm")}}
              </v-card-title>
              <v-card-actions>
                <v-subheader>id={{editedItem.mctypeId}}</v-subheader>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">
                  {{$t("button.Cancel")}}
                </v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                  {{$t("button.Ok")}}
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-toolbar>
      </template>
<!-- actions column: Edit, delete -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"

              >
                mdi-pencil
              </v-icon>
            </template>
            <span>
            {{$t("adminMctypes.tooltip.EditItem")}}
          </span>
          </v-tooltip>

          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>
              {{$t("adminMctypes.tooltip.DeleteItem")}}
            </span>
          </v-tooltip>

        </div>
      </template>
    </v-data-table>
</template>

<script>
import {adminMctypesApi} from "@/api/admin.mctypes.api";
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {deepClone} from "@/helpers/gutils";

export default {
  name: "adminMctypesPage",
  props: [
    /** 0 - course types, 1 - message types */
    'kind'
  ],

  data: function() {
    return {
      /** List of mctypes loaded from the server*/
      listItems: [],
      /** last error received from the server */
      lastError: null,
      /** Columns of the data table */
      headers: [
        {
          text: this.$t("adminMctypes.headers.Id"),
          align: 'start',
          value: 'mctypeId',
          width: 100,
        },
        {
          text: this.$t("adminMctypes.headers.Title"),
          value: 'title'
        },
        {
          text: this.$t("adminMctypes.headers.Actions"),
          value: 'actions',
          sortable: false,
          width: 120,
          align: 'center'
        },
      ],
      /** show edit dialog */
      dialog: false,
      /** show delete-confirmation dialog */
      dialogDelete: false,

      /** The index of the currently edited item in listItems
       * -1 - there is no item that is being edited*/
      editedIndex: -1,
      /** A copy of the item that is being edited*/
      editedItem: {
        mctypeId: 0,
        title: ""
      },
      /** Default values of the item.
       * When a dialog is close, we copy these values to editedItem */
      defaultItem: {
        mctypeId: 0,
        title: ""
      },

      /** A title of edit/insert dialog.
       * We refresh it using refreshEditDialogTitle*/
      dialogTitle: "",
    }
  },

  watch: {
    dialog (val) {
      val || this.closeDialog()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
    editedIndex () {
      this.refreshFormTitle();
    }
  },

  created() {
    this.init();
  },

  /**
   * Course-types and message-types pages use same component to display data.
   * So, "created" is not called second time if we move from one list to another.
   * We need to use beforeRouteEnter to workaround the problem.
   *
   * https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation
   * https://github.com/vuejs/vue-router/issues/1875
   **/
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.init();
    });
  },

  computed: {
    /** Title of the list: 'list of course types' or 'list of message types' */
    titleList: function() {
      return this.kind === 0
          ? this.$t("adminMctypes.ListCourseTypes")
          :  this.kind === 1
              ? this.$t("adminMctypes.ListMessageTypes")
              : 'Unknown'
    },
    /** Title of the types: 'course type' or 'message type'*/
    titleItemType: function() {
      return this.kind === 0
          ? this.$t("adminMctypes.CourseType")
          :  this.kind === 1
              ? this.$t("adminMctypes.MessageType")
              : 'unknown'
    },
  },

  methods: {
//region Initialization
    /** Initialization: it's called on creation and on re-initialization in beforeRouteEnter*/
    init() {
      this.loadItems();
      this.refreshFormTitle();
    },

    refreshFormTitle() {
      if (this.editedIndex === -1) {
        this.dialogTitle = this.$t("adminMctypes.dialog.TitleCreate", {type: this.titleItemType});
      } else {
        this.dialogTitle = this.$t("adminMctypes.dialog.TitleEdit"
            , {
              type: this.titleItemType
            }
        );
      }
    },
//endregion Initialization

//region Requests to server
    /** Load list of all mctypes from server */
    loadItems() {
      adminMctypesApi.getListMctypes(this.$store.state, this.$store.dispatch, this.kind).then(
          list => {
            this.listItems = list;
          }
          , error => showHttpErrorsInToasts(error)
      )
    },

    make_edit_request(editedItem, editedItemIndex) {
      //the object was edited
      adminMctypesApi.updateMctype(
          this.$store.state,
          this.$store.dispatch,
          {
            "mctypeId": editedItem.mctypeId,
            "title": editedItem.title
          }
      ).then(
          new_item => Object.assign(this.listItems[editedItemIndex], new_item)
          , error => showHttpErrorsInToasts(this, error)
      )
    },

    make_insert_request(itemToInsert) {
      adminMctypesApi.createMctype(
          this.$store.state,
          this.$store.dispatch,
          {
            "title": itemToInsert.title,
            "kind": this.kind
          }
      ).then(
          new_item => this.listItems.push(new_item)
          , error => showHttpErrorsInToasts(this, error)
      )
    },

    make_delete_request(indexToDelete) {
      adminMctypesApi.deleteMctype(
          this.$store.state,
          this.$store.dispatch,
          this.listItems[indexToDelete].mctypeId
      ).then(
          () => this.listItems.splice(indexToDelete, 1)
          , error => showHttpErrorsInToasts(this, error)
      )
    },
//endregion Requests to server

//region Edit/create items
    /** Show dialog to edit selected item*/
    editItem (item) {
      this.editedIndex = this.listItems.indexOf(item);
      this.editedItem = deepClone(item);
      this.dialog = true;
    },

    /** Close the dialog to edit selected item*/
    closeDialog () {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = deepClone(this.defaultItem);
        this.editedIndex = -1;
      })
    },

    /** Save changes after editing/inserting*/
    save () {
      if (this.editedIndex > -1) {
        this.make_edit_request(this.editedItem, this.editedIndex); //the object was edited
      } else {
        this.make_insert_request(this.editedItem); //new object was created
      }
      this.closeDialog()
    },
//endregion Edit/create items

//region Delete item
    /** Show confirmation dialog to delete selected item*/
    deleteItem (item) {
      this.editedIndex = this.listItems.indexOf(item);
      this.editedItem = deepClone(item);
      this.dialogDelete = true;
    },

    /** Close the dialog to confirm a deletion of the selected item*/
    closeDelete () {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = deepClone(this.defaultItem);
        this.editedIndex = -1;
      })
    },

    /** Item was confirmed to delete. Make real deletion*/
    deleteItemConfirm () {
      this.make_delete_request(this.editedIndex); //new object was deleted
      this.closeDelete();
    },
//endregion Delete item
  }
}
</script>

<style scoped>

</style>