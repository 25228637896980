/** Keep user-info on message-related pages
 *
 * - list of messages - admin part: mctypeId
 * - list of messages - user part: mctypeId, Unread only
 *
 * */
export const storeMessages = {
    /** All of its getters, actions and mutations (but not state properties) are automatically namespaced based on the path the module is registered at
     *  https://vuex.vuejs.org/guide/modules.html#module-local-state */
    namespaced: true,
    state: {
        /** Currently selected mctype_id with kind "messages" in admin part */
        adminMctypeId: null,
        /** Currently selected mctype_id with kind "messages" in user part */
        userMctypeId: null,
        /** Show Unread messages only - list of messages, user part*/
        userUnreadOnly: true,
    },

    mutations: {
        /** Set currently selected mctype_id for the list of messages in admin part */
        setAdminMctypeId(state, mctypeId) {
            state.adminMctypeId = mctypeId;
        },

        /** Set currently selected mctype_id for the list of messages in user part */
        setUserMctypeId(state, mctypeId) {
            state.userMctypeId = mctypeId;
        },

        /** Set value of flag "Unread only" for the list of messages, user part */
        setUserUnreadOnly(state, unreadOnly) {
            state.userUnreadOnly = unreadOnly;
        },
    }
}
