<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-toolbar flat>
            <v-toolbar-title>{{$t("userMessageViewer.FormTitle")}}</v-toolbar-title>

            <v-chip class="ma-2"
                    color="warning"
                    v-if="!messageData.isDone"
            >{{$t("userMessageViewer.status.Unread")}}</v-chip>
            <v-chip class="ma-2"
                    color="success"
                    v-if="messageData.isDone"
            >{{$t("userMessageViewer.status.Read")}}</v-chip>

            <v-spacer></v-spacer>

            <v-btn
                class="ma-2"
                @click="set_as_read(false)"
                :disabled="!messageData.isDone"
            >
              {{$t("userMessageViewer.SetUnread")}}
            </v-btn>

            <v-btn
                color="primary"
                class="ma-2"
                @click="set_as_read(true)"
                :disabled="messageData.isDone"
            >
              {{$t("userMessageViewer.SetRead")}}
            </v-btn>

            <v-btn
                color="secondary"
                class="ma-2"
                @click="close"
            >
              {{$t("userMessageViewer.Close")}}
            </v-btn>

          </v-toolbar>
        </v-col>
      </v-row>
      <!-- Message ID, from-name -->
      <v-row>
        <v-col>
          {{$t("label.From")}}: {{messageData.createdByUserName}}
        </v-col>
        <v-col>
          {{$t("label.Type")}}: {{messageData.messageTypeTitle}}
        </v-col>
        <v-col>
          {{$t("label.PublishedAt")}}: {{messageData.publishedAt ? dt2str(messageData.publishedAt) : ""}}
        </v-col>
      </v-row>

<!-- Message subject -->
      <v-row>
        <v-col>
          <v-text-field v-model="messageData.title"
                        :label="$t('userMessageViewer.MessageSubject')"
                        readonly
          ></v-text-field>
        </v-col>
      </v-row>

<!-- Message body -->
      <v-row>
        <v-col>
          <template>
            <div id="message_body">
              <v-subheader class="pa-0">{{$t("userMessageViewer.MessageBody")}}:</v-subheader>
              <div v-html="messageData.messageBody">
              </div>
            </div>
          </template>
        </v-col>
      </v-row>

<!-- Attached files -->
      <v-row v-if="messageData.attachedFiles.length > 0">
        <v-col>
          <v-sheet
              elevation="10"
              rounded="xl"
          >
            <v-sheet
                class="pa-3 accent text-right"
                dark
                rounded="t-xl"
            >
              {{$t("label.AttachedFiles")}}
            </v-sheet>

            <div class="pa-4">
              <v-chip class="ma-2"
                      v-for="(item, i) in messageData.attachedFiles"
                      :key="i"
                      @click="download_file(item)"
              >
                {{item.originalFileName}}
              </v-chip>
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {userMessagesApi as userMessagesApi} from "@/api/user.messages.api";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";
import {fileUtils} from "@/helpers/fileUtils";

export default {
  name: "userMessagesPageViewer",
  props: [
  ],

  data: function() {
    return {
      /**
       * All data of the message that is being edited (MessageEditEx)
       */
      messageData: {
        attachedFiles: []
      },

      fileToUpload: [],
    }
  },

  created() {
    // load messageData data
    userMessagesApi.getMessageViewEx(this.$store.state, this.$store.dispatch, this.$route.params.id).then(
        m => {
          this.messageData = m;
        },
        error => showHttpErrorsInToasts(this, error)
    );
  },

  methods: {
    /** Save all message data and optionally publish the message */
    close: function() {
      this.$router.push({ path: `/view/user/messages` })
    },

    /** Set the message as read or unread*/
    set_as_read: function(isMessageRead) {
      userMessagesApi.setMessageStatus(this.$store.state, this.$store.dispatch
          , {
            messageId: this.$route.params.id,
            isRead: isMessageRead
          }
      ).then(
          () => {
            if (isMessageRead) {
              //the message was marked as "read" successfully
              //it means, that message is now "done" and not "active"
              //REP will update message status soon, but currently we should change its manually
              this.messageData.isDone = true;
              this.messageData.isActive = false;
              this.$toast.open({
                message: this.$t("userMessageViewer.message.markedAsRead", {message: this.messageData.messageId}),
                type: 'success',
                position: 'top-right'
              });
            } else {
              //the message was marked as "unread" successfully
              //it means, that message is now "active" and not "done"
              //REP will update message status soon, but currently we should change its manually
              this.messageData.isDone = false;
              this.messageData.isActive = true;
              this.$toast.open({
                message: this.$t("userMessageViewer.message.markedAsUnread", {message: this.messageData.messageId}),
                type: 'success',
                position: 'top-right'
              });
            }

            //update statistics on left side-bar (see Normal view)
            this.$bus.$emit("refreshCountUnprocessedItems", {messages: true});
          },
          error => showHttpErrorsInToasts(this, error)
      );
    },

    /**
     * Download file using JWT-token
     *
     * https://stackoverflow.com/questions/29452031/how-to-handle-file-downloads-with-jwt-based-authentication
     *
     * @param fileItem
     */
    download_file: function(fileItem) {
      fileUtils.downloadFileUsingJWTToken(this, fileItem);
    },
    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },
  }
}
</script>

<style scoped>
/**
DOM content created with v-html are not affected by scoped styles, but you can still style them using deep selectors.
https://vue-loader-v14.vuejs.org/en/features/scoped-css.html
*/

div >>> #message_body img
{
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}
</style>