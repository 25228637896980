<!--
  Confirm deletion of any entity.

  To show the dialog call
      showDialog(title, itemTag)
  where
    title is a message to display, itemTag is arbitrary tag that identifies the item-to-be-deleted

  To get confirmation it's necessary to subscribe to the event
      deletion:confirmed
  it has single parameter
      {
        itemToDelete: itemTag
      }
  that allows to know what item should be deleted.
-->

<template>
  <v-dialog v-model="dialogDelete"
            max-width="520px"
  >
    <v-card color="warning">
      <v-card-title class="headline">
        {{dialogTitle}}
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDeleteDialog">{{$t("button.Cancel")}}</v-btn>
        <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{$t("button.Ok")}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "dialogDeleteConfirmation",

  model: {
    /** This event is sent if the user has pressed Ok button i.e. has confirmed the deletion */
    event: "deletion:confirmed",
  },

  data: function() {
    return {
      /** show delete-confirmation dialog*/
      dialogDelete: false,

      /** Confirmation message. It's assigned when the dialog is opened, see showDialog */
      dialogTitle: "",

      /** Tag of the item-to-be-deleted that is passed as a parameter of the event "deletion:confirmed" */
      itemTag: null,
    }
  },

  watch: {
    dialogDelete(visible) {
      visible || this.closeDeleteDialog();
    },
  },

  methods: {
    /** Display deletion-confirmation dialog */
    showDialog(title, itemTag) {
      this.dialogDelete = true;
      this.dialogTitle = title;
      this.itemTag = itemTag;
    },

    /** Hide deletion-confirmation dialog */
    closeDeleteDialog() {
      this.dialogDelete = false;
    },

    /** Delete folder or file after confirmation*/
    deleteItemConfirm() {
      this.$emit("deletion:confirmed"
          , {
            itemToDelete: this.itemTag
          }
      );
      this.closeDeleteDialog();
    }
  }
}
</script>

<style scoped>

</style>