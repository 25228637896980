/** The unit contains two variables:
 * - count of unread messages
 * - count of undone courses
 * for the current user.
 *
 * The values are initialized when the app is loaded
 * and refreshed after passing a course or a message.
 *
 * */
export const storeUnreadItems = {
    /** All of its getters, actions and mutations (but not state properties) are automatically namespaced based on the path the module is registered at
     *  https://vuex.vuejs.org/guide/modules.html#module-local-state */
    namespaced: true,
    state: {
        /** Count of unread messages for the current user */
        userCountUnreadMessages: 0,

        /** Count of undone courses for the current user */
        userCountUndoneCourses: 0
    },

    mutations: {
        /** Set count of unread messages */
        setCountUnreadMessages(state, userCountUnreadMessages) {
            state.userCountUnreadMessages = userCountUnreadMessages;
        },

        /** Set count of undone courses */
        setCountUndoneCourses(state, userCountUndoneCourses) {
            state.userCountUndoneCourses = userCountUndoneCourses;
        },
    }
}
