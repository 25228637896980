<!--
  Component to select file from Forms section of Document archive
  and get its URL. Then this URL can be added to the document content in adminDocumentPageEditor.

  There are two mode of work: user and admin.

  The selector allows to:
    For users and admins:
      - view tree of folders and files
    For admins only:
      - generate link for any file
      - edit folders and files
-->
<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="items"
        item-key="id"
        class="elevation-1"
        :loading="loading"

        hide-default-footer
        disable-pagination
        :items-per-page="-1"

        @click:row="onRowClick"
    >
      <template v-slot:top>
        <v-toolbar flat>
<!-- path to the folder -->
          <v-breadcrumbs
              :items="path2folder"
              divider="-"
              v-if="!hidePathToFolder"
              class="ma-0 pa-0"
          >
            <template v-slot:item="{ item }">
              <v-breadcrumbs-item
                  :href="item.href"
                  :disabled="item.disabled"
              >
                <v-icon v-if="item.elearning_icon" class="ma-1 pa-0">{{item.elearning_icon}}</v-icon>{{ item.text }}
              </v-breadcrumbs-item>
            </template>
          </v-breadcrumbs>

          <v-spacer></v-spacer>

<!-- Add buttons -->
          <v-btn
              dark
              class="ma-2"
              @click="newFolder"
              v-if="adminMode"
          >{{$t("formsListView.button.NewFolder")}}</v-btn>
          <v-btn
              color="primary"
              class="ma-2"
              @click="createNewFiles"
              v-if="adminMode"
          >
            {{$t("formsListView.button.NewFile")}}
          </v-btn>

        </v-toolbar>
      </template>

      <template v-slot:item.data.title="{ item }">
        <div class="d-flex">
          {{
            get_item_title(item)
          }}
        </div>
      </template>

      <template v-slot:item.icon="{ item }">
        <div class="d-flex justify-center">
          <v-icon v-if="item.isFolder" @click="openItem(item)">
            mdi-folder
          </v-icon>
          <v-tooltip top v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="openItem(item)">
                {{
                  fileExtensions[
                      item && item.data && item.data.fileData && item.data.fileData.fileExtension
                          ? item.data.fileData.fileExtension.substr(1)
                          : ""
                      ]
                }}
              </v-icon>
            </template>
            <span>{{$t("formsListView.tooltip.openFileInBrowser")}}</span>
          </v-tooltip>
        </div>
      </template>

      <!-- actions column: Edit, delete - with tooltips -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-center">
          <!-- Copy link to the file -->
          <v-tooltip top v-if="!item.isFolder">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  class="mr-2"
                  @click.stop="copyUrlToClipboard(item)"
              >
                mdi-content-copy
              </v-icon>
            </template>
            <span>{{$t("formsListView.tooltip.copyUrl")}}</span>
          </v-tooltip>

          <!-- Rename the folder -->
          <v-tooltip top v-if="adminMode && item.isFolder && !item.isPathToRoot">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click.stop="editFolder(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>{{$t("formsListView.tooltip.rename")}}</span>
          </v-tooltip>

          <!-- Open the document -->
          <v-tooltip top v-if="!item.isFolder">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  class="mr-2"
                  @click.stop="downloadItem(item)"
              >
                mdi-content-save
              </v-icon>
            </template>
            <span>{{$t("formsListView.tooltip.downloadTheFile")}}</span>
          </v-tooltip>

          <!-- Delete the folder or the file -->
          <v-tooltip top v-if="adminMode && !item.isPathToRoot">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  @click.stop="deleteItem(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{$t("formsListView.tooltip.delete")}}</span>
          </v-tooltip>

        </div>
      </template>
    </v-data-table>

    <!-- a dialog to add new folder or rename exist folder -->
    <dialog-edit-folder
        ref="refDialogEditFolder"
        v-on:folder:save="saveFolderDialog"
    />

    <!-- a dialog to add new files -->
    <v-dialog v-model="dialogFiles" max-width="500px" @keydown.esc="dialogFiles = false">
      <v-card>
        <v-card-title>
          <span class="headline">{{$t('formsListView.fileDialog.DialogTitle')}}</span>
        </v-card-title>

        <v-card-text>
          <v-file-input v-model="attachedFiles.toUpload"
                        small-chips
                        show-size
                        multiple
                        clearable
                        label="Attach files">
          </v-file-input>
        </v-card-text>

        <v-card-text>
          <div>
            <div class="py-3">
              <v-btn @click="uploadSelectedFiles" :disabled="attachedFiles.toUpload.length === 0">
                {{$t("label.UploadSelectedFiles")}}
              </v-btn>
            </div>
          </div>

          <div class="pa-4">
            <v-progress-circular
                indeterminate
                color="primary"
                v-if="isUploading"
            ></v-progress-circular>

            <v-chip-group column>
              <v-chip
                  v-for="(item, i) in attachedFiles.uploaded"
                  :key="i"
                  class="ma-2"
                  close
                  @click:close="removeAttachedFile(i)"
              >
                {{item.originalFileName}}
              </v-chip>

            </v-chip-group>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="closeFilesDialog"
          >
            {{$t("button.Cancel")}}
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveFilesDialog"
              :disabled="attachedFiles.toUpload.length !== 0 || attachedFiles.uploaded.length === 0"
          >
            {{$t("button.Save")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- a dialog to confirm deletion of the folder or file -->
    <dialog-delete-confirmation
        ref="refDialogDeleteConfirmation"
        v-on:deletion:confirmed="deleteItemConfirm"
    />

    <dialog-show-url
        ref="refDialogShowURL"
        text="lastGeneratedFileURL"
    />
  </div>
</template>

<script>

import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {adminFilesApi} from "@/api/admin.files.api";
import DialogEditFolder from "@/components/documents-archive/dialogEditFolder";
import DialogDeleteConfirmation from "@/components/shared/dialogDeleteConfirmation";
import {fileUtils} from "@/helpers/fileUtils";
import {foldersApi} from "@/api/folders.api";
import {treeFormsUtils} from "@/helpers/treeFormsUtils";
import DialogShowUrl from "@/components/shared/dialogShowUrl";

export default {
  name: "formsListView",
  components: {DialogEditFolder, DialogDeleteConfirmation, DialogShowUrl},

  props: [
      "theFolderId",
      /** The control is used in admin mode.
       *  If true, then the user should have admin rights, otherwise control won't work properly*/
      "adminMode",
      "hidePathToFolder"
  ],

  model: {
    prop: "theFolderId",
    event: "update:theFolderId"
  },

  data: function() {
    return {
      /** a map: file extension - icon */
      fileExtensions: {
        pdf: 'mdi-file-pdf',
        png: 'mdi-file-image',
        jpg: 'mdi-file-image',
        jpeg: 'mdi-file-image',
        txt: 'mdi-file-document',
        xls: 'mdi-file-excel',
        xlsx: 'mdi-file-excel',
        doc: 'mdi-file-word',
        docx: 'mdi-file-word',
        ppt: 'mdi-file-powerpoint',
        pptx: 'mdi-file-powerpoint',

        avi: 'mdi-file-video',
        mpg: 'mdi-file-video',
        mp4: 'mdi-file-video',
        flv: 'mdi-file-video',
        wmv: 'mdi-file-video',

        mp3: 'mdi-file-music',
        wav: 'mdi-file-music',
      },

      headers: [
        {
          text: "",
          value: 'icon',
          align: 'center',
          sortable: false,
          width: 60
        },
        {
          text: this.$t("formsListView.title"),
          value: 'data.title',
          sortable: false,
        },
        {
          text: this.$t("formsListView.actions"),
          value: 'actions',
          align: 'center',
          sortable: false,
          width: 160
        },
      ],

      /** All items (folders and files) in the current folder.
       * Current folder id is stored in this.$route.params.folder_id*/
      items: [],

      /** All path2folder of the folder starting from the most top (root) folder
       *  The items have properties according to specification
       *      https://vuetifyjs.com/en/api/v-breadcrumbs/#api-props
       *  {
       *    disabled: boolean,
       *    exact: boolean,
       *    href: string,
       *    link: boolean,
       *    text: string | number,
       *    to: string | object
       *  }
       * */
      path2folder: [],

      /** show dialog to attach new files to the currently selected folder */
      dialogFiles: false,
      /** list of new files that 1) should be uploaded 2) uploaded (but not attached to document archive) on the server */
      attachedFiles: {
        toUpload: [],
        /** Array of File */
        uploaded: []
      },
      /** file is being uploaded */
      isUploading: false,

      /** currently opened folder */
      currentFolder: {
        folderId: this.theFolderId,
      },

      /** data is being loaded from the server */
      loading: false,

      /** When user gives the command "generate URL,
       *  an URL for the file is generated and stored here.
       *  At the same time DialogShowURL is opened,
       *  so user is able to copy the URL to clipboard
       * */
      lastGeneratedFileURL: null,
    }
  },

  created() {

  },

  mounted() {
    this.init(this.$route.params.folder_id);
  },

  watch: {
    theFolderId(value) {
      this.init(value)
    },

    dialogFiles(visible) {
      visible || this.closeFilesDialog();
    },
  },

  methods: {
//region Load data
    /** Initialization: it's called on creation and on re-initialization in beforeRouteEnter*/
    init: function(folderId) {
      this.openFolder(folderId);
    },

    /** Show the folder's content in the list */
    openFolder(folderId) {
      foldersApi.getFilesFolder(this.$store.state
          , this.$store.dispatch
          , folderId
          , !!this.adminMode
      ).then(
          archiveFolder => {    //ArchiveFolder<ArchiveFile>
            this.items = this.generate_items_for_root_folder(archiveFolder);
            this.currentFolder = archiveFolder.data;

            this.generatePath2Folder(folderId);
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });
    },

    generatePath2Folder(folderId) {
      if (folderId) {
        foldersApi.getParents(this.$store.state
            , this.$store.dispatch
            , folderId
            , !!this.adminMode
        ).then(
            parents => {
              //convert Folder[] to breadcrumbs items
              this.path2folder = parents.map(
                  (x, index) => {
                    return {
                      text: index === 0
                        ? "" //this.$t("label.Forms")
                        : x.title,
                      to: `/view/admin/forms/${x.folderId}`,
                      elearning_icon: index === 0
                        ? "mdi-file-multiple"
                        : null
                    };
                  }
              );
              //add current folder to the path as last item (and without  URL)
              this.path2folder.push({
                text: parents.length === 0
                   ? "" //this.$t("label.Forms")
                   : this.currentFolder.title
                , elearning_icon: parents.length === 0
                    ? "mdi-file-multiple"
                    : null
              });
            }
        ).catch(error => {
          this.loading = false;
          showHttpErrorsInToasts(this, error);
        });
      } else {
        //add current folder to the path as alone item (and without  URL)
        this.path2folder = [{
          text: "", //this.$t("label.Forms"),
          elearning_icon: "mdi-file-multiple"
        }];
      }
    },

    /** Load this.items from archiveFolder */
    generate_items_for_root_folder(archiveFolder) {
      //copy all sub-folders and all nested files to this.Items

      let dest = (archiveFolder.data.parentFolderId)
          ? [   //items with the root folder ".."
            treeFormsUtils.getPathToRootFolder(archiveFolder.data.parentFolderId) //root folder
            , ...treeFormsUtils.getChildren(archiveFolder.subfolders, archiveFolder.items)
          ]
          //this is a root folder, there is no ".." item
          : treeFormsUtils.getChildren(archiveFolder.subfolders, archiveFolder.items);

      treeFormsUtils.sortChildren(dest);
      return dest;
    },
//endregion Load data

//region Folder dialog
    /** Edit currently selected folder */
    editFolder(item) {
      this.$refs.refDialogEditFolder.editFolder(item.data);
    },

    /** Create new folder/subfolder */
    newFolder() {
      this.$refs.refDialogEditFolder.newFolder();
    },

    /** This method is called by event folder:save emitted from refDialogEditFolder */
    saveFolderDialog(data) {
      if (data.folderId) {
        this.renameFolder(data.folderId, data.title, data.sortorder);
      } else {
        this.createSubfolder(data.title);
      }
    },
//endregion Folder dialog

//region Create files
    /** Add one or several files to the current folder */
    createNewFiles() {
      this.attachedFiles = {
        toUpload: [],
        uploaded: []
      };

      this.dialogFiles = true;
    },

    /** Close the dialog to attach files to the currently selected folder */
    closeFilesDialog () {
      this.dialogFiles = false;
      this.$nextTick(() => {
        this.attachedFiles = {
          toUpload: [],
          uploaded: []
        };
      })
    },

    /** Attach uploaded files to the current folder.
     *  Refresh folder content */
    saveFilesDialog() {
      this.attachFilesToCurrentFolder(this.attachedFiles);
      this.closeFilesDialog();
    },

    /** User has opened files-dialog and selected one or several files.
     * Now they are uploaded to the server.
     * Server returns array of File, it's stored to attachedFiles.uploaded */
    uploadSelectedFiles() {
      if (this.attachedFiles.toUpload.length > 0) {
        const files_to_upload = [
          ...this.attachedFiles.toUpload
        ];
        this.attachedFiles.toUpload = [];
        this.isUploading = true;
        adminFilesApi.uploadFiles(this.$store.state, files_to_upload)
            .then(response => {
              this.isUploading = false;
              let files = response.data;
              for (const f of files) {
                this.attachedFiles.uploaded.push(f);
              }
            })
            .catch(error => {
              showHttpErrorsInToasts(this, error);
              this.isUploading = false;
            });
      }
    },

    /** User has uploaded file and decided to remove it (don't save the file in the document archive)
     * The file will be kept on the server, but it won't be added to the document archive. **/
    removeAttachedFile(index0) {
      if (index0 > -1 && index0 < this.attachedFiles.uploaded.length) {
        this.attachedFiles.uploaded.splice(index0, 1);
      }
    },
//endregion Create files

//region Delete folder/document
    /** Display deletion-confirmation dialog */
    deleteItem(item) {
      this.$refs.refDialogDeleteConfirmation.showDialog(
          item.isFolder
              ? this.$t("formsListView.confirmation.deleteSubfolder", {"title": treeFormsUtils.getItemTitle(item)})
              : this.$t("formsListView.confirmation.deleteFile", {"title": treeFormsUtils.getItemTitle(item)})
          , item
      );
    },

    /** Delete folder or document after confirmation*/
    deleteItemConfirm(data) {
      if (data.itemToDelete.isFolder) {
        this.deleteSubfolder(data.itemToDelete.data);
      } else {
        this.detachFile(data.itemToDelete.data);
      }
    },
//endregion Delete folder/document

//region Open file / folder
    /** Open the folder or
     * open the file in the browser.
     * If browser cannot open the file directly, the file will be downloaded
     * */
    openItem(item) {
      if (item.isFolder) {
        //open folder
        this.$emit("update:theFolderId", item.data.folderId);
        //this.$router.push({ path: `/view/admin/forms/${item.data.folderId}` });
        //this.$refs.listView.openFolder(item.data.folderId);
      } else {
        //open file
        fileUtils.downloadFileUsingFileToken(this.$root, item.data.fileData.fileId);
      }
    },

    downloadItem(item) {
      if (item.isFolder) {
        //open folder
        this.$emit("update:theFolderId", item.data.folderId);
      } else {
        //open file
        fileUtils.downloadFileUsingFileToken(this.$root, item.data.fileData.fileId, true);
      }
    },

//endregion Open file /folder

//region Data table
    /** User has clicked on the item.
     * Let's open the folder or the file
     * */
    onRowClick(item) {
      if (item.isFolder) {
        this.openItem(item);
      } else {
        //do we need to download item by click on the row?
      }
    },

    get_item_title(item) {
      return treeFormsUtils.getItemTitle(item);
    },
//endregion Data table

//region Edit folders
    /** Rename currently selected folder */
    renameFolder(folderId, newTitle, sortorder) {
      //rename the folder
      foldersApi.updateFolder(this.$store.state, this.$store.dispatch
          , {
            title: newTitle,
            parentFolderId: this.currentFolder.folderId,
            folderId: folderId,
            sortorder: sortorder ? sortorder : null
          }
      ).then(
          f => {
            const index = this.items.findIndex(x => x.isFolder && x.data.folderId === folderId);
            if (index !== -1) {
              this.items[index].data = f;
            }

            //let's refresh view (title/sortorder of the folder is probably changed, re-sorting is required)
            this.init(this.$route.params.kind, this.$route.params.id);
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });
    },

    /** Create new subfolder inside current folder */
    createSubfolder(title) {
      foldersApi.createFolder(this.$store.state
          , this.$store.dispatch
          , {
            title: title,
            parentFolderId: this.currentFolder.folderId
          }
      ).then(
          f => {    //ArchiveFolder<Document>
            //copy all subfolders and all nested files to this.Items
            this.items.push(treeFormsUtils.getFolderItem(f, [] ))
            treeFormsUtils.sortChildren(this.items);
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });
    },

    /** Delete currently selected subfolder */
    deleteSubfolder(folder) {
      foldersApi.deleteEmptyFolder(this.$store.state
          , this.$store.dispatch
          , folder.folderId
      ).then(
          () => {
            const index = this.items.findIndex(x => x.isFolder && x.data.folderId === folder.folderId);
            if (index !== -1) {
              this.items.splice(index, 1);
            }
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });
    },
//endregion Edit folder

//region Attach and detach files
    /** Attach uploaded files to the current folder. Refresh folder content */
    attachFilesToCurrentFolder(attachedFiles) {
      foldersApi.attachFilesToFolder(this.$store.state
          , this.$store.dispatch
          , {
            folderId: this.currentFolder.folderId,
            fileIds: attachedFiles.uploaded.map(x => x.fileId)
          }
      ).then(
          files_in_folder => {
            let children = this.items.filter(x => x.isFolder);
            //add all files
            for (let f of files_in_folder) {
              children.push(treeFormsUtils.getFileItem(f));
            }
            //treeFormsUtils.sortChildren(children);
            this.items = children;
            treeFormsUtils.sortChildren(this.items);
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });
    },

    /** Detach current file from the current folder
     *  Refresh folder from which the file was detached (=== parent folder). */
    detachFile(file) {
      foldersApi.detachFilesFromFolder(this.$store.state
          , this.$store.dispatch
          , {
            folderId: file.parentFolderId,
            fileIds: [file.fileData.fileId]
          }
      ).then(
          () => {
            return foldersApi.getFilesFolder(this.$store.state
                , this.$store.dispatch
                , this.currentFolder.folderId
                , !!this.adminMode
            );
          }
      ).then(
          archiveFolder => {
            this.items = this.generate_items_for_root_folder(archiveFolder);
          }
      ).catch(error => {
            this.loading = false;
            showHttpErrorsInToasts(this, error);
          }
      );
    },
//endregion Attach and detach files

//region File URL
    copyUrlToClipboard(item) {
      foldersApi.generateFileLink(this.$store.state, this.$store.dispatch
        , {
            folderId: this.currentFolder.folderId,
            fileName: item.data.ffFilename //!TODO: replace by folder2file.filename
          }
      ).then(
          ret => {
            this.$refs.refDialogShowURL.showDialog(this.$t("formsListView.DialogShowUrl.title"), ret.url);
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
    },
//endregion File URL
  },

}
</script>

<style scoped>

</style>