var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar-title',[_c('router-link',{attrs:{"to":("/view/admin/messages/" + (this.$route.params.id) + "/edit"),"tag":"button"}},[_vm._v(" "+_vm._s(_vm.$t("adminUserGroupsStatByMessages.UserGroupTitle" , { "title": this.userGroupData ? this.userGroupData.data.title : "" } ))+" ")]),_c('v-subheader',{staticClass:"ma-0 pa-0"},[_vm._v("id="+_vm._s(_vm.$route.params.id))])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listItems,"item-key":"mutaskId","options":_vm.paginationOptions,"server-items-length":_vm.totalCountItems,"loading":_vm.loading},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t("adminUserGroupsStatByMessages.FormTitle"))+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.isDone",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"small":"","color":item.isDone ? 'green' : 'red'}},[_vm._v(" "+_vm._s(item.isDone ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle-outline")+" ")])],1)]}},{key:"item.lastReadAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.lastReadAt ? _vm.dt2str(item.lastReadAt) : "")+" ")])]}},{key:"item.publishedAt",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.publishedAt ? _vm.dt2str(item.publishedAt) : _vm.$t("adminUserGroupsStatByMessages.status.NotPublished"))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[(item.publishedAt)?_c('router-link',{attrs:{"to":'/view/admin/messages/' + item.messageData.messageId + '/stat',"tag":"button"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-alert ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("adminUserGroupsStatByMessages.tooltip.StatByUsers"))+" ")])])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }