import {handleResponse_401_Unauthorized} from "@/helpers/handle401";
import {getAuthorizationHeader} from "@/helpers/auth-header";

export const adminEditionsApi = {
    getEditionEditEx,

    createEdition,
    updateEdition,
    deleteEdition,
};

//region Get
/**
 * Get EditionEditEx for specified editionData
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param editionId
 * @return {Promise<Response>}
 *      Promise that returns EditionViewExWithEditions
 */
function getEditionEditEx(rootStoreState, storeDispatch, editionId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/editions/edit/${editionId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Get

//region Create, delete edition
/**
 * Create new edition
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel {object}
 *      see FaEditionsController.InputModelCreate:
 *      {
 *          remarks,
 *          body,
 *          bodyFormat,
 *          isPublished,
 *          attachedFileIds,
 *          finalTestId
 *      }
 */
function createEdition(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/editions/create`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

function deleteEdition(rootStoreState, storeDispatch, editionId) {
    const requestOptions = {
        method: 'DELETE',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/editions/${editionId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Create, delete edition

//region Update
/**
 * Update edition's title
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param updateModel
 *      see EditionsAppController.InputModelUpdate
 *           {
 *              editionId,
 *              remarks,
 *              body,
 *              bodyFormat,
 *              isPublished,
 *              attachedFileIds,
 *              finalTestId
 *           }
 */
function updateEdition(rootStoreState, storeDispatch, updateModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(updateModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/editions/update`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Update