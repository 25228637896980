<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="listItems"
        _hide-default-footer
        item-key="courseId"
        class="elevation-1"
        :options.sync="paginationOptions"
        :server-items-length="totalCountItems"
        :loading="loading"
        @click:row="handleClick"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{$t("userCourses.FormTitle")}}</v-toolbar-title>

          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>

<!-- Filter by course types -->
          <v-select
              v-model="currentMctypeId"
              :items="mctypes"
              item-text="title"
              item-value="mctypeId"
              menu-props="auto"
              label="Course type"
              hide-details
              prepend-icon="mdi-map"
              single-line
          ></v-select>

          <v-spacer></v-spacer>

<!-- Switcher to view all/unread courses -->
          <v-switch
              v-model="showUndoneOnly"
              :label="$t('userCourses.UndoneOnly')"
              inset
              class="mx-4"
          ></v-switch>

        </v-toolbar>
      </template>

<!-- Show icon status: RED - unread, GREEN - read -->
      <template v-slot:item.status="{ item }">
        <div class="d-flex justify-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  :color="item.isDone
                    ? 'green'
                    : item.isStarted
                      ? 'yellow'
                      : 'red'"
              >
                {{item.isDone
                  ? "mdi-checkbox-marked-circle"
                  : "mdi-checkbox-blank-circle-outline"
                }}
              </v-icon>
            </template>
            <span>
              {{ item.isDone
                  ? $t("userCourses.status.Done")
                  : item.isStarted
                    ? $t("userCourses.status.Started")
                    : $t("userCourses.status.NotStarted")
              }}
            </span>
          </v-tooltip>
          <div class="mx-4">
            {{item.countCompletedParts}}/{{item.totalCountParts}}
          </div>
        </div>
      </template>

<!-- Highlight all columns by BOLD for all unread items -->
      <template v-slot:item.createdByUserName ="{ item }">
        <div class="d-flex justify-left"
             :class="{'font-weight-bold' : !item.isDone && item.isEnabled}"
        >
          {{item.createdByUserName}}
        </div>
      </template>

      <template v-slot:item.courseId ="{ item }">
        <div class="d-flex justify-left"
             :class="{'font-weight-bold' : !item.isDone && item.isEnabled}"
        >
          {{item.courseId}}
        </div>
      </template>

      <template v-slot:item.createdAt ="{ item }">
        <div class="d-flex justify-left"
             :class="{'font-weight-bold' : !item.isDone && item.isEnabled}"
        >
          {{item.createdAt ? dt2str(item.createdAt) : ""}}
        </div>
      </template>

<!-- Show title + icon of ATTACHED file exists -->
      <template v-slot:item.title ="{ item }">
        <div class="d-flex justify-left align-baseline"
             :class="{'font-weight-bold' : !item.isDone && item.isEnabled}"
        >
          {{item.title}}
          <v-spacer></v-spacer>
          <v-icon v-if="item.countAttachedFiles">
            mdi-attachment
          </v-icon>
          <div v-if="!item.isEnabled">
            <v-icon
                color="orange"
            >
              mdi-alert-outline
            </v-icon>
            <v-chip color="warning"
                    class="ma-2">
              {{$t('userCourses.CourseIsDiabled')}}
            </v-chip>
          </div>

        </div>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import {adminMctypesApi} from "@/api/admin.mctypes.api";
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {debounce} from "lodash";
import {userCoursesApi} from "@/api/user.courses.api";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";

export default {
  name: "adminCoursesPage",
  props: [
  ],

  data: function() {
    return {
      /** List of course mctypes, McType*/
      mctypes: [],

      /** Currently selected type of the courses*/
      currentMctypeId: 0,

      /** Main list of courses loaded from the server, CourseUserView*/
      listItems: [],

      /** Columns of the list of courses */
      headers: [
        {
          text: this.$t("userCourses.headers.CreatedAt"),
          value: 'createdAt',
          width: 150,
          align: 'center'
        },
        // {
        //   text: this.$t("userCourses.headers.Id"),
        //   align: 'start',
        //   value: 'courseId',
        //   width: "80"
        // },
        {
          text: this.$t("userCourses.headers.Creator"),
          value: 'createdByUserName',
          width: 200,
        },
        {
          text: this.$t("userCourses.headers.Status"),
          value: 'status',
          width: 100,
          align: 'center'
        },
        {
          text: this.$t("userCourses.headers.Title"),
          value: 'title'
        },
      ],

      /**
       * Current pagination settings
       *
       * Server side pagination is used
       * see: https://vuetifyjs.com/en/api/v-data-footer/#props
       * */
      paginationOptions: {
        sortDesc: [true],
        sortBy: ["courseId"]
      },
      totalCountItems: 0,

      /** the data is loading from the server */
      loading: false,

      showUndoneOnly: false,
    }
  },

  watch: {
    /** When user select a course type, we need to reload list of the courses of the selected type*/
    currentMctypeId() {
      //course type was changed
      //we need to start from page 1, but we can keep currently selected ordering parameters
      this.debouncedLoadDataFromServer();
      this.$store.commit("storeCourses/setUserMctypeId", this.currentMctypeId)
    },

    showUndoneOnly() {
      //flag "show unread only" was changed
      //we need to start from page 1, but we can keep currently selected ordering parameters
      this.debouncedLoadDataFromServer();
      this.$store.commit("storeCourses/setUserUndoneOnly", this.showUndoneOnly)
    },

    paginationOptions: {
      handler(newValue, oldValue) {
        // console.log("options.new", JSON.stringify(newValue));
        // console.log("options.old", JSON.stringify(oldValue));

        if (newValue !== oldValue) {
          this.debouncedLoadDataFromServer();
        }
      },
      deep: true
    },

  },

  created() {
    // _.debounce is a function provided by lodash to limit how
    // often a particularly expensive operation can be run.
    // In this case, we want to limit how often we access
    // yesno.wtf/api, loading until the user has completely
    // finished typing before making the ajax request. To learn
    // more about the _.debounce function (and its cousin
    // _.throttle), visit: https://lodash.com/docs#debounce
    this.debouncedLoadDataFromServer = debounce(this.loadDataFromServer, 50)
    this.debouncedLoadDataFromServer();

    this.showUndoneOnly = this.$store.state.storeCourses.userUndoneOnly;

    //default mctype_id passed in url from dashboard
    const default_mctype_id = this.$route.params.mctype_id
        ? Number(this.$route.params.mctype_id)
        : this.$store.state.storeCourses.userMctypeId; //set mctype currently stored in the vuex

    // load list of available course-types
    adminMctypesApi.getListTypesOfCourses(this.$store.state, this.$store.dispatch).then(
        list => {
          this.mctypes = list;

          //select first available mctype by default
          if (list.length !== 0) {
            this.currentMctypeId = list[0].mctypeId;
            if (default_mctype_id) {
              if (list.filter(x => x.mctypeId === default_mctype_id).length > 0) {
                this.currentMctypeId = default_mctype_id;
              }
            }
          }
        }
    );
  },

  methods: {
    /** Load list of courses form server - one page only*/
    loadDataFromServer: function() {
      const pn = {
        page: this.paginationOptions.page,
        itemsPerPage: this.paginationOptions.itemsPerPage,
        sortBy: this.paginationOptions.sortBy,
        sortDesc: this.paginationOptions.sortDesc,
      };

      this.loading = true;
      userCoursesApi.getListCourses(this.$store.state
          , this.$store.dispatch
          , {
            mctypeId: this.currentMctypeId
            , unreadOnly: this.showUndoneOnly
          }
          , pn
      ).then(
          json => {
            this.listItems = json.items;
            this.paginationOptions.page = json.paginationOptions.page;
            //We need to use arrays: https://github.com/vuetifyjs/vuetify/issues/12929
            this.paginationOptions.sortBy = [json.paginationOptions.sortBy];
            this.paginationOptions.sortDesc = [json.paginationOptions.sortDesc];

            this.paginationOptions.itemsPerPage = json.paginationOptions.itemsPerPage;
            this.totalCountItems = json.totalCountItems;
            this.loading = false;
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });
    },

    /** User has clicked on the item of the table */
    handleClick: function(item) {
      if (item.isEnabled) {
        this.$router.push({
          path: `/view/user/courses/${item.courseId}/view/` //normal user has logged in
        });
      }
    },

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },

  }
}
</script>

<style scoped>

</style>