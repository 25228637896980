import { render, staticRenderFns } from "./userDocumentPageViewer.vue?vue&type=template&id=184f30a3&scoped=true&"
import script from "./userDocumentPageViewer.vue?vue&type=script&lang=js&"
export * from "./userDocumentPageViewer.vue?vue&type=script&lang=js&"
import style0 from "./userDocumentPageViewer.vue?vue&type=style&index=0&id=184f30a3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "184f30a3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCol,VContainer,VRow,VSpacer,VSubheader,VTextField,VToolbar,VToolbarTitle})
