import {compareUtils} from "@/helpers/compareUtils";

/**
 * Utils for shared/treeDocuments component
 * */
export const treeDocumentsUtils = {
    getChildren,
    getFolderSid,
    getDocumentSid,
    getFolderItem,
    getDocumentItem,
    getItemTitle,
    sortChildren,
    getPathToRootFolder,
    getItemSortorder,
}

/** Generate list of children (folders and documents) for given folder */
function getChildren(subfolders, documents) {
    let dest = [];

    //add all subfolders
    for (let f of subfolders) {
        dest.push(
            treeDocumentsUtils.getFolderItem(
                f
                , []
            ));
    }

    //add all documents
    for (let f of documents) {
        dest.push(treeDocumentsUtils.getDocumentItem(f));
    }

    return dest;
}

function getPathToRootFolder(folderId) {
    const sid = treeDocumentsUtils.getFolderSid(folderId);
    return {
        isFolder: true,
        data: {
            folderId: folderId,
            title: ".."
        },
        children: [],
        id: sid,
        isPathToRoot: true
    }
}

//region Generate unique identifiers for folder/document-items
/** generate folder uid */
function getFolderSid(folderId) {
    return `folder_${folderId}`;
}

/** generate document uid */
function getDocumentSid(document) {
    return `doc_${document.data.documentId}`;
}
//endregion Generate unique identifiers for folder/document-items

//region Generate new items
/**
 * Generate new folder item with uid, children and isFolder flag
 * @param f
 * @param subitems
 *      Optional previously downloaded subitems.
 *      We can reuse them i.e after renaming of the folder.
 * @return {{isFolder: boolean, data, children, id: string}}
 */
function getFolderItem(f, subitems) {
    const sid = treeDocumentsUtils.getFolderSid(f.folderId);
    return {
        isFolder: true,
        data: f,
        children: subitems,
        id: sid,
        createdByUserName: "TODO"
    }
}

/**
 * generate new document item with uid, children and isFolder flag
 * @param document
 * @return {{isFolder: boolean, data, id: string}}
 */
function getDocumentItem(document) {
    const sid = treeDocumentsUtils.getDocumentSid(document);
    return {
        isFolder: false,
        data: document,
        id: sid,
        createdByUserName: "TODO"
    }
}
//endregion Generate new items

/** get title of folder/document */
function getItemTitle(item) {
    return item.isFolder
        ? item.data.title
        : item.data.data.title;
}

function getItemSortorder(item) {
    return item.isFolder
        ? item.data.sortorder
        : item.data.data.sortorder;
}

/** sort in place the children of the given folder */
function sortChildren(items) {
    items.sort(function(a, b) {
        if (a.isFolder === b.isFolder) {
            const a_title = treeDocumentsUtils.getItemTitle(a);
            const b_title = treeDocumentsUtils.getItemTitle(b);
            const a_sortorder = treeDocumentsUtils.getItemSortorder(a);
            const b_sortorder = treeDocumentsUtils.getItemSortorder(b);
            return compareUtils.compareBySortorderAndTitle(a_sortorder, a_title, b_sortorder, b_title );
        } else {
            return a.isFolder
                ? -1
                : 1;
        }
    });
}
