<!--
View course's part and mark the part as DONE or NOT DONE.
Go to next part or to the final test.
-->
<template>
  <div>
    <v-container>
<!-- Course title -->
      <v-row>
        <v-col>
          <v-toolbar flat>
            <v-toolbar-title>
              <router-link :to="`/view/user/courses/${this.courseData.courseId}/view`"
                           tag="button">
                {{`${this.$t("userCoursesPart.Course")}: ${this.courseData.title}`}}
              </router-link>
            </v-toolbar-title>
            <v-chip class="ma-2"
                    color="warning"
                    v-if="!this.partData.isCourseDone"
            >{{$t("userCoursesViewer.Unpassed")}}</v-chip>
            <v-chip class="ma-2"
                    color="success"
                    v-if="this.partData.isCourseDone"
            >{{$t("userCoursesViewer.Passed")}}</v-chip>

            <v-spacer></v-spacer>
            <v-btn
                color="info"
                class="ma-2"
                @click="close()"
            >
              {{$t("adminCoursesEditor.button.Close")}}
            </v-btn>
          </v-toolbar>
        </v-col>
      </v-row>

<!-- Done, undone -->
      <v-row>
        <v-col>
          <v-toolbar flat>
            <v-toolbar-title>{{$t("userCoursesPart.Part")}}: {{partData.data.part.title}}</v-toolbar-title>

            <v-chip class="ma-2">{{partData.partOrderNumber1}} / {{partData.totalCountParts}}</v-chip>

          </v-toolbar>
        </v-col>
      </v-row>

<!-- Part body -->
      <v-row>
        <v-col>
          <v-subheader class="pa-0">{{$t("userCoursesPart.Instructions1")}}</v-subheader>
          <v-divider></v-divider>
          <div id="edition-body" v-html="partData.editionCardBody"/>
        </v-col>
      </v-row>

<!-- Attached files -->
      <v-row v-if="partData.attachedFiles.length > 0">
        <v-col>
          <v-sheet
              elevation="10"
              rounded="xl"
          >
            <v-sheet
                class="pa-3 accent text-right"
                dark
                rounded="t-xl"
            >
              {{$t("label.AttachedFiles")}}
            </v-sheet>

            <div class="pa-4">
              <v-chip class="ma-2"
                      v-for="(item, i) in partData.attachedFiles"
                      :key="i"
                      @click="download_file(item)"
              >
                {{item.originalFileName}}
              </v-chip>
            </div>
          </v-sheet>
        </v-col>
      </v-row>

<!-- Confirm that the part is DONE -->
      <v-row>
        <v-col>
          <v-divider ></v-divider>
          <v-subheader class="pa-0">{{$t("userCoursesPart.Instructions2")}}</v-subheader>
          <v-checkbox
              :label="$t('userCoursesPart.Confirm')"
              v-model="isPartMarkAsDone"/>
        </v-col>
      </v-row>

<!-- Next actions -->
      <v-row>
        <v-col>
          <v-toolbar flat>
            <v-spacer></v-spacer>

            <v-btn
                color="secondary"
                class="ma-2"
                @click="open_final_test()"
                v-if="partData.data.activeEdition.finalTestId"
                :disabled="!isPartMarkAsDone"
            >
              {{$t("userCoursesPart.button.FinalTest")}}
            </v-btn>

            <v-btn
                color="primary"
                class="ma-2"
                @click="save_and_open_next_part()"
                v-if="isNextPartAvailable"
                :disabled="!isPartMarkAsDone"
            >
              {{$t("userCoursesPart.button.NextPart")}}
            </v-btn>

            <v-btn
                color="success"
                class="ma-2"
                @click="finish_the_course()"
                v-if="isFinishCourseButtonAvailable"
                :disabled="!isPartMarkAsDone"
            >
              {{$t("userCoursesPart.button.FinishCourse")}}
            </v-btn>

          </v-toolbar>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {userPartsApi as userPartsApi} from "@/api/user.parts.api";
import {userCoursesApi} from "@/api/user.courses.api";
import {fileUtils} from "@/helpers/fileUtils";

export default {
  name: "userCoursesPagePart",
  props: [
  ],

  data: function() {
    return {
      /** All data of the part and it's active edition, see PartUserViewEx */
      partData: {
        /** PartUserView */
        data: {
          part: {},
          activeEdition: {}
        },
        /** List of File-objects */
        attachedFiles: [],
      },

      /** See Course*/
      courseData: {

      },

      /** Is current part marked as DONE or not */
      isPartMarkAsDone: false
    }
  },

  computed: {
    /** Next part is available iif:
     * - this is not last part in the course
     * - either final test is already passed or there is no final test
     * */
    isNextPartAvailable: function() {
      return (
          !this.partData.data.activeEdition.finalTestId
          || this.partData.isFinalTestPassed
      ) && (
          this.partData.undonePartIds && this.partData.undonePartIds.length
      )
    },

    isFinishCourseButtonAvailable: function() {
      return !this.isNextPartAvailable
        && (
              !this.partData.data.activeEdition.finalTestId
              || this.partData.isFinalTestPassed
        );
    }
  },

  created() {
    this.init(this.$route.params.id);
  },

  /**
   * All parts use same component to display data.
   * So, "created" is not called second time if we move from one list to another.
   * We need to use beforeRouteEnter to workaround the problem.
   *
   * https://router.vuejs.org/guide/advanced/navigation-guards.html#in-component-guards
   **/
  beforeRouteUpdate (to) {
    this.init(to.params.id);
  },

  methods: {
//region Init, close
    init(partId) {
      // load partData data
      userPartsApi.getPartViewEx(this.$store.state, this.$store.dispatch, partId).then(
          m => {
            this.partData = m;
            this.isPartMarkAsDone = m.isPartDone;

            userCoursesApi.getCourseBaseData(this.$store.state, this.$store.dispatch, m.data.part.courseId).then(
                c => {
                  this.courseData = c;
                },
                error => showHttpErrorsInToasts(this, error)
            );
          },
          error => showHttpErrorsInToasts(this, error)
      );
    },

    /** Return to course page */
    close: function() {
      this.$router.push({ path: `/view/user/courses/${this.courseData.courseId}/view` });
    },
//endregion Init, close

//region Actions
    /** Go to final-test page */
    open_final_test: function () {
      this.save_and_make_next_action(
          () => {
            this.$router.push({ path: `/view/user/parts/${this.partData.data.part.partId}/test` });
            this.$forceUpdate();
          }
      );
    },

    save_and_open_next_part: function () {
      this.save_and_make_next_action(
          () => {
            this.$router.push({path: `/view/user/parts/${this.partData.undonePartIds[0]}/view`});
            this.$forceUpdate();
          }
      );
    },

    finish_the_course: function() {
      this.save_and_make_next_action(
          () => {
            this.$router.push({ path: `/view/user/courses/${this.courseData.courseId}/view` });
            this.$forceUpdate(); //!TODO: do we need it ?

            //update statistics on left side-bar (see Normal view)
            this.$bus.$emit("refreshCountUnprocessedItems", {courses: true});
          }
      );
    },

    /** User has marked the part as "DONE".
     * Save his choice and move to next action */
    save_and_make_next_action: function(next_action) {
      if (this.isPartMarkAsDone !== this.partData.isPartDone) {
        //user has marked the part as "done", save it
        userPartsApi.setPartStatus(this.$store.state, this.$store.dispatch
            , {
              editionId: this.partData.data.activeEdition.editionId,
              isDone: this.isPartMarkAsDone
            }
        ).then(
            () => {
              this.$toast.open({
                message: "Part is marked as DONE",
                type: 'success',
                position: 'top-right'
              });
              next_action();
            },
            error => showHttpErrorsInToasts(this, error)
        );
      } else {
        next_action();
      }
    },
//endregion Actions

//region Files
    /**
     * Download file using JWT-token
     *
     * https://stackoverflow.com/questions/29452031/how-to-handle-file-downloads-with-jwt-based-authentication
     *
     * @param fileItem
     */
    download_file: function(fileItem) {
      fileUtils.downloadFileUsingJWTToken(this.$root, fileItem);
    },
//endregion Files
  }
}
</script>

<style scoped>
/**
DOM content created with v-html are not affected by scoped styles, but you can still style them using deep selectors.
https://vue-loader-v14.vuejs.org/en/features/scoped-css.html
*/

div >>> #edition_body img
{
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
}

.show_id {
  position: relative;
  float: right;
}
</style>