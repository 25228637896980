<template>

  <v-app id="inspire">
    <!-- Toolbar -->
    <v-main class="grey lighten-3">
      <v-container class="mt-4">
        <v-row>
          <v-col cols="0" md="2" sd="3"/>
          <!-- login and password -->
          <v-col cols="12"
                 md="8"
                 sd="6"
          >
            <v-card>
              <v-card-title>
                {{$t('login.appTitle')}}
                <v-spacer></v-spacer>
                <div class="locale-changer">
                  <select v-model="$i18n.locale">
                    <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
                  </select>
                </div>
              </v-card-title>
              <v-card-text class="pa-8">
                <v-form ref="form">
                  <!-- Follow hidden text field is added to prevent Chrome-bug with autocompletion:
                       when user/password are filled automatically,
                       labels are not moved to their places from placeholders.
                      https://github.com/vuetifyjs/vuetify/issues/3679
                      The solution with placeholder ' ' from here
                      https://stackoverflow.com/questions/60160260/vuetify-input-autocomplete-bug
                      doesn't work
                  -->
                  <v-text-field label="hidden" style="display:none"></v-text-field>

                  <v-text-field
                      :label="$t('login.userName')"
                      v-model="username"
                      :rules="usernameRules"
                      required
                  ></v-text-field>
                  <v-text-field
                      :label="$t('login.password')"
                      v-model="password"
                      type="password"
                      :rules="passwordRules"
                      :error-messages="lastError"
                      counter
                      required
                  ></v-text-field>
                  <v-layout justify-space-between>
                    <v-btn @click="submit"
                           :class=" { 'blue darken-4 white--text' : true, disabled: loggingIn }"
                           type="submit"
                    >
                      {{$t('login.login')}}
                    </v-btn>
                    <div>build {{this.$store.state.appVersion}}</div>
                  </v-layout>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="0" md="2" sd="3"/>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'Login',
  data() {
    return {
      /** Any error from the server */
      lastError: null,
      username: "",
      password: "",
      /** User has pressed "Submit" button */
      submitted: false,

      passwordRules: [
        (v) => !!v || this.$t("login.rulePasswordIsRequired"),
      ],
      usernameRules: [
        (v) => !!v || this.$t("login.ruleUserNameIsRequired")
      ],

      langs: ['da', 'en']
    }
  },
  computed: {
    loggingIn () {
      return this.$store.state.authentication.status.loggingIn;
    }
  },
  created () {
    // reset login status
    this.$store.dispatch('authentication/logout');
  },
  methods: {
    submit (e) {
      if (this.$refs.form.validate()) {
        e.preventDefault();
        this.submitted = true;
        const {username, password} = this;

        if (username && password) {
          this.$store.dispatch('authentication/login', {username, password})
              .then(
                  this.on_success_login
                  , this.on_failed_login
              );
        }
      }
    },

    on_failed_login(error) {
      this.lastError = error
          ? error
          : this.$t("error.NoConnection");
      console.error(this.lastError);
    },

    on_success_login (user) {
      //save selected lang to the cookie
      const one_year = new Date(Date.now() + 365 * 86400 * 1000);
      this.$cookies.set("language"
          , this.$i18n.locale
          , one_year
      );

      if ( user.isMasterUser) {
        this.$router.push({
          name: "master" //master-user has logged in
        });
      } else {
        this.$router.push({
          path: "view/dashboard" //normal user has logged in
        });
      }
    }
  }
}
</script>
