<!--
  List of folders and files
  single given folder inside Forms sections of the Document Archive
  see #27
-->
<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>{{$t("adminForms.FormTitle")}}</v-toolbar-title>
    </v-toolbar>

    <forms-list-view
        ref="refAdminDocumentPageEditorFileSelector"
        v-on:update:theFolderId="open_folder"
        :the-folder-id="currentFolderId"
        :admin-mode="true"
    />
  </div>
</template>

<script>

import {fileUtils} from "@/helpers/fileUtils";
import FormsListView from "@/components/documents-archive/formsListView";


export default {
  name: "adminFormsPage",
  components: {FormsListView},
  data: function() {
    return {
      currentFolderId: 0,
    }
  },

  created() {
  },

  mounted() {
    this.init(this.$route.params.folder_id);
  },

  watch: {
    dialogFiles(visible) {
      visible || this.closeFilesDialog();
    },
  },

  /**
   * https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation
   * https://github.com/vuejs/vue-router/issues/1875
   **/
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.init(to.params.folder_id);
    });
  },

  beforeRouteUpdate (to, from, next) {
    this.init(to.params.folder_id);
    next();
  },

  methods: {
//region Load data
    /** Initialization: it's called on creation and on re-initialization in beforeRouteEnter*/
    init(folderId) {
      this.currentFolderId = folderId;
    },
//endregion Load data

//region Open file / folder
    open_folder(folderId) {
      //console.log("open_folder", folderId);
      this.$router.push({ path: `/view/admin/forms/${folderId}` });
    },

    openItem(item) {
      if (item.isFolder) {
        //open folder
        this.$router.push({ path: `/view/admin/forms/${item.data.folderId}` });
      } else {
        //open file
        fileUtils.downloadFileUsingFileToken(this.$root, item.data.fileData.fileId);
      }
    },
//endregion Open file /folder
  },

}
</script>

<style scoped>

</style>