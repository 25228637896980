import {handleResponse_401_Unauthorized} from "@/helpers/handle401";
import {getAuthorizationHeader} from "@/helpers/auth-header";
import {getUrlWithParams} from "@/helpers/url_utils";

export const userCoursesApi = {
    getListCourses,
    getCourseViewEx,
    getCourseBaseData,

    setCourseStatus
};

//region Get
/**
 * Get list of courses available for specified user
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel
 *      CourseAppController.InputDataGetUserCourses
 *      {
 *          McTypeId: optional,
 *          UnreadOnly: false
 *      }
 * @param paginationOptions
 *      optional JSON with set of (optional) pagination options
 *      paginationOptions: {
 *           page: 1,
 *           pageCount: 0,
 *           itemsPerPage: 15,
 *           sortBy: "courseId",
 *           sortDesc: true
 *     },
 * @return {Promise<Response>}
 *      function returns a promise that returns JSON with follow data:
 *          {
 *              paginationOptions  - current set of the pagination options
 *              , items - list of the courses on the currently selected page
 *          }
 */
function getListCourses(rootStoreState, storeDispatch, inputModel, paginationOptions) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const query = {...paginationOptions, ...inputModel};

    const url = getUrlWithParams(`${rootStoreState.apiUrl}/api/user/courses/list`, query);

    //console.log("url", url);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get CourseUserViewEx for specified course
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param courseId
 * @return {Promise<Response>}
 */
function getCourseViewEx(rootStoreState, storeDispatch, courseId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/user/courses/view/${courseId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get Course for the specified course
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param courseId
 * @return {Promise<Response>}
 */
function getCourseBaseData(rootStoreState, storeDispatch, courseId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/user/courses/base/${courseId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Get

//region Set course as read or unread
/**
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel
 *         UserCoursesController.InputModelUpdate
 *         {
 *             CourseId,
 *             IsRead
 *         }
 */
function setCourseStatus(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModel)
    };

    const url = `${rootStoreState.apiUrl}/api/user/courses/update`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Set course as read or unread