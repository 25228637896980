<!--
Edit exist edition ($router.params.id is defined)
or
create new edition ($router.params.part_id is defined)

Until new edition is not created (not saved first time),
new-mode is enabled:
- it's not possible to publish the edition
-->
<template>
  <div>
<!-- Course, list of parts - links -->
    <v-toolbar flat>
      <v-toolbar-title>
          <router-link :to="`/view/admin/courses/${this.courseData.courseId}/edit`"
                       tag="button">
            {{
              this.$t("adminEdition.CourseTitle"
                  , {
                    title: this.courseData ? this.courseData.title : ""
                  }
              )
            }}
          </router-link>
<!--        <v-subheader class="ma-0 pa-0">id={{courseData.courseId}}</v-subheader>-->
      </v-toolbar-title>
      <v-divider
          class="mx-4"
          inset
          vertical
      ></v-divider>
      <v-toolbar-title>
        <router-link :to="`/view/admin/courses/${this.courseData.courseId}/parts`"
                     tag="button">
          {{$t("adminEdition.ListParts")}}
        </router-link>
      </v-toolbar-title>
    </v-toolbar>

<!-- Part title -->
    <v-toolbar flat>
      <v-toolbar-title>
        {{$t("adminEdition.Part", {part: this.partData.title})}}
      </v-toolbar-title>
    </v-toolbar>

<!-- Toolbar with buttons -->
    <v-toolbar flat>
      <v-toolbar-title v-if="isNewMode()">
        {{$t("adminEdition.Create")}}
      </v-toolbar-title>
      <v-toolbar-title v-else>
        {{$t("adminEdition.Edit", {edition: editionData.data.editionId})}}
      </v-toolbar-title>
      <v-divider
          class="mx-2"
          inset
          vertical
      ></v-divider>

      <v-spacer></v-spacer>

      <v-btn
          color="warning"
          class="ma-2"
          :disabled="(editionData.data.isPublished || !courseData.isPublished)"
          v-if="!isNewMode()"
          @click="deleteItem()"
      >
        {{$t("button.Delete")}}
      </v-btn>

      <v-btn
          color="primary"
          class="ma-2"
          :disabled="(editionData.data.isPublished || !courseData.isPublished)"
          v-if="!isNewMode()"
          @click="saveAndPublish(true)"
      >
        {{$t("adminEdition.button.SaveAndPublish")}}
      </v-btn>

      <v-btn
          color="secondary"
          class="ma-2"
          :disabled="editionData.data.isPublished"
          @click="saveAndPublish(false)"
      >
        {{$t("button.Save")}}
      </v-btn>

      <v-btn
          class="ma-2"
          @click="close()"
      >
        {{$t("button.Close")}}
      </v-btn>

    </v-toolbar>

    <v-container>
<!-- Created by, created at, published at -->
      <v-row v-if="!isNewMode()">
        <v-col>
          {{$t("label.CreatedBy")}}: <v-chip>{{editionData.createdByUserName}}</v-chip>
        </v-col>
        <v-col>
          {{$t("label.CreatedAt")}}: <v-chip>{{dt2str(editionData.createdAt)}}</v-chip>
        </v-col>
        <v-col>
          {{$t("label.PublishedAt")}}:
          <v-chip v-if="editionData.publishedAt">
            {{editionData.publishedAt
                ? dt2str(editionData.publishedAt)
                : ""
            }}
          </v-chip>
        </v-col>
      </v-row>

<!-- Edition's remarks -->
      <v-row>
        <v-col>
          <v-text-field v-model="editionData.data.remarks"
                        :label="$t('adminEdition.CommentsToEdition')"
          ></v-text-field>
        </v-col>
      </v-row>

<!-- Retraining -->
      <v-row>
        <v-col>
          <v-checkbox
              v-model="editionData.data.isRetrainingRequired"
              :label="$t('adminEdition.IsRetrainingRequired')"
          ></v-checkbox>
        </v-col>
      </v-row>

<!-- Text of the document -->
      <v-row>
        <v-col>
          <v-subheader>
            {{$t("adminEdition.TextDocument")}}
          </v-subheader>
          <ckeditor-wrapper
              ref="ckeditorWrapper"
              v-bind:text.sync="editionData.body"
              :readonly="editionData.data.isPublished"
          >
          </ckeditor-wrapper>
        </v-col>
      </v-row>

<!-- Attached files -->
      <v-row>
        <v-col>
          <v-subheader>
            {{$t("label.AttachedFiles")}}
          </v-subheader>
          <v-sheet
              elevation="10"
              rounded="xl"
          >
            <v-sheet
                class="pa-3 accent text-right"
                dark
                rounded="t-xl"
            >
              <v-file-input v-model="fileToUpload"
                            :disabled="editionData.data.isPublished"
                            small-chips
                            show-size
                            multiple
                            clearable
                            label="Attach files">
              </v-file-input>
              <div v-if="fileToUpload.length > 0">
                <div class="py-3">
                  <v-btn @click="uploadSelectedFiles">
                    {{$t("label.UploadSelectedFiles")}}
                  </v-btn>
                </div>
              </div>

            </v-sheet>

            <div class="pa-4">
              <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="isUploading"
              ></v-progress-circular>

              <v-chip-group>
                <v-chip
                    v-for="(item, i) in editionData.attachedFiles"
                    :key="i"
                    class="ma-2"
                    close
                    @click:close="removeAttachedFile(i)"
                >
                  {{item.originalFileName}}
                </v-chip>

              </v-chip-group>
            </div>
          </v-sheet>
        </v-col>

      </v-row>

<!-- Final test -->
      <v-row>
        <v-col>
          <v-switch
              v-model="enableFinalTest"
              :label="$t('adminEdition.UserFinalTest')"
          ></v-switch>
          <admin-edition-page-test-editor
              ref="testEditor"
              v-if="enableFinalTest"
              :json-final-test="editionData.jsonFinalTest"
          />
        </v-col>
      </v-row>
    </v-container>

    <!-- a dialog to confirm deletion of the item -->
    <v-dialog v-model="dialogDelete"
              max-width="520px"
    >
      <v-card color="warning">
        <v-card-title class="headline">
          {{$t("adminEdition.delete.Confirmation")}}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteDialog">{{$t("button.Cancel")}}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{$t("button.OK")}}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>


import {adminEditionsApi} from "@/api/admin.editions.api";
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {adminFilesApi} from "@/api/admin.files.api";
import {adminPartsApi} from "@/api/admin.parts.api";
import {adminCoursesApi} from "@/api/admin.courses.api";
import AdminEditionPageTestEditor from "@/components/admin/adminEditionPageTestEditor";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";
import CkeditorWrapper from "@/components/shared/ckeditorWrapper";

//!TODO: https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/installing-plugins.html
//import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter';

export default {
  name: "adminEditionPage",
  components: {AdminEditionPageTestEditor, CkeditorWrapper},
  props: [
  ],

  data: function() {
    return {
      /**
       * All data of the edition that is being edited (EditionEditEx)
       */
      editionData: {
        data: {

        }, attachedFiles: []
        , jsonFinalTest: {}
      },
      /** Basic-data of the part to which the edition belogs */
      partData: {},
      /** Basic-data of the course to which the part belongs */
      courseData: {},
      fileToUpload: [],
      /** file is being uploaded */
      isUploading: false,

      /** Enable final test - user is able to edit jsonFinalTest*/
      enableFinalTest: false,

      /** show delete-confirmation dialog*/
      dialogDelete: false,
    }
  },

  created() {
    this.init();
  },

  /**
   * When new page is created, the page is redirected to itself with different set of params
   * (to change isNewMode from true to false)
   *
   * So, "created" is not called second time if we move from one list to another.
   * We need to use beforeRouteEnter to workaround the problem.
   *
   * https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation
   * https://github.com/vuejs/vue-router/issues/1875
   **/
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.init();
    });
  },

  watch: {
    "dialogDelete": function(visible) {
      visible || this.closeDeleteDialog();
    },
  },

  methods: {
//region Init
    /** true - new course is being created
     *  false - exist course is being edited */
    isNewMode: function() {
      return !this.$route.params.edition_id;
    },

    init() {
      if (this.isNewMode()) {
        this.load_part_and_course_info(this.$route.params.part_id);
        this.enableFinalTest = false;
      } else {
        // load data of exist edition
        adminEditionsApi.getEditionEditEx(this.$store.state, this.$store.dispatch, this.$route.params.edition_id).then(
            m => {
              this.editionData = m;
              this.enableFinalTest = !!m.jsonFinalTest;
              this.$refs.ckeditorWrapper.content = this.editionData.body;
              this.$refs.ckeditorWrapper.isDisabled = this.editionData.data.isPublished;// || !this.courseData.isPublished);

              this.load_part_and_course_info(this.editionData.data.partId);
            },
            error => showHttpErrorsInToasts(this, error)
        );
      }
    },

    /** load owner-part-data and owner-course-data **/
    load_part_and_course_info: function(partId) {
      //load owner-part-data
      adminPartsApi.getPart(this.$store.state, this.$store.dispatch, partId).then(
          p => {
            this.partData = p;

            //load owner-course-data
            adminCoursesApi.getCourse(this.$store.state, this.$store.dispatch, this.partData.courseId).then(
                c => {
                  this.courseData = c;
                },
                error => showHttpErrorsInToasts(this, error)
            );
          },
          error => showHttpErrorsInToasts(this, error)
      )
    },
//endregion Init

//region Save
    /** Save all edition data and optionally publish the edition */
    saveAndPublish: function(publishTheEditionAfterSaving) {
      if (this.isNewMode()) {
        this.save_create();
      } else {
        this.save_update(publishTheEditionAfterSaving);
      }
    },

    save_create() {
      adminEditionsApi.createEdition(this.$store.state, this.$store.dispatch
          , {
            partId: this.$route.params.part_id,
            remarks: this.editionData.data.remarks,
            attachedFileIds: this.editionData.attachedFiles.map(x => x.fileId),
            body: this.editionData.body,
            bodyFormat: "HTML",
            jsonFinalTest: this.enableFinalTest
                ? this.$refs.testEditor.getResultsAsJson()
                : undefined,
            isRetrainingRequired: this.editionData.data.isRetrainingRequired
          }
      ).then(
          edition_id => {
            this.$toast.open({
              message: this.$t("adminEdition.messages.Created", {id: edition_id}),
              type: 'success',
              position: 'top-right'
            });
            this.$router.replace({ path: `/view/admin/editions/${edition_id}` })

            //update statistics on left side-bar (see Normal view)
            this.$bus.$emit("refreshCountUnprocessedItems", {courses: true});
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
    },

    save_update(publishTheEditionAfterSaving) {
      adminEditionsApi.updateEdition(this.$store.state, this.$store.dispatch
          , {
            editionId: this.editionData.data.editionId,
            remarks: this.editionData.data.remarks,
            attachedFileIds: this.editionData.attachedFiles.map(x => x.fileId),
            body: this.editionData.body,
            bodyFormat: this.editionData.bodyFormat,
            isPublished: publishTheEditionAfterSaving,
            jsonFinalTest: this.enableFinalTest
                ? this.$refs.testEditor.getResultsAsJson()
                : undefined,
            isRetrainingRequired: this.editionData.data.isRetrainingRequired
          }
      ).then(
          () => {
            this.$toast.open({
              message: publishTheEditionAfterSaving
                  ? this.$t("adminEdition.messages.Published", {id: this.editionData.data.editionId})
                  : this.$t("adminEdition.messages.Saved", {id: this.editionData.data.editionId}),
              type: 'success',
              position: 'top-right'
            });

            //we need to update isPublished after publishing of the edition
            //to correctly disable buttons on top toolbar
            if (publishTheEditionAfterSaving) {
              this.editionData.data.isPublished = true;
            }

            //update statistics on left side-bar (see Normal view)
            this.$bus.$emit("refreshCountUnprocessedItems", {courses: true});
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
    },
//endregion Save

//region Attached files
    /**  Upload and register all selected files on the server. */
    uploadSelectedFiles: function() {
      if (this.fileToUpload.length > 0) {
        const files_to_upload = [
          ...this.fileToUpload
        ];
        this.fileToUpload = [];
        this.isUploading = true;
        adminFilesApi.uploadFiles(this.$store.state, files_to_upload)
            .then(response => {
              this.isUploading = false;
              let files = response.data;
              for (const f of files) {
                this.editionData.attachedFiles.push(f);
              }
            })
            .catch(error => {
              this.isUploading = false;
              showHttpErrorsInToasts(this, error);
            });
      }
    },

    /**
     * Detach the file from the edition
     * (but don't delete already uploaded file from the server)
     * */
    removeAttachedFile(chipIndex) {
      if (chipIndex > -1 && chipIndex < this.editionData.attachedFiles.length) {
        this.editionData.attachedFiles.splice(chipIndex, 1);
      }
    },
//endregion Attached files

    close() {
      this.$router.push({ path: `/view/admin/courses/${this.courseData.courseId}/parts` })
    },

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },

//region Delete item
    /** Show confirmation dialog to delete selected item*/
    deleteItem () {
      this.dialogDelete = true;
    },

    /** Close the dialog to confirm a deletion of the selected item*/
    closeDeleteDialog () {
      this.dialogDelete = false;
    },

    /** Item was confirmed to delete. Make real deletion*/
    deleteItemConfirm () {
      adminEditionsApi.deleteEdition(this.$store.state
          , this.$store.dispatch
          , this.editionData.data.editionId
      ).then(
          () => {
            this.closeDeleteDialog();
            this.$toast.open({
              message: this.$t("adminEdition.message.Deleted", {"course": this.editionData.data.editionId}),
              type: 'success',
              position: 'top-right'
            });
            this.close();
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
    },
//endregion Delete item
  }
}
</script>

<style scoped>

</style>