/**
 * Display all possible errors received from the server
 */


/**
 * Show error(s) in toast(s)
 *
 * @param vm
 *      instance of Vue
 * @param errors
 *      single error (string)
 *      or
 *      multiple errors (array of the strings)
 * */
export function showHttpErrorsInToasts(vm, errors) {
    console.log(errors);

    if (Array.isArray(errors) ) {
        for (let error of errors) {
            vm.$toast.open({
                message: error,
                type: 'error',
                position: 'top-right'
            });
        }
    } else {
        vm.$toast.open({
            message: errors,
            type: 'error',
            position: 'top-right'
        });
    }
}

/**
 *
 * @param data
 *      Parse json with possible errors.
 *      Typical examples are follow.
 *
 *  A json, produced by FluentValidation:
 *
 *  {"type":"https://tools.ietf.org/html/rfc7231#section-6.5.1"
 *      ,"title":"One or more validation errors occurred."
 *      ,"status":400
 *      ,"traceId":"00-4b311b3618a07f418623387b51a0442f-a304d6d398da5749-00"
 * ,"errors":{"Title":["'Title' must not be empty."]}}
 *
 * @return {[]}
 */
export function parseHttpErrorResponse(data) {

    let errors = [];
    if (data) {
        if (data.title) {
            errors.push(data.title);
        } else if (data.Title) {
            errors.push(data.Title); //asp.net.core ProblemDetails, see E21ProblemDetailsExtensions
        }

        if (data.errors) {
            if (data === Object(data)  && data !== null) {
                for (let property_name in data.errors) {
                    const value = data.errors[property_name];
                    if (Array.isArray(value)) {
                        for (let item of value) {
                            errors.push(`${property_name}: ${item}`);
                        }
                    } else {
                        errors.push(value);
                    }
                }
            }
        }
    }

    const ret = errors.length
        ? errors
        : null;

    return ret;
}