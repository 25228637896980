const APP_VERSION = "2021.12.15.26"; //!TODO: move it to .env

import Vue from 'vue'


import './assets/css/styles.css'


import { router } from "./helpers"
import { store } from "./store";

import App from './App.vue'
import vuetify from './plugins/vuetify';

// VueToast ***************
// https://github.com/ankurk91/vue-toast-notification
import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);
//*************** VueToast

// CKEditor ==================
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use( CKEditor );
// ================== CKEditor

// i18n ==================
import i18n from './i18n'
// ================== i18n

Vue.config.productionTip = false


// Event bus ================
//let's use central event bus, see https://habr.com/ru/post/332628/
Object.defineProperty(Vue.prototype,"$bus",{
  get: function() {
    return this.$root.bus;
  }
});
//Example, how to use:
// mounted: function() {
//   this._someEvent = (..) => {
//   ..
//   }
//   this._otherEvent = (..) => {
//   ..
//   }
//   this.$bus.$on("someEvent",this._someEvent);
//   this.$bus.$on("otherEvent",this._otherEvent);
// },
// beforeDestroy: function() {
//   this._someEvent && this.$bus.$off("someEvent",this._someEvent);
//   this._otherEvent && this.$bus.$off("otherEvent",this._otherEvent);
// }
//
// this.$bus.$emit('specialEvent', {});
// ================ Event bus

//Cookies
//https://www.npmjs.com/package/vue-cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies);

new Vue({
  vuetify,
  render: h => h(App),
  router: router,
  store: store,
  i18n,
  // Here we bind our event bus to our $root Vue model.
  data: {
    bus: new Vue({})
  },

  created () {
    // set value of apiUrl constant
    const api_url = process.env.VUE_APP_URL || "https://localhost:5001";
    // let api_url =  window.location.href.includes("localhost") || window.location.href.includes("127.0.0.1")
    //     ? "https://localhost:5001"
    //     : "https://el.sawo.dk";
    //     //: "https://sel.efaktum.dk";
    //api_url = this.$store.state.apiUrl;

    this.$store.dispatch("setApiUrl", {api_url} );
    this.$store.dispatch("setAppVersion", {app_version: APP_VERSION} );

    // read lang from cookie, see login.vue
    const stored_lang = this.$cookies.get("language");
    if (stored_lang) {
      this.$i18n.locale = stored_lang;
    } else {
      this.$i18n.locale = "da"; //default language, see #115
    }
  }
}).$mount('#app')
