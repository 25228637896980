var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.listItems,"_hide-default-footer":"","item-key":"courseId","options":_vm.paginationOptions,"server-items-length":_vm.totalCountItems,"loading":_vm.loading},on:{"update:options":function($event){_vm.paginationOptions=$event},"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("userCourses.FormTitle")))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-select',{attrs:{"items":_vm.mctypes,"item-text":"title","item-value":"mctypeId","menu-props":"auto","label":"Course type","hide-details":"","prepend-icon":"mdi-map","single-line":""},model:{value:(_vm.currentMctypeId),callback:function ($$v) {_vm.currentMctypeId=$$v},expression:"currentMctypeId"}}),_c('v-spacer'),_c('v-switch',{staticClass:"mx-4",attrs:{"label":_vm.$t('userCourses.UndoneOnly'),"inset":""},model:{value:(_vm.showUndoneOnly),callback:function ($$v) {_vm.showUndoneOnly=$$v},expression:"showUndoneOnly"}})],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":item.isDone
                    ? 'green'
                    : item.isStarted
                      ? 'yellow'
                      : 'red'}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.isDone ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle-outline")+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.isDone ? _vm.$t("userCourses.status.Done") : item.isStarted ? _vm.$t("userCourses.status.Started") : _vm.$t("userCourses.status.NotStarted"))+" ")])]),_c('div',{staticClass:"mx-4"},[_vm._v(" "+_vm._s(item.countCompletedParts)+"/"+_vm._s(item.totalCountParts)+" ")])],1)]}},{key:"item.createdByUserName",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left",class:{'font-weight-bold' : !item.isDone && item.isEnabled}},[_vm._v(" "+_vm._s(item.createdByUserName)+" ")])]}},{key:"item.courseId",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left",class:{'font-weight-bold' : !item.isDone && item.isEnabled}},[_vm._v(" "+_vm._s(item.courseId)+" ")])]}},{key:"item.createdAt",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left",class:{'font-weight-bold' : !item.isDone && item.isEnabled}},[_vm._v(" "+_vm._s(item.createdAt ? _vm.dt2str(item.createdAt) : "")+" ")])]}},{key:"item.title",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-left align-baseline",class:{'font-weight-bold' : !item.isDone && item.isEnabled}},[_vm._v(" "+_vm._s(item.title)+" "),_c('v-spacer'),(item.countAttachedFiles)?_c('v-icon',[_vm._v(" mdi-attachment ")]):_vm._e(),(!item.isEnabled)?_c('div',[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(" mdi-alert-outline ")]),_c('v-chip',{staticClass:"ma-2",attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('userCourses.CourseIsDiabled'))+" ")])],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }