export const compareUtils = {
    compareBySortorderAndTitle
}


/**
 * #119: Folders and documents are sorted by sortorder and title.
 *       Sortorder can be equal to null.
 *
 *       All items with not-null-sortorder are show at the top,
 *       other items with null-sortorder are shown below
 *
 * @param sortorder1
 * @param title1
 * @param sortorder2
 * @param title2
 */
function compareBySortorderAndTitle(sortorder1, title1, sortorder2, title2) {

    if (sortorder1 === null || sortorder1 === undefined) {
        if (sortorder2 === null || sortorder2 === undefined) {
            //both documents don't have sortorders
            //let's compare by title
            return (title1 || "").localeCompare(title2, 'da', { sensitivity: 'accent' });
        } else {
            //y has sortorder, x doesn't have it
            return 1;
        }
    } else {
        if (sortorder2 === null || sortorder2 === undefined) {
            //x has sortorder, y doesn't have it
            return -1;
        } else {
            //both documents have sortorder
            if (sortorder1 < sortorder2) {
                return -1;
            } else if (sortorder1 > sortorder2) {
                return 1;
            } else {
                //both documents have a same sortorder
                return (title1 || "").localeCompare(title2, 'da', { sensitivity: 'accent' });
            }
        }
    }
}