<!--
Edit exist user-group ($router.params.id is defined)
or
create new user-group ($router.params.part_id is defined)

Until new user-group is not created (not saved first time),
new-mode is enabled
-->
<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <v-toolbar flat>
            <v-toolbar-title v-if="isNewMode()">
              {{$t("adminUserGroupsEditor.TitleCreate")}}
            </v-toolbar-title>
            <v-toolbar-title v-else>
              {{$t("adminUserGroupsEditor.TitleEdit")}}
              <v-subheader class="ma-0 pa-0">id={{userGroupData.data.userGroupId}}</v-subheader>
            </v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn
                color="primary"
                class="mr-4"
                @click="saveAndClose(true)"
            >
              {{$t("adminUserGroupsEditor.button.SaveAndClose")}}
            </v-btn>

            <router-link to="/view/admin/user_groups"
                         tag="button">
              <v-btn
                  class="mr-4"
              >
                {{$t("button.Close")}}
              </v-btn>
            </router-link>

          </v-toolbar>
        </v-col>
      </v-row>

      <!-- User group title and description -->
      <v-row>
        <v-col>
          <v-text-field v-model="userGroupData.data.title"
                        :label="$t('adminUserGroupsEditor.UserGroupName')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field v-model="userGroupData.data.description"
                        :label="$t('adminUserGroupsEditor.Description')"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Group admins and members -->
      <v-row>
        <v-col>
          <!-- Group admins -->
          <v-select
              v-model="groupAdminUserIds"
              :items="listUsers"
              :item-text="x => x.title"
              :item-value="x => x.userId"
              :label="$t('adminUserGroupsEditor.GroupAdmins')"
              chips
              deletable-chips
              multiple
              clearable
          ></v-select>
        </v-col>
      </v-row>
      <!-- Members -->
      <v-row>
        <v-col>
          <v-select
              v-model="membersUserIds"
              :items="listUsers"
              :item-text="x => x.title"
              :item-value="x => x.userId"
              :label="$t('adminUserGroupsEditor.GroupMembers')"
              chips
              deletable-chips
              multiple
              clearable
          ></v-select>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {adminUserGroupsApi} from "@/api/admin.usergroups.api";
import {adminUsersApi} from "@/api/admin.users.api";

export default {
  name: "adminMessagesPageEditor",
  props: [
  ],

  data: function() {
    return {
      /**
       * All data of the message that is being edited (MessageEditEx)
       */
      userGroupData: {
        data: {},
        members: [],
        groupAdmins: [],
      },

      groupAdminUserIds: [],
      membersUserIds: [],

      /** Full list of available dbUsers: DbUserEx */
      listUsers: [],
    }
  },

  created() {
    this.init();
  },

  /**
   * When new user group is created, the page is redirected to itself with different set of params
   * (to change isNewMode from true to false)
   *
   * So, "created" is not called second time if we move from one list to another.
   * We need to use beforeRouteEnter to workaround the problem.
   *
   * https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation
   * https://github.com/vuejs/vue-router/issues/1875
   **/
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.init();
    });
  },

  methods: {

//region Init
    /** true - new course is being created
     *  false - exist course is being edited */
    isNewMode: function() {
      return !this.$route.params.id;
    },

    init: function() {
      //load list of available users
      adminUsersApi.getListDbUsers(this.$store.state, this.$store.dispatch).then(
          users => {
            this.listUsers = users;
          }
          , errors => showHttpErrorsInToasts(this, errors)
      );

      if (!this.isNewMode()) {
        // load userGroupData data
        adminUserGroupsApi.getUserGroup(this.$store.state, this.$store.dispatch, this.$route.params.id).then(
            m => {
              this.userGroupData = m;
              this.groupAdminUserIds = this.userGroupData.groupAdmins.map(x => x.userId);
              this.membersUserIds = this.userGroupData.members.map(x => x.userId);
            },
            error => showHttpErrorsInToasts(this, error)
        );
      }
    },
//endregion Init

//region Save
    /** Save all message data and optionally publish the message */
    saveAndClose: function() {
      if (this.isNewMode()) {
        this.save_and_create();
      } else {
        this.save_and_update();
      }
    },

    save_and_create() {
      adminUserGroupsApi.createUserGroup(this.$store.state, this.$store.dispatch
          , {
            title: this.userGroupData.data.title,
            description: this.userGroupData.data.description,
            groupAdminUserIds: this.groupAdminUserIds,
            membersUserIds: this.membersUserIds,
          }
      ).then(
          user_group => {
            this.$toast.open({
              message: this.$t("adminUserGroupsEditor.messages.Saved", {id: user_group.userGroupId}),
              type: 'success',
              position: 'top-right'
            });
            this.$router.push({ path: `/view/admin/user_groups` })

            //update statistics on left side-bar (see Normal view)
            this.$bus.$emit("refreshCountUnprocessedItems", {courses: true, messages: true});
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
    },

    save_and_update() {
      adminUserGroupsApi.updateUserGroup(this.$store.state, this.$store.dispatch
          , {
            userGroupId: this.userGroupData.data.userGroupId,
            title: this.userGroupData.data.title,
            description: this.userGroupData.data.description,
            groupAdminUserIds: this.groupAdminUserIds,
            membersUserIds: this.membersUserIds,
          }
      ).then(
          () => {
            this.$toast.open({
              message: this.$t("adminUserGroupsEditor.messages.Saved", {id: this.userGroupData.data.userGroupId}),
              type: 'success',
              position: 'top-right'
            });
            this.$router.push({ path: `/view/admin/user_groups` })

            //update statistics on left side-bar (see Normal view)
            this.$bus.$emit("refreshCountUnprocessedItems", {courses: true, messages: true});
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
    },
//endregion Save
  }
}
</script>

<style scoped>

</style>