<template>
  <div>
    <v-container>

<!-- Test title -->
      <v-row>
        <v-col>
          <v-text-field v-model="testTitle"
                        :label="$t('adminEditionTest.TestTitle')"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Test description -->
      <v-row>
        <v-col>
          <v-subheader class="pa-0">
            {{$t("adminEditionTest.TestDescription")}}
          </v-subheader>
          <ckeditor-wrapper
              ref="ckeditorWrapper"
              v-bind:text.sync="testDescription"
          >
          </ckeditor-wrapper>
        </v-col>
      </v-row>

<!-- List of test questions -->
      <v-row>
        <v-col>
          <v-data-table
              :headers="headers"
              :items="listItems"
              v-model="selectedQuestion"
              item-key="question"
              class="elevation-1"
              show-select
              single-select
              :key="keyToRerenderListQuestions"
          >
    <!-- Toolbar of the list of questions: move up/down, new question -->
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{$t("adminEditionTest.ListQuestions")}}
                </v-toolbar-title>
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>

                <v-spacer></v-spacer>

                <v-btn
                    class="ma-2"
                    :disabled="selectedQuestion.length === 0"
                    @click="moveSelectedQuestion(true)"
                >
                  {{$t("adminEditionTest.button.MoveUp")}}
                </v-btn>

                <v-btn
                    class="ma-2"
                    :disabled="selectedQuestion.length === 0"
                    @click="moveSelectedQuestion(false)"
                >
                  {{$t("adminEditionTest.button.MoveDown")}}
                </v-btn>

                <!-- a dialog to insert/edit question -->
                <v-dialog
                    v-model="dialogs.dialogEdit"
                    max-width="500px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        class="ma-2"
                        v-bind="attrs"
                        v-on="on"
                    >
                      {{$t("adminEditionTest.button.NewQuestion")}}
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ dialogs.dialogEditTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col>
                            <!-- About v-if, see: https://stackoverflow.com/questions/51472947/vuetifys-autofocus-works-only-on-first-modal-open -->
                            <v-text-field
                                v-model="dialogs.editedItem.question"
                                :label="$t('adminEditionTest.dialog.Question')"
                                v-if="dialogs.dialogEdit" autofocus
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <!-- Answers, one per line, mark correct one by leading '*' -->
                          <v-textarea
                              v-model="dialogs.editedItem.answers"
                              :label="$t('adminEditionTest.dialog.Answers')"
                              v-if="dialogs.dialogEdit"
                          ></v-textarea>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                          color="blue darken-1"
                          text
                          @click="closeEditDialog"
                      >
                        {{$t("button.Cancel")}}
                      </v-btn>
                      <v-btn
                          color="blue darken-1"
                          text
                          @click="save"
                      >
                        {{$t("button.Save")}}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <!-- a dialog to confirm deletion of the question -->
                <v-dialog v-model="dialogs.dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">
                      {{$t("adminEditionTest.delete.Confirm")}}
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDeleteDialog">
                        {{$t("button.Cancel")}}
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                        {{$t("button.Ok")}}
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

              </v-toolbar>
            </template>

            <template v-slot:item.answers="{ item }">
              <v-list flat dense>
                <v-list-item v-for="t in item.answers"
                             :key="t.text"
                >
                  <v-icon small class="mx-4">
                    {{t.correct ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle-outline" }}
                  </v-icon>
                  <v-list-item-content>
                    <v-list-item-title
                        class="text-wrap"
                        v-text="t.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </template>

    <!-- actions column: Edit, delete - with tooltips -->
            <template v-slot:item.actions="{ item }">
              <div class="d-flex justify-center">
                <!-- Edit the question -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>
                    {{$t("adminEditionTest.tooltip.EditQuestion")}}
                  </span>
                </v-tooltip>

                <v-divider
                    class="mx-4"
                    inset
                    vertical
                ></v-divider>

                <!-- Delete the question -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>
                    {{$t("adminEditionTest.tooltip.DeleteQuestion")}}
                  </span>
                </v-tooltip>
              </div>
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import CkeditorWrapper from "@/components/shared/ckeditorWrapper";
import {deepClone} from "@/helpers/gutils";

export default {
  name: "adminEditionPageTestEditor",
  components: {CkeditorWrapper},
  props:[
      "jsonFinalTest"
  ],

  data: function () {
    return {

      /** Actual list of questions
       * If the list or any question in the list is changed,
       * the component sends an event to the parent.
       * Each question is a follow object:
       * {
       *   question,
       *   answers: [
       *     {
       *       text,
       *       correct: false
       *     }
       *   ]
       * }
       * */
      listItems: this.jsonFinalTest && this.jsonFinalTest.questions
          ? this.jsonFinalTest.questions
          : [],

      /** selected question (to move it up/down)*/
      selectedQuestion: [],

      /** Title of the test */
      testTitle: this.jsonFinalTest
          ? this.jsonFinalTest.title
          : "",

      /** Description of the test */
      testDescription: this.jsonFinalTest
          ? this.jsonFinalTest.description
          : "",

      /** Columns of the list of the tests for v-data-table
       * */
      headers: [
        {
          text: this.$t("adminEditionTest.headers.Number"),
          align: 'start',
          value: 'order_index',
          sortable: false,
          width: "80"
        },
        {
          text: this.$t("adminEditionTest.headers.Question"),
          value: 'question',
          sortable: false,
        },
        {
          text: this.$t("adminEditionTest.headers.Answers"),
          value: 'answers',
          sortable: false
        },
        {
          text: this.$t("adminEditionTest.headers.Actions"),
          value: 'actions',
          align: 'center',
          sortable: false,
          width: "80",
        },
      ],

      /** dialogs to edit, insert or delete items*/
      dialogs: {
        /** show edit dialog to edit selected question*/
        dialogEdit: false,
        /** show delete-confirmation dialog*/
        dialogDelete: false,

        /** The index of the currently edited item in listItems
         * -1 - there is no item that is being edited*/
        editedIndex: -1,

        /** A copy of the item that is being edited*/
        editedItem: {
          question: "",
          /** List of answers */
          answers: ""
        },

        /** Default values of the item. When a dialog is close, we copy these values to editedItem */
        defaultItem: {
          question: "",
          /** List of answers, one answer per line. Correct answer is marked by leading '*' */
          answers: ""
        },

        /** A title of edit dialog.
         * We refresh it using refreshEditDialogTitle*/
        dialogEditTitle: "",
      },

      /**  https://stackoverflow.com/questions/32106155/can-you-force-vue-js-to-reload-re-render
       * to rerender list of questions forcedly after moving items
       * */
      keyToRerenderListQuestions: 0,
    }
  },

  watch: {
    "dialogs.dialogEdit": function(visible) {
      visible || this.closeEditDialog();
    },
    "dialogs.dialogDelete": function(visible) {
      visible || this.closeDeleteDialog();
    },
    "dialogs.editedIndex": function () {
      this.refreshEditDialogTitle();
    }
  },

  methods: {
    refreshEditDialogTitle() {
      this.dialogs.dialogEditTitle = this.dialogs.editedIndex === -1
          ? this.$t("adminEditionTest.dialog.TitleCreate")
          : this.$t("adminEditionTest.dialog.TitleEdit");
    },
    /** Get same json as initial jsonFinalTest */
    getResultsAsJson() {
      return {
        title: this.testTitle,
        description: this.testDescription,
        questions: this.listItems
      }
    },

//region json-item To/From editor-item
    /**
     * Convert json-item to editor-item
     *
     * json-item is an item of this.listItems
     * editorItem is an item for the editor dialog
     * the difference in format of answers:
     * - json-item: answer is json: {text, correct}
     * - editor-item: answer is list of answers, one answer per line, correct answers are marked by leading '*'
     */
    convert_jsonItemToEditorItem(jsonItem) {
      let answers = "";
      for (const a of jsonItem.answers) {
        if (a.correct) {
          answers = answers + "*" + a.text + "\r\n";
        } else {
          answers = answers + a.text + "\r\n";
        }
      }

      let dest = {
        question: jsonItem.question,
        answers: answers
      }
      return dest;
    },

    /**
     * Convert editor-item to json-item
     */
     convert_editorItemToJonItem(editorItem) {
      let answers = [];
      for (let s of editorItem.answers.split("\n")) {
        if (s) {
          if (s.startsWith("*")) {
            answers.push(
                {
                  text: s.substring(1),
                  correct: true
                }
            );
          } else {
            answers.push({text: s});
          }
        }
      }

      return {
        question: editorItem.question,
        answers: answers
      }
    },
//endregion json-item To/From editor-item

//region Edit/insert item
    /** Show dialog to edit selected item*/
    editItem(item) {
      this.dialogs.editedIndex = this.listItems.indexOf(item);
      this.dialogs.editedItem = this.convert_jsonItemToEditorItem(item);
      this.dialogs.dialogEdit = true;
    },

    /** Close the dialog to edit selected item*/
    closeEditDialog() {
      this.dialogs.dialogEdit = false;
      this.$nextTick(() => {
        this.dialogs.editedItem = deepClone(this.dialogs.defaultItem);
        this.dialogs.editedIndex = -1;
      })
    },

    /** Save changes after editing/inserting */
    save() {
      if (this.dialogs.editedIndex > -1) {
//edit exist question
        Object.assign(this.listItems[this.dialogs.editedIndex]
            , deepClone(this.convert_editorItemToJonItem(this.dialogs.editedItem))
        );
      } else {
//create new question
        let c = this.convert_editorItemToJonItem(this.dialogs.editedItem);
        this.listItems.push(c);
      }
      this.closeEditDialog();
    },
//endregion Edit/insert item

//region Delete item
    /** Show confirmation dialog to delete selected item*/
    deleteItem(item) {
      this.dialogs.editedIndex = this.listItems.indexOf(item);
      this.dialogs.editedItem = this.convert_jsonItemToEditorItem(item);
      this.dialogs.dialogDelete = true;
    },

    /** Close the dialog to confirm a deletion of the selected item*/
    closeDeleteDialog() {
      this.dialogs.dialogDelete = false;
      this.$nextTick(() => {
        this.dialogs.editedItem = deepClone(this.dialogs.defaultItem);
        this.dialogs.editedIndex = -1;
      })
    },

    /** Item was confirmed to delete. Make real deletion*/
    deleteItemConfirm() {
      this.listItems.splice(this.dialogs.editedIndex, 1);
      this.closeDeleteDialog();
    },
//endregion Delete item

//region Move up/down
    /** Move selected question up or down in the list */
    moveSelectedQuestion(moveUp) {
      if (this.selectedQuestion.length > 0) {
        const selected_question = this.selectedQuestion[0];
        const index = this.listItems.findIndex(x => x.question === selected_question.question);
        if (moveUp) {
         if (index > 0) {
           let temp = this.listItems[index - 1];
           this.listItems[index - 1] = this.listItems[index];
           this.listItems[index] = temp;
         }
        } else {
          if (index < this.listItems.length - 1) {
            let temp = this.listItems[index + 1];
            this.listItems[index + 1] = this.listItems[index];
            this.listItems[index] = temp;
          }
        }
      }

      //forced rerender
      this.keyToRerenderListQuestions += 1;
    },
//endregion Move up/down
  }
}
</script>

<style scoped>

</style>