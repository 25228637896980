import {handleResponse_401_Unauthorized} from "@/helpers/handle401";
import {getAuthorizationHeader} from "@/helpers/auth-header";

export const adminMctypesApi = {
    getListMctypes
    , getMctype
    , getListTypesOfCourses
    , getListTypesOfMessages
    , createMctype
    , updateMctype
    , deleteMctype
};

const KIND_MESSAGE_TYPES = 1;
const KIND_COURSE_TYPES = 0;

//region Get mctypes
/**
 * Get specified mctype
 * @param rootStoreState
 * @param storeDispatch
 * @param mctypeId
 * @return {Promise<Response>}
 */
function getMctype(rootStoreState, storeDispatch, mctypeId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/mctypes/${mctypeId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get list of course types
 * @param rootStoreState
 * @param storeDispatch
 * @return {Promise<Response>}
 */
function getListTypesOfCourses(rootStoreState, storeDispatch) {
    return getListMctypes(rootStoreState, storeDispatch, KIND_COURSE_TYPES);
}

/**
 * Get list of message types
 * @param rootStoreState
 * @param storeDispatch
 * @return {Promise<Response>}
 */
function getListTypesOfMessages(rootStoreState, storeDispatch) {
    return getListMctypes(rootStoreState, storeDispatch, KIND_MESSAGE_TYPES);
}

/**
 * Get list of course/message types
 * @param rootStoreState
 * @param storeDispatch
 * @param kind01
 *      0 - course types
 *      1 - message types
 * @return {Promise<Response>}
 */
function getListMctypes(rootStoreState, storeDispatch, kind01) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/mctypes/list/${kind01}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Get mctypes

//region Create, update, delete mctypes
/**
 * Register new user in DB
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel {object}
 *      see FaMcTypesController.InputModelCreate:
 *      {
 *          Title,
 *          Kind = 0 or 1
 *      }
 */
function createMctype(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/mctypes`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Update mctype
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param updateModel
 *      see FaMcTypesController.InputModelUpdate
 *           {
 *               mctypeId,
 *               Title,
 *           }
 */
function updateMctype(rootStoreState, storeDispatch, updateModel) {
    const requestOptions = {
        method: 'PUT',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(updateModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/mctypes`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

function deleteMctype(rootStoreState, storeDispatch, mctypeId) {
    const requestOptions = {
        method: 'DELETE',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/mctypes/${mctypeId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Create, update, delete users
