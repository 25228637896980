<!--
  The editor of the document in Document Archive
  see #27
-->
<template>
  <v-form ref="form">
    <div>
      <v-container>
        <v-row>
          <v-col>
            <v-toolbar flat>
              <v-toolbar-title>
                {{
                  isNewMode()
                      ? $t("adminDocumentsEditor.FormTitle.new")
                      : $t("adminDocumentsEditor.FormTitle.edit")
                }}
              <v-subheader class="ma-0 pa-0">id={{documentData.data.documentId}}</v-subheader>
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn
                  color="success"
                  class="ma-2"
                  @click="saveAndClose(true)"
              >
                {{$t("adminDocumentsEditor.button.SaveAndClose")}}
              </v-btn>

              <v-btn
                  color="primary"
                  class="ma-2"
                  @click="saveAndClose(false)"
              >
                {{$t("button.Save")}}
              </v-btn>

              <v-btn
                  class="ma-2"
                  @click="close()"
              >
                {{$t("button.Close")}}
              </v-btn>

            </v-toolbar>
          </v-col>
        </v-row>

        <!-- Document ID, creator-name -->
        <v-row>
          <v-col>
            <v-subheader class="pa-0">
              {{$t("label.CreatedBy")}}
            </v-subheader>
            {{documentData.createdByUserName}}
          </v-col>
          <v-col>
            <v-subheader class="pa-0">
              {{$t("label.CreatedAt")}}
            </v-subheader>
            {{
              documentData.createdAt
                  ? dt2str(documentData.createdAt)
                  : ""
            }}
          </v-col>
          <v-col>
            <v-subheader class="pa-0">
              {{$t("label.PublishedAt")}}
            </v-subheader>
            {{documentData.publishedAt ? dt2str(documentData.publishedAt) : ""}}
          </v-col>
        </v-row>

  <!-- Comment to the document -->
        <v-row>
          <v-col>
            <v-text-field v-model="documentData.data.title"
                          :rules="ruleTitleIsRequired"
                          :label='$t("adminDocumentsEditor.Title")'
            />
          </v-col>
          <v-col>
            <v-text-field v-model="documentData.data.remarks"
                          :label='$t("adminDocumentsEditor.Remarks")'
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-checkbox
                v-model="documentData.data.isPublished"
                :label="$t('adminDocumentsEditor.Published')"
            >
            </v-checkbox>
          </v-col>
        </v-row>

  <!-- Document body -->
        <v-row>
          <v-col>
            <div>
              <v-subheader class="pa-0">
                {{$t("adminDocumentsEditor.DocumentBody")}}
              </v-subheader>

              <ckeditor-wrapper
                  ref="ckeditorWrapper"
                  v-bind:text.sync="documentData.htmlContent"
                  :readonly="documentData.data.isPublished"
              >
              </ckeditor-wrapper>
            </div>
          </v-col>
        </v-row>

<!-- File selector -->
        <v-row>
          <v-col>

            <div>
              <v-subheader class="pa-0">
                {{$t("adminDocumentsEditor.FileSelector")}}
              </v-subheader>

              <forms-list-view
                  ref="refAdminDocumentPageEditorFileSelector"
                  v-on:update:theFolderId="open_folder_in_file_selector"
                  :the-folder-id="fileSelectorCurrentFolderId"
                  :admin-mode="true"
                  hide-path-to-folder="true"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-form>
</template>

<script>
//region Import
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {documentsApi} from "@/api/documents.api";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";
import CkeditorWrapper from "@/components/shared/ckeditorWrapper";
import FormsListView from "@/components/documents-archive/formsListView";
//endregion Import

export default {
  name: "adminDocumentPageEditor",
  components: {CkeditorWrapper, FormsListView},
  data: function() {
    return {
      components: {CkeditorWrapper},
      /**
       * All data of the document that is being edited (DocumentEditEx)
       */
      documentData: {
        /** (Document) */
        data: {},
      },

      /** not null in edit mode */
      currentDocumentId_EditMode: undefined,

      /** the folder to which the document (will) belongs */
      currentFolderId: undefined,

      ruleTitleIsRequired: [
        (v) => !!v || this.$t("adminDocumentsEditor.ruleTitleIsRequired"),
      ],

      /** A folder currently selected in the file selector */
      fileSelectorCurrentFolderId: undefined,
    }
  },

  created() {
    this.init();
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.init();
    });
  },

  methods: {
//region Init
    /** Possible ways to open this page:
     *    documents/document/create/:folderId     -   create new document in the given folder
     *    documents/document/edit/:documentId     -   update the given document
     *    */
    isNewMode: function() {
      return !this.currentDocumentId_EditMode;
    },

    init: function() {
      //let's detect mode
      if (this.$route.path.toLowerCase().startsWith("/view/admin/documents/document/create/")) {
        this.currentFolderId = this.$route.params.folderId;
      } else {
        this.currentDocumentId_EditMode = this.$route.params.documentId;
      }

      if (this.isNewMode()) {
        //new document is created, no defaults are used
      } else {
        // load documentData data
        documentsApi.getDocument(this.$store.state, this.$store.dispatch, this.currentDocumentId_EditMode, true).then(
            document_ex => {
              this.documentData = document_ex;
              this.currentFolderId = document_ex.data.folderId;
              this.$refs.ckeditorWrapper.content = document_ex.htmlContent;
            }
        ).catch(error => showHttpErrorsInToasts(this, error));
      }
    },

//endregion Init

//region Save and publish
    /** Save all document data and optionally return to the parent folder */
    saveAndClose: function(close) {
      if (this.$refs.form.validate()) {
        if (this.isNewMode()) {
          this.create_the_document();
        } else {
          this.update_the_document();
        }

        if (close) {
          this.close();
        }
      }
    },

    /** Create new document using entered data */
    create_the_document: function() {
      documentsApi.createDocument(this.$store.state, this.$store.dispatch
          , {
            title: this.documentData.data.title,
            folderId: this.currentFolderId,
            htmlContent: this.documentData.htmlContent,
            remarks: this.documentData.data.remarks,
            isPublished: this.documentData.data.isPublished,
          }
      ).then(
          dex => {
            this.currentFolderId = dex.data.folderId;
            this.currentDocumentId_EditMode = dex.data.documentId;
            this.documentData = dex;

            this.$toast.open({
              message: this.$t("adminDocumentsEditor.document.Created", {id: dex.data.documentId}),
              type: 'success',
              position: 'top-right'
            });
            //update statistics on left side-bar (see Normal view)
            this.$bus.$emit("refreshCountUnprocessedItems", {documents: true});
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
    },

    /** update all fields of the document */
    update_the_document: function() {
      documentsApi.updateDocument(this.$store.state, this.$store.dispatch
          , {
            documentId: this.documentData.data.documentId,
            maskToUpdate: 0x1 | 0x2 | 0x4 | 0x8 | 0x10, //see DocumentAppController.WhatToUpdate

            title: this.documentData.data.title,
            folderId: this.documentData.data.folderId,
            htmlContent: this.documentData.htmlContent,
            remarks: this.documentData.data.remarks,
            isPublished: this.documentData.data.isPublished,
          }
      ).then(
          () => {
            this.$toast.open({
              message: this.$t("adminDocumentsEditor.document.Updated", {id: this.documentData.data.documentId}),
              type: 'success',
              position: 'top-right'
            });

            //update statistics on left side-bar (see Normal view)
            this.$bus.$emit("refreshCountUnprocessedItems", {documents: true});
          }
      ).catch(error => showHttpErrorsInToasts(this, error));
    },
//endregion Save and publish

//region Close
    close() {
      this.$router.push({ path: `/view/admin/documents/openFolder/${this.currentFolderId}` });
    },

//endregion Close

//region File selector
    open_folder_in_file_selector(folderId) {
      this.fileSelectorCurrentFolderId = folderId;
    },

//endregion File selector

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },
  }
}

</script>

<style scoped>

</style>