<!--
Admin part: list of courses - FA, GA
Allow to view list of courses of specified mctype.
Allow to create new course and edit exist courses.

#119: GA is able to create new courses.
      GA is able to modify any courses created by him.
-->
<template>
  <v-data-table
        :headers="headers"
        :items="listItems"
        item-key="courseId"
        class="elevation-1"
        :options.sync="paginationOptions"
        :server-items-length="totalCountItems"
        :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{$t("adminCourses.FormTitle")}}</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>

          <!-- Filter by course types -->
          <v-select
              v-model="currentMctypeId"
              :items="mctypes"
              item-text="title"
              item-value="mctypeId"
              menu-props="auto"
              label="Course type"
              hide-details
              prepend-icon="mdi-map-marker-outline"
              single-line
          ></v-select>

          <v-spacer></v-spacer>

          <router-link :to="'/view/admin/courses/new/' + currentMctypeId"
                       tag="button">
            <v-btn
                color="primary"
                class="ma-2"
                :disabled="currentMctypeId === 0"
            >
              {{$t("adminCourses.button.NewCourse")}}
            </v-btn>
          </router-link>
        </v-toolbar>
      </template>

<!-- actions column: Edit, delete - with tooltips -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-center">

      <!-- GA-only: assign course to users and user groups -->
          <router-link :to="'/view/admin/courses/' + item.data.courseId + '/gamembers'"
                       tag="button"
                       v-if="!isEditableForCurrentUser(item)"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    class="mr-2"
                >
                  mdi-account-multiple-plus
                </v-icon>
              </template>
              <span>{{$t("adminCourses.tooltip.ga.Assign")}}</span>
            </v-tooltip>
          </router-link>

     <!-- FA-Edit / GA-View button (to edit previously created course) -->
          <router-link :to="'/view/admin/courses/' + item.data.courseId + '/edit'"
                       tag="button">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                >
                  {{isEditableForCurrentUser(item) ? "mdi-pencil" : "mdi-eye" }}
                </v-icon>
              </template>
              <span>{{isEditableForCurrentUser(item)
                  ? $t("adminCourses.tooltip.Edit")
                  : $t("adminCourses.tooltip.View")
                }}</span>
            </v-tooltip>
          </router-link>

          <v-divider
              class="mr-2"
              inset
              vertical
          ></v-divider>

      <!-- FA-Edit / GA-view list of course parts -->
          <router-link :to="'/view/admin/courses/' + item.data.courseId + '/parts'"
                       tag="button">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                >
                  mdi-format-list-bulleted
                </v-icon>
              </template>
              <span>{{$t("adminCourses.tooltip.Parts")}}</span>
            </v-tooltip>
          </router-link>

          <v-divider
              class="mr-2"
              inset
              vertical
              v-show="item.publishedAt"
          ></v-divider>

          <!-- View statistics by users -->
          <router-link :to="'/view/admin/courses/' + item.data.courseId + '/stat'"
                       tag="button"
                       v-show="item.publishedAt">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                >
                  mdi-account-alert
                </v-icon>
              </template>
              <span>{{$t("adminCourses.tooltip.StatByUser")}}</span>
            </v-tooltip>
          </router-link>
        </div>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <div class="d-flex justify-center">
          {{ dt2str(item.createdAt) }}
        </div>
      </template>

    <template v-slot:item.publishedAt="{ item }">
      <div class="d-flex justify-center">
        {{ item.publishedAt
            ? dt2str(item.publishedAt)
            : $t("label.NotPublished")
        }}
      </div>
    </template>
  </v-data-table>
</template>

<script>
import {adminMctypesApi} from "@/api/admin.mctypes.api";
import {adminCoursesApi} from "@/api/admin.courses.api";
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {debounce} from "lodash";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";

export default {
  name: "adminCoursesPage",
  props: [],

  data: function() {
    return {
      /** List of course mctypes, McType*/
      mctypes: [],
      /** Currently selected type of the courses*/
      currentMctypeId: 0,
      /** Main list of courses loaded from the server*/
      listItems: [],
      /** Columns of the list of DB-users */
      headers: [
        {
          text: this.$t("adminCourses.headers.Id"),
          align: 'start',
          value: 'data.courseId',
          width: 80
        },
        {
          text: this.$t("adminCourses.headers.Title"),
          value: 'data.title'
        },
        {
          text: this.$t("adminCourses.headers.CreatedAt"),
          value: 'createdAt',
          width: 150,
          align: 'center'
        },
        {
          text: this.$t("adminCourses.headers.Creator"),
          value: 'createdByUserName',
          width: 200,
          align: 'center'
        },
        {
          text: this.$t("adminCourses.headers.PublishedAt"),
          value: 'publishedAt',
          width: 150,
          align: 'center'
        },
        {
          text: this.$t("adminCourses.headers.Actions"),
          value: 'actions',
          align: 'center',
          sortable: false,
          width: 160
        },
      ],

      /**
       * Current pagination settings
       *
       * Server side pagination is used
       * see: https://vuetifyjs.com/en/api/v-data-footer/#props
       * */
      paginationOptions: {
        sortDesc: [true],
        sortBy: ["data.courseId"]
      },
      totalCountItems: 0,

      /** the data is loading from the server */
      loading: false,
    }
  },

  watch: {
    /** When user select a course type, we need to reload list of the courses of the selected type*/
    currentMctypeId() {
      //course type was changed
      //we need to start from page 1, but we can keep currently selected ordering parameters
      this.debouncedLoadDataFromServer();
      this.$store.commit("storeCourses/setAdminMctypeId", this.currentMctypeId)
    },

    paginationOptions: {
      handler(newValue, oldValue) {
        // console.log("options.new", JSON.stringify(newValue));
        // console.log("options.old", JSON.stringify(oldValue));

        if (newValue !== oldValue) {
          this.debouncedLoadDataFromServer();
        }
      },
      deep: true
    },

  },

  created() {
    // _.debounce is a function provided by lodash to limit how
    // often a particularly expensive operation can be run.
    // In this case, we want to limit how often we access
    // yesno.wtf/api, loading until the user has completely
    // finished typing before making the ajax request. To learn
    // more about the _.debounce function (and its cousin
    // _.throttle), visit: https://lodash.com/docs#debounce
    this.debouncedLoadDataFromServer = debounce(this.loadDataFromServer, 50)
    this.debouncedLoadDataFromServer();

    // load list of available course types
    adminMctypesApi.getListTypesOfCourses(this.$store.state, this.$store.dispatch).then(
        list => {
          this.mctypes = list;
          const default_mctype_id = this.$store.state.storeCourses.adminMctypeId //set mctype currently stored in the vuex
              ? this.$store.state.storeCourses.adminMctypeId
              : list.length !== 0
                  ? list[0].mctypeId
                  : null;

          if (default_mctype_id) {
            if (list.filter(x => x.mctypeId === default_mctype_id).length > 0) {
              this.currentMctypeId = default_mctype_id;
            }
          }
        }
    );
  },

  computed: {
    /** User who has currently logged in */
    currentUser() {
      return JSON.parse(localStorage.getItem('user'));
    }
  },

  methods: {
//region Load data from server
    /** Load list of courses form server - one page only*/
    loadDataFromServer: function() {
      const mctype_id = this.currentMctypeId;
      const pn = {
        page: this.paginationOptions.page,
        itemsPerPage: this.paginationOptions.itemsPerPage,
        sortBy: this.paginationOptions.sortBy,
        sortDesc: this.paginationOptions.sortDesc,
      };

      this.loading = true;
      adminCoursesApi.getListCourses(this.$store.state, this.$store.dispatch, mctype_id, pn).then(
          json => {
            this.listItems = json.items;
            this.paginationOptions.page = json.paginationOptions.page;
            //We need to use arrays: https://github.com/vuetifyjs/vuetify/issues/12929
            this.paginationOptions.sortBy = [json.paginationOptions.sortBy];
            this.paginationOptions.sortDesc = [json.paginationOptions.sortDesc];

            this.paginationOptions.itemsPerPage = json.paginationOptions.itemsPerPage;
            this.totalCountItems = json.totalCountItems;
            this.loading = false;
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });
    },
//endregion load data from server

//region Create new course
    /**
     * Create new (empty) course
     * and redirect to the editor of new course
     */
    createNewCourse: function() {
      adminCoursesApi.createCourse(this.$store.state, this.$store.dispatch
          , {
            courseTitle: "",
            courseBody: "",
            courseBodyFormat: "HTML",
            mctypeId: this.currentMctypeId
          }
      ).then(
          course_id => {
            this.$router.push({ path: `/view/admin/courses/${course_id}/edit` })
          },
          error => {
            showHttpErrorsInToasts(this, error)
          }
      );
    },
//endregion Create new course

//region Permissions
    /** True if the current user is GA but is not FA */
    isCurrentUserGAandNotFA: function() {
      let user = this.currentUser;
      return !user.isFullAdmin
          && user.gaUserGroupIds
          && user.gaUserGroupIds.length > 0;
    },

    /**
     * True if the given course can be edited
     * by the current user. It means, that
     * either the current user is FA or
     * the current user is GA and he is creator of the course
     *
     * @param item
     *    CourseViewEx
     */
    isEditableForCurrentUser: function(item) {
      if (this.currentUser.isFullAdmin) {
        return true;
      }
      return this.currentUser.userId === item.createdBy;
    },

//endregion Permissions

//region Utils
    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },
//endregion Utils
  }
}
</script>

<style scoped>

</style>