<!--
  List of folders and documents
  single given folder inside Documents sections of the Document Archive
  see #27

it can be opened as:
   documents
   documents/openFolder/folder_id
   documents/openDocument/document_id
There are two optional parameters in the URL: kind and id
-->
<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>{{$t("adminDocumentsPage.FormTitle")}}</v-toolbar-title>
    </v-toolbar>

    <documents-list-view
        ref="refDocumentsEditor"
        v-on:update:theFolderId="open_folder"
        :the-folder-id="currentFolderId"
        :admin-mode="true"
    />
  </div>
</template>

<script>

import DocumentsListView from "@/components/documents-archive/documentsListView";

export default {
  name: "adminDocumentsPage",
  components: {DocumentsListView},
  data: function() {
    return {
      /**
       * Id of the current folder.
       */
      currentFolderId: 0,
    }
  },

  created() {
  },

  mounted() {
    this.init(this.$route.params.kind, this.$route.params.id);
  },

  watch: {
    dialogFiles(visible) {
      visible || this.closeFilesDialog();
    },
  },

  /**
   * When new page is created, the page is redirected to itself with different set of params
   * (to change isNewMode from true to false)
   *
   * So, "created" is not called second time if we move from one list to another.
   * We need to use beforeRouteEnter to workaround the problem.
   *
   * https://router.vuejs.org/guide/advanced/data-fetching.html#fetching-after-navigation
   * https://github.com/vuejs/vue-router/issues/1875
   **/
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.init(to.params.kind, to.params.id);
    });
  },

  beforeRouteUpdate (to, from, next) {
    this.init(to.params.kind, to.params.id);
    next();
  },

  methods: {
//region Load data
    /** Initialization: it's called on creation and on re-initialization in beforeRouteEnter
     * @param kind
     *    What "id" means
     *       undefined or "openFolder" or "openDocument"
     * @param id
     *    optional id of the document or folder
     *    that should be opened.
     *
     *    If kind is "openDocument" then we need to
     *    open the folder that contains the document
     * */
    init(kind, id) {
      this.$refs.refDocumentsEditor.openFolder(kind, id);
    },
//endregion Load data

//region Open file / folder
    openItem(item) {
      if (item.isFolder) {
        this.$router.push({path: `/view/admin/documents/openFolder/${item.data.folderId}`});
      } else {
        console.log(item, item.data)
        this.$router.push({path: `/view/admin/documents/document/edit/${item.data.data.documentId}`});
      }
    },

    open_folder(folderId) {
      console.log("open_folder", folderId);
      this.$router.push({ path: `/view/admin/documents/openFolder/${folderId}` });
    },
//endregion Open file /folder
  }
}
</script>

<style scoped>

</style>