<!--
Admin part.
Statistic by users for the message
-->
<template>
  <div>
    <v-toolbar-title>
      <router-link :to="`/view/admin/messages/${this.$route.params.id}/edit`"
                   tag="button">
        {{$t("adminMessagesStatByUsers.MessageTitle"
          , {
            title: this.messageData
                ? this.messageData.data.title
                : ""
          }
         )}}
      </router-link>

      <v-chip class="ma-2"
              :class="{ 'success' : this.countUsersDone === this.listItems.length}"
      >{{this.countUsersDone}}/{{this.listItems.length}}</v-chip>

      <v-subheader class="ma-0 pa-0">id={{$route.params.id}}</v-subheader>

    </v-toolbar-title>

    <v-data-table
        :headers="headers"
        :items="listItems"
        item-key="data.dbUser.userId"
        class="elevation-1"
        hide-default-footer
        disable-pagination
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            {{$t("adminMessagesStatByUsers.FormTitle")}}
          </v-toolbar-title>
          <v-divider
              class="mx-2"
              inset
              vertical
          ></v-divider>
        </v-toolbar>
      </template>

<!-- Status -->
      <template v-slot:item.isDone="{ item }">
        <div class="d-flex justify-center">
          <v-icon
              small
              :color="item.isDone ? 'green' : 'red'"
          >
            {{item.isDone ? "mdi-checkbox-marked-circle" : "mdi-checkbox-blank-circle-outline" }}
          </v-icon>
        </div>
      </template>

<!-- Done at -->
      <template v-slot:item.lastReadAt="{ item }">
        <div class="d-flex justify-center">
          {{ item.lastReadAt ? dt2str(item.lastReadAt) : ""}}
        </div>
      </template>

    </v-data-table>
  </div>
</template>

<script>
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {adminMessagesApi} from "@/api/admin.messages.api";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";

export default {
  name: "adminMessagesPageStatByUsers",

  data: function() {
    return {
      /** ID of the message provided through URL*/
      messageId: this.$route.params.id,

      /** Message-struct loaded from server by messageId */
      messageData: {
        data: {},
        userRecipients: [],
        userGroupRecipients: [],
        attachedFiles: []
      },

      /** List of MessageStatusByUser */
      listItems: [],

      /** Columns of the list of DB-users
       * Sorting is disabled because of implementation of move up/down
       * */
      headers: [
        {
          text: this.$t("adminMessagesStatByUsers.headers.Id"),
          align: 'start',
          value: 'dbUser.userId',
          sortable: true,
          width: "100"
        },
        {
          text: this.$t("adminMessagesStatByUsers.headers.UserName"),
          value: 'dbUser.title',
          sortable: true,
          align: 'left'
        },
        {
          text: this.$t("adminMessagesStatByUsers.headers.Status"),
          value: 'isDone',
          sortable: true,
          width: "100",
          align: 'center'
        },
        {
          text: this.$t("adminMessagesStatByUsers.headers.ReadAt"),
          value: 'lastReadAt',
          sortable: true,
          width: "160",
          align: 'center'
        },

      ],

      /** How much users has read the message */
      countUsersDone: 0,
    }
  },

  created() {
    this.loadDataFromServer();

    //load info about the message
    adminMessagesApi.getEditMessageEx(this.$store.state, this.$store.dispatch
        , this.messageId
    ).then(
        c => {
          this.messageData = c;
        }
    ).catch(error => {
      showHttpErrorsInToasts(this, error);
    });
  },

  methods: {
//region Load data
    /** Load stat the server */
    loadDataFromServer: function() {
      adminMessagesApi.getStatByUsers(this.$store.state, this.$store.dispatch
          , this.messageId
      ).then(
          list => {
            this.listItems = list; //array of MessageStatusByUser
            this.countUsersDone = list.filter(x => x.isDone).length;
          }
      ).catch(error => {
        showHttpErrorsInToasts(this, error);
      });
    },
//endregion Load data

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },

  }
}
</script>

<style scoped>

</style>