export const apiDbUserEx = {
    getUserRolesAsString
}


/**
 * Generate text representation of list of available roles
 * for the particular user
 *
 * @param dbUserEx
 *    an item of listItems === dbUserEx
 *
 * @param t
 *    this.$t - for localization
 *    it can be null in ut
 * */
function getUserRolesAsString(dbUserEx, vue) {
    const roles = [];
    if (dbUserEx.isFullAdmin) {
        roles.push(
            vue.$t
                ? vue.$t("label.FullAdmin")
                : "Full admin"
        );
    }
    if (dbUserEx.gaUserGroups) {
        for (const g of dbUserEx.gaUserGroups) {
            roles.push(
                vue.$t
                    ? vue.$t("label.GroupAdmin", {"title": g.title})
                    : `Group admin: ${g.title}`)
        }
    }
    return roles.join("\r\n");
}