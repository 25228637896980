import {handleResponse_401_Unauthorized} from "@/helpers/handle401";
import {getAuthorizationHeader} from "@/helpers/auth-header";

export const adminPartsApi = {
    getListParts,
    getPart,

    createPart,
    updatePart,
    deletePart,

    movePart,
};

//region Get
/**
 * Get list of parts of specified course
 * @param rootStoreState
 * @param storeDispatch
 * @param courseId
 * @param all
 *      true if we need all parts and editions
 *      false - only active editions and their parts
 * @return {Promise<Response>}
 */
function getListParts(rootStoreState, storeDispatch, courseId, all) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = all
        ? `${rootStoreState.apiUrl}/api/admin/parts/list/${courseId}?all=true`
        : `${rootStoreState.apiUrl}/api/admin/parts/list/${courseId}`

    //console.log("url", url);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get PartEditEx for specified partData
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param partId
 * @return {Promise<Response>}
 *      Promise that returns PartViewExWithEditions
 */
function getPart(rootStoreState, storeDispatch, partId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/parts/${partId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Get

//region Create, delete part
/**
 * Create new part
 * @param rootStoreState
 * @param storeDispatch
 * @param inputModel {object}
 *      see FaPartsController.InputModelCreate:
 *      {
 *          courseId,
 *          title,
 *      }
 */
function createPart(rootStoreState, storeDispatch, inputModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(inputModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/parts/create`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

function deletePart(rootStoreState, storeDispatch, partId) {
    const requestOptions = {
        method: 'DELETE',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/parts/${partId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Create, delete part

//region Update, move
/**
 * Update part's title
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param updateModel
 *      see PartsAppController.InputModelUpdate
 *           {
 *               partId,
 *               title,
 *           }
 */
function updatePart(rootStoreState, storeDispatch, updateModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(updateModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/parts/update`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Update part's position
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param moveModel
 *      see PartsAppController.MoveModelUpdate
 *           {
 *               partId,
 *               moveUp,
 *           }
 */
function movePart(rootStoreState, storeDispatch, moveModel) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
        body: JSON.stringify(moveModel)
    };

    const url = `${rootStoreState.apiUrl}/api/admin/parts/move`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

//endregion Update, move