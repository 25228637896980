<!--
Admin part: list of messages - FA, GA
-->
<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="listItems"
        item-key="messageId"
        class="elevation-1"
        :options.sync="paginationOptions"
        :server-items-length="totalCountItems"
        :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            {{$t("adminMessages.Title")}}
          </v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>

  <!-- Filter by message types -->
          <v-select
              v-model="currentMctypeId"
              :items="mctypes"
              item-text="title"
              item-value="mctypeId"
              menu-props="auto"
              :label="$t('adminMessages.MessageType')"
              hide-details
              prepend-icon="mdi-map-marker"
              single-line
          ></v-select>

          <v-spacer></v-spacer>

          <router-link :to="`/view/admin/messages/new/${currentMctypeId}`"
                       tag="button">
            <v-btn
                color="primary"
                class="ma-2"
                :disabled="currentMctypeId === 0"
            >
              {{$t("adminMessages.button.NewMessage")}}
            </v-btn>
          </router-link>
        </v-toolbar>
      </template>

      <!-- actions column: Edit, delete - with tooltips -->
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-center">
          <!-- Edit button (to edit previously created message draft) -->
          <router-link :to="'/view/admin/messages/' + item.data.messageId + '/edit'"
                       tag="button">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>
                {{$t("adminMessages.tooltip.EditMessage")}}
              </span>
            </v-tooltip>
          </router-link>

          <v-divider
              class="mx-2"
              inset
              vertical
          ></v-divider>

          <!-- View button (to view already published message or drafts created by other users) -->
          <router-link :to="'/view/admin/messages/' + item.data.messageId + '/view'"
                       tag="button">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>
                {{$t("adminMessages.tooltip.ViewMessage")}}
              </span>
            </v-tooltip>
          </router-link>

          <v-divider
              class="mx-2"
              inset
              vertical
              v-show="item.publishedAt"
          ></v-divider>

          <!-- View statistics by users -->
          <router-link :to="'/view/admin/messages/' + item.data.messageId + '/stat'"
                       tag="button"
                       v-show="item.publishedAt">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                >
                  mdi-account-check
                </v-icon>
              </template>
              <span>
                {{$t("adminMessages.tooltip.StatByUsers")}}
              </span>
            </v-tooltip>
          </router-link>
        </div>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <div class="d-flex justify-center">
          {{ dt2str(item.createdAt) }}
        </div>
      </template>

      <template v-slot:item.publishedAt="{ item }">
        <div class="d-flex justify-center">
          {{ item.publishedAt
            ? dt2str(item.publishedAt)
            : `Not published`
          }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {adminMctypesApi} from "@/api/admin.mctypes.api";
import {adminMessagesApi} from "@/api/admin.messages.api";
import {showHttpErrorsInToasts} from "@/helpers/handleHttpErrors";
import {debounce} from "lodash";
import {convertIsoDateToDisplayDate} from "@/helpers/gutils";

export default {
  name: "adminMessagesPage",
  props: [
  ],

  data: function() {
    return {
      /** List of message mctypes, McType*/
      mctypes: [],
      /** Currently selected type of the messages*/
      currentMctypeId: 0,
      /** Main list of messages loaded from the server*/
      listItems: [],
      /** Columns of the list of DB-users */
      headers: [
        {
          text: this.$t("adminMessages.headers.Id"),
          align: 'start',
          value: 'data.messageId',
          width: "80"
        },
        {
          text: this.$t("adminMessages.headers.Creator"),
          value: 'createdByUserName',
          width: 200
        },
        {
          text: this.$t("adminMessages.headers.PublishedAt"),
          value: 'publishedAt',
          width: 150,
          align: 'center'
        },
        {
          text: this.$t("adminMessages.headers.Subject"),
          value: 'data.title'
        },
        {
          text: this.$t("adminMessages.headers.CreatedAt"),
          value: 'createdAt',
          width: 150,
          align: 'center'
        },
        {
          text: this.$t("adminMessages.headers.Actions"),
          value: 'actions',
          align: 'center',
          sortable: false,
          width: "160"
        },
      ],

      /**
       * Current pagination settings
       *
       * Server side pagination is used
       * see: https://vuetifyjs.com/en/api/v-data-footer/#props
       * */
      paginationOptions: {
        sortDesc: [true],
        sortBy: ["data.messageId"]
      },
      totalCountItems: 0,

      /** the data is loading from the server */
      loading: false
    }
  },

  watch: {
    /** When user select a message type, we need to reload list of the messages of the selected type*/
    currentMctypeId() {
      //message type was changed
      //we need to start from page 1, but we can keep currently selected ordering parameters
      this.debouncedLoadDataFromServer();
      this.$store.commit("storeMessages/setAdminMctypeId", this.currentMctypeId)
    },

    paginationOptions: {
      handler(newValue, oldValue) {
        // console.log("options.new", JSON.stringify(newValue));
        // console.log("options.old", JSON.stringify(oldValue));

        if (newValue !== oldValue) {
          this.debouncedLoadDataFromServer();
        }
      },
      deep: true
    },

  },

  created() {
    // _.debounce is a function provided by lodash to limit how
    // often a particularly expensive operation can be run.
    // In this case, we want to limit how often we access
    // yesno.wtf/api, loading until the user has completely
    // finished typing before making the ajax request. To learn
    // more about the _.debounce function (and its cousin
    // _.throttle), visit: https://lodash.com/docs#debounce
    this.debouncedLoadDataFromServer = debounce(this.loadDataFromServer, 50)
    this.debouncedLoadDataFromServer();

    // load list of available message types
    adminMctypesApi.getListTypesOfMessages(this.$store.state, this.$store.dispatch).then(
        list => {
          this.mctypes = list;
          const default_mctype_id = this.$store.state.storeMessages.adminMctypeId //set mctype currently stored in the vuex
            ? this.$store.state.storeMessages.adminMctypeId
              : list.length !== 0
                  ? list[0].mctypeId
                  : null;

          if (default_mctype_id) {
            if (list.filter(x => x.mctypeId === default_mctype_id).length > 0) {
              this.currentMctypeId = default_mctype_id;
            }
          }
        }
    );
  },

  methods: {
    /** Load list of messages form server - one page only*/
    loadDataFromServer: function() {
      const mctypeId = this.currentMctypeId;
      const pn = {
        page: this.paginationOptions.page,
        itemsPerPage: this.paginationOptions.itemsPerPage,
        sortBy: this.paginationOptions.sortBy,
        sortDesc: this.paginationOptions.sortDesc,
      };

      this.loading = true;
      adminMessagesApi.getListMessages(this.$store.state, this.$store.dispatch, mctypeId, pn).then(
          json => {
            this.listItems = json.items;
            this.paginationOptions.page = json.paginationOptions.page;
            //We need to use arrays: https://github.com/vuetifyjs/vuetify/issues/12929
            this.paginationOptions.sortBy = [json.paginationOptions.sortBy];
            this.paginationOptions.sortDesc = [json.paginationOptions.sortDesc];

            this.paginationOptions.itemsPerPage = json.paginationOptions.itemsPerPage;
            this.totalCountItems = json.totalCountItems;
            this.loading = false;
          }
      ).catch(error => {
        this.loading = false;
        showHttpErrorsInToasts(this, error);
      });
    },

    /** Convert date 2021-04-09T14:48:34 to DD-MM-YYYY HH-MM **/
    dt2str(dt) {
      return convertIsoDateToDisplayDate(dt);
    },

  }
}
</script>

<style scoped>

</style>