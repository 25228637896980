<!--
  Dialog to create/edit a folder
  in the given section of the Document Archive.

  To open dialog use newFolder() or editFolder()
  To save changes subscribe to the event "folder:save"

  2021-12-07, #119: allow to edit sortorder
-->
<template>
  <!-- a dialog to create new folder / edit exist folder -->
  <v-dialog v-model="dialogFolder" max-width="500px" @keydown.esc="dialogFolder = false">
    <v-card>
      <v-card-title>
        <span class="headline">{{ getDialogFolderTitle() }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="editedFolder.title"
                  :label="$t('dialogEditFolder.Title')"
                  autofocus
              ></v-text-field>
              <v-text-field
                  v-model="editedFolder.sortorder"
                  :label="$t('dialogEditFolder.Sortorder')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="closeFolderDialog"
        >
          {{$t("button.Cancel")}}
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="saveFolderDialog"
        >
          {{$t("button.Save")}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import {deepClone} from "@/helpers/gutils";

export default {
  name: "dialogEditFolder",
  model: {
    /** Create new folder or rename selected folder */
    event: "folder:save",
  },

  data: function() {
    return {
      /** show edit dialog to insert new folder or rename exist folder */
      dialogFolder: false,

      /** A copy of the current folder that is being edited */
      editedFolder: {
        folderId: 0,
        title: "",
        parentFolderId: 0
      },

    }
  },

  watch: {
    dialogFolder(visible) {
      visible || this.closeFolderDialog();
    },
  },

  methods: {
//region Folder dialog
    /** Get title of currently opened edit-folder dialog: edit or insert */
    getDialogFolderTitle() {
      return this.editedFolder.folderId
          ? this.$t("dialogEditFolder.EditDialogTitle")
          : this.$t("dialogEditFolder.InsertDialogTitle");
    },

    /** Edit the folder */
    editFolder(folder) {
      this.editedFolder = deepClone(folder);
      this.dialogFolder = true;
    },

    /** Create new folder */
    newFolder() {
      this.editedFolder = {};
      this.dialogFolder = true;
    },

    /** Close the dialog to insert/edit folder without saving the changes*/
    closeFolderDialog () {
      this.dialogFolder = false;
      this.$nextTick(() => {
        this.editedFolder = {};
      })
    },

    /** Create new folder or rename exist folder */
    saveFolderDialog () {
      this.$emit("folder:save"
          , {
            title: this.editedFolder.title,
            folderId: this.editedFolder.folderId,
            sortorder: this.editedFolder.sortorder
          }
      );

      this.closeFolderDialog();
    },
//endregion Folder dialog
  }
}
</script>

<style scoped>

</style>