import {handleResponse_401_Unauthorized} from "@/helpers/handle401";
import {getAuthorizationHeader} from "@/helpers/auth-header";
import {getUrlWithParams} from "@/helpers/url_utils";

export const adminTestfailsApi = {
    getTestfail,
    getListTestfails,
    updateTestfail
};

//region Get

/**
 * Get specified testfail
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param testfailId
 * @return {Promise<*>}
 */
function getTestfail(rootStoreState, storeDispatch, testfailId) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const url = `${rootStoreState.apiUrl}/api/admin/testfails/${testfailId}`;

    //console.log("url", url);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}

/**
 * Get list of testfails - all or not-reopened only
 * @param rootStoreState
 * @param storeDispatch
 * @param kind
 *          0   -   list of new (not reopened) testfailes only
 *          1   -   full list of testfails
 * @param paginationOptions
 *      optional JSON with set of (optional) pagination options
 *      paginationOptions: {
 *           page: 1,
 *           pageCount: 0,
 *           itemsPerPage: 15,
 *           sortBy: "testfailId",
 *           sortDesc: true
 *     },
 * @return {Promise<Response>}
 *      function returns a promise that returns JSON with follow data:
 *          {
 *              paginationOptions  - current set of the pagination options
 *              , items - list of the messages on the currently selected page
 *          }
 */
function getListTestfails(rootStoreState, storeDispatch, kind, paginationOptions) {
    const requestOptions = {
        method: 'GET',
        headers: getAuthorizationHeader()
    };

    const query = {...paginationOptions};

    const url = getUrlWithParams(`${rootStoreState.apiUrl}/api/admin/testfails/list/${kind}`, query);

    //console.log("url", url);
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}
//endregion Get

//region Reopen
/**
 * Reopen testfail - give a permission to the user to re-pass failed test
 *
 * @param rootStoreState
 * @param storeDispatch
 * @param testfailId
 *           }
 */
function updateTestfail(rootStoreState, storeDispatch, testfailId) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json', ...getAuthorizationHeader()},
    };

    const url = `${rootStoreState.apiUrl}/api/admin/testfails/update/${testfailId}`;
    return fetch(url, requestOptions)
        .then(response => handleResponse_401_Unauthorized(response, storeDispatch));
}


//endregion Reopen